import React from 'react';

import { Box, Modal, Typography } from '@mui/material';

import close from '../../../assets/images/close.svg';

const BP1 = '@media (max-width: 899px)';
const BP2 = '@media (max-width: 719px)';
const BP3 = '@media (max-width: 600px)';

const sx = {
  root: {
    height: '100%',
   // backgroundColor: 'rgba(0,0,0,0.3)',
    py: '44px',
    px: '125px',
    position: 'relative',
    display: 'flex',
    // pointerEvents: "none",
    transition: 'all .3s',
    [BP1]: {
      px: '85px',
    },
    [BP2]: {
      px: '25px',
    },
  },
  container: {
    maxHeight: '80vh',
    minHeight: '150px',
    position: 'relative',
    margin: 'auto',
    width: '100%',
    maxWidth: '600px',
    borderRadius: '16px',
    border: '1px solid #050000',
    overflow: 'hidden',
    transition: 'all .3s',
    backgroundColor: '#D2D4C2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    px: '30px',
    py: '20px',
    textAlign: 'center',
    outline: 'solid 2px #D2D4C2',
    outlineOffset: '12px',
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  closeBtn: {
    position: 'absolute',
    width: '14px',
    height: '14px',
    top: '0',
    bottom: '0',
    right: '5px',
    cursor: 'pointer',
    pointerEvents: 'auto',
    transition: 'all .3s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  header: {
    fontFamily: 'boucherie-block',
    position: 'relative',
    width: '100%',
    minHeight: '50px',
    color: '#FF692B',
  },
  body: {
    width: '100%',
    minHeight: '50px',
    fontFamily: 'poppins',
    fontSize: '16px',
  },
  footer: {
    width: '100%',
    minHeight: '10px',
  },
  text: {
    fontFamily: "poppins",
    fontSize: 14,
	marginBottom: '1rem'
  },
};

const PostponePanel = ({

  style,
}) => {
  return (

      <Box sx={sx.root} style={style}>
        <Box sx={sx.container}>
          <Box sx={sx.header}>
            <h1>Girls Robots Dragons Sale Update!</h1>
          </Box>
          <Box sx={sx.content}>
              <Typography sx={sx.text}>
                We are experiencing issues. Working on the solution.
              </Typography>
   {/*            <Typography sx={sx.text}>
			  The whitelist limit is 25% of the total number of GRD NFTs in a wallet at the time of the snapshot. So, if an address has 100 GRD NFTs, that address can mint 25 NFTs during that first hour. Following the 1 hour grace period, the sale opens to everyone at 40 max per transaction with no wallet limit. 
              </Typography>
              <Typography sx={sx.text}>
			  Thank you everyone for the excellent feedback and support!
              </Typography> */}
            </Box>
        </Box>
      </Box>
  );
};

export default PostponePanel;
