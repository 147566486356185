import React from 'react';

import { Box, Modal } from '@mui/material';

import close from '../../../assets/images/white_close.svg';
import ExplorerCard from '../../ExplorerCard/Components/ExplorerCard';

const BP1 = '@media (max-width: 899px)';
const BP2 = '@media (max-width: 719px)';
const traitTypes = [
  {
    id: 1,
    name: 'Physical Redeemables',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/physical-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/physical-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/physical-black.svg',
  },
  {
    id: 2,
    name: 'Digital Redeemables',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/digital-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/digital-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/digital-black.svg',
  },
  {
    id: 3,
    name: 'Discount Traits',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/discount-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/discount-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/discount-black.svg',
  },
  {
    id: 4,
    name: 'Access Traits',
    icon_white: '/storage/app/uploads/public/trait_type_icons/access-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/access-orange.svg',
    icon_black: '/storage/app/uploads/public/trait_type_icons/access-black.svg',
  },
  {
    id: 5,
    name: 'Modifiers',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/modifier-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/modifier-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/modifier-black.svg',
  },
  {
    id: 6,
    name: 'Meeting',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/meeting-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/meeting-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/meeting-black.svg',
  },
  {
    id: 7,
    name: 'Badges',
    icon_white: '/storage/app/uploads/public/trait_type_icons/badge-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/badge-orange.svg',
    icon_black: '/storage/app/uploads/public/trait_type_icons/badge-black.svg',
  },
  {
    id: 8,
    name: 'Signature',
    icon_white: '/storage/app/uploads/public/trait_type_icons/access-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/autograph-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/autograph-black.svg',
  },
];
const sx = {
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: '#152d2ebf',
    position: 'absolute',
  },
  closeBtn: {
    position: 'absolute',
    width: '32px',
    min: '32px',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
};

const RenderModal = ({ isOpen, setOpen, metadata, imageContainerWidth }) => {
  //console.log(metadata);
  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => { 
         //if (reason !== 'backdropClick') {
        //   setOpen(false);
       //  }
        setOpen(false);
      }}
    >
      <Box
        sx={sx.root}
        style={{
          display: 'flex',
          jusifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => setOpen(false)}
      >
        <ExplorerCard
          metadata={metadata}
          traitTypes={traitTypes}
          imageContainerWidth={imageContainerWidth}
        />
        <Box sx={sx.closeBtn} onClick={() => setOpen(false)}>
          <img src={close} style={{ width: '100%' }} alt="Close" />
        </Box>
      </Box>
    </Modal>
  );
};

export default RenderModal;
