import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const sx = {
  footer: {
    fontSize: '12px',
    fontFamily: 'poppins',
    color: '#F7FCFB',
    maxWidth: '812px',
    margin: 'auto',
    marginTop: '60px',
    opacity: '0.4',
  },
};

const Footer = () => (
  <Typography align="center" sx={sx.footer}>
    The GRD Team makes no representation, warranty, or undertaking, express or implied, as to the accuracy, reliability, completeness, or reasonableness of the information on this page.
    Any assumptions, opinions, and details expressed on this site constitute the GRD Team’s judgment as of the date thereof and are subject to change without notice.
    Any projections or claims contained in the information are based on a number of assumptions including,
    but not limited to market conditions and the current status of the GRD project, and there can be no guarantee that any projected outcomes will be achieved.
  </Typography>
);

export default Footer;
