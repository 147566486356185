import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ExplorerCard from './Components/ExplorerCard.jsx'
import ClipLoader from 'react-spinners/ClipLoader';

const traitTypes = [
    {
        id: 1,
        name: 'Physical Redeemables',
        icon_white:
            '/storage/app/uploads/public/trait_type_icons/physical-white.svg',
        icon_orange:
            '/storage/app/uploads/public/trait_type_icons/physical-orange.svg',
        icon_black:
            '/storage/app/uploads/public/trait_type_icons/physical-black.svg',
    },
    {
        id: 2,
        name: 'Digital Redeemables',
        icon_white:
            '/storage/app/uploads/public/trait_type_icons/digital-white.svg',
        icon_orange:
            '/storage/app/uploads/public/trait_type_icons/digital-orange.svg',
        icon_black:
            '/storage/app/uploads/public/trait_type_icons/digital-black.svg',
    },
    {
        id: 3,
        name: 'Discount Traits',
        icon_white:
            '/storage/app/uploads/public/trait_type_icons/discount-white.svg',
        icon_orange:
            '/storage/app/uploads/public/trait_type_icons/discount-orange.svg',
        icon_black:
            '/storage/app/uploads/public/trait_type_icons/discount-black.svg',
    },
    {
        id: 4,
        name: 'Access Traits',
        icon_white: '/storage/app/uploads/public/trait_type_icons/access-white.svg',
        icon_orange:
            '/storage/app/uploads/public/trait_type_icons/access-orange.svg',
        icon_black: '/storage/app/uploads/public/trait_type_icons/access-black.svg',
    },
    {
        id: 5,
        name: 'Modifiers',
        icon_white:
            '/storage/app/uploads/public/trait_type_icons/modifier-white.svg',
        icon_orange:
            '/storage/app/uploads/public/trait_type_icons/modifier-orange.svg',
        icon_black:
            '/storage/app/uploads/public/trait_type_icons/modifier-black.svg',
    },
    {
        id: 6,
        name: 'Meeting',
        icon_white:
            '/storage/app/uploads/public/trait_type_icons/meeting-white.svg',
        icon_orange:
            '/storage/app/uploads/public/trait_type_icons/meeting-orange.svg',
        icon_black:
            '/storage/app/uploads/public/trait_type_icons/meeting-black.svg',
    },
    {
        id: 7,
        name: 'Badges',
        icon_white: '/storage/app/uploads/public/trait_type_icons/badge-white.svg',
        icon_orange:
            '/storage/app/uploads/public/trait_type_icons/badge-orange.svg',
        icon_black: '/storage/app/uploads/public/trait_type_icons/badge-black.svg',
    },
    {
        id: 8,
        name: 'Signature',
        icon_white: '/storage/app/uploads/public/trait_type_icons/access-white.svg',
        icon_orange:
            '/storage/app/uploads/public/trait_type_icons/autograph-orange.svg',
        icon_black:
            '/storage/app/uploads/public/trait_type_icons/autograph-black.svg',
    },
];
const CardRender = () => {
    const location = useLocation();
    const metadataURL = location.search.slice(1);
    const [metadata, setmetadata] = useState(undefined);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            axios.get(`https://${metadataURL}`).then((response) => {
                setmetadata(response.data);
                
                setLoading(false)
            }).catch((e) => {
                console.log(e);
            });
        })();
    }, []);
    return (
        <>
            <ClipLoader
                size={150}
                color="rgb(252, 108, 3)"
                loading={loading}
                css={{
                    position: 'absolute',
                    border: '5px solid rgb(252, 108, 3)',
                    borderBottomColor: 'transparent',
                    margin: 'auto',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
                speedMultiplier={0.5}
            />

            <Box className='card-overlay'>

                {metadata && <ExplorerCard
                    metadata={metadata}
                    traitTypes={traitTypes}
                    horizontalPadding={20}
                    imageContainerWidth={600}
                    claimDisabled={true}
                />
                }
            </Box>
        </>
    )
}

export default CardRender