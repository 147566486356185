import { useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { loadNext } from './InfiniteScrollHelpers';
import CardList from './CardList';

const ExplorerScroller = ({ nfts, filtersAreActive, showToken }) => {
  const ITEMS_PER_PAGE = 29;
  const [cards, setCards] = useState([]);
  const [currentPage, _setCurrentPage] = useState(0);
  const currentPageRef = useRef(currentPage);
  const setCurrentPage = (val) => {
    currentPageRef.current = val;
    _setCurrentPage(val);
  };

  useEffect(() => {
    setCards([]);
    setCurrentPage(0);
    loadNext(nfts, ITEMS_PER_PAGE, currentPageRef, setCurrentPage, setCards);
  }, [nfts]);

  return (
    <>
      <div style={{ paddingTop: '250px' }}></div>
      {filtersAreActive && (
        <p
          style={{
            color: '#faf9f5f5',
            maxWidth: '1155px',
            margin: 'auto',
            paddingLeft: '20px',
          }}
        >
          Filter result:{' '}
          <span style={{ fontWeight: '500', color: '#FC6405' }}>
            {nfts.length}{' '}
          </span>
        </p>
      )}
      <div>
        <InfiniteScroll
          dataLength={cards.length}
          next={() =>
            loadNext(
              nfts,
              ITEMS_PER_PAGE,
              currentPageRef,
              setCurrentPage,
              setCards
            )
          }
          pullDownToRefreshThreshold={500}
          hasMore={currentPageRef.current * ITEMS_PER_PAGE < nfts.length}
          // scrollThreshold="200px"
          // scrollableTarget="content-container"
          // initialScrollY={1000}
          loader={<h4>Loading...</h4>}
        >
          <CardList metadata={cards} showToken={showToken} />
        </InfiniteScroll>
      </div>
    </>
  );
};

export default ExplorerScroller;
