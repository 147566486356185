import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import VideoPlayer from './VideoPlayer';

const sx = {
  walletTitle: {
    fontFamily: 'boucherie-block',
    fontSize: '40px',
    color: '#CACCB5',
    lineHeight: '45px',
    maxWidth: '590px',
    textAlign: 'center',
    margin: 'auto',
    mb: '30px',
  },
  description: {
    fontFamily: 'poppins',
    fontSize: '16px',
    lineHeight: '29px',
    color: '#D2D4C2',
    maxWidth: '750px',
    margin: 'auto',
  },
};
const DustClaimingProcedure = () => {
  const [openModal, setOpenModal] = useState(false);

  const hanldeOpenVideoModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Box>
        <Typography sx={sx.walletTitle}>
          How to claim $DUST utility tokens from GRD NFTs
        </Typography>
        <Typography sx={sx.description}>
          In order to claim $DUST Utility Tokens, first move the GRD NFTs to
          MATIC/Polygon Layer 2 blockchain network. (Bridge transfers are
          limited to 20 NFTs per transaction.)
          <br />
          <br />
          There are a few things to note:
          <br />
          <br />
          The first time GRD NFTs are transferred - approve tokens to transfer.
          <br />
          <br />
          Follow the instruction to move the NFTs across the bridge.
          <br />
          <br />
          GRD NFTs will be received on the polygon network in about 30-45
          minutes.
          <br />
          <br />
          Once the transfer is complete, DUST Utility Tokens may also be claimed
          from the NFTs.
          <br />
          <br />
          A small amount of MATIC tokens are required on the Polygon L2 network.
          Based on the snapshot taken early Friday, November 25th, Galaxis has
          airdropped 1 MATIC token to unique wallet addresses containing GRD
          NFTs.
          <br />
          <br />
          Galaxis recommends keeping the NFTs on Polygon as Utility Traits,
          metagame, forge, second series and future development are all coming
          to Polygon!
        </Typography>
        {/* <Button variant='contained' onClick={hanldeOpenVideoModal}>
          WATCH EXPLAINER VIDEO
        </Button> */}
      </Box>

      {/* {openModal && (
        <VideoPlayer
          closeVideo={() => setOpenModal(false)}
          video={'https://www.youtube.com/embed/Tpfr4fCUEEk'}
        />
      )} */}
    </>
  );
};

export default DustClaimingProcedure;
