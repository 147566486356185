import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import bodyicon from '../../assets/images/bodyicon.svg';
import grdlogo from '../../assets/images/logo.png';
import grdlogo1 from '../../assets/images/grd-logo2.png';
import backgroundIcon from '../../assets/images/backgroundIcon.png';
import decoration_v1 from '../../assets/images/decoration_v1.svg';
import galaxisLogo from '../../assets/images/galaxis-logo.svg';
import decorPoolsTraits from '../../assets/images/decoration_pools_traits.svg';
import angel from '../../assets/images/arts/angel_sword.jpg';
import drawing_example from '../../assets/images/arts/drawing_example.jpg';
import banner from '../../assets/images/mobile-images/banner-new-mobile.jpg';
import women from '../../assets/images/mobile-images/woman-new-mobile.png';
import bannerMobile from '../../assets/images/mobile-images/banner-new-mobile-2.jpg';
import womenMobile from '../../assets/images/mobile-images/woman-new-mobile-2.png';

import Header from '../../Components/Header/Header';
import Card from '../../Components/TraitAnimation/Card';
import Decoration from '../../Components/Decoration';
import Footer from '../../Components/Footer';
import ModalRewards from '../../Components/TraitAnimation/Claim/ModalRewards';
import GalleryModal from '../../Components/GalleryModal';
import FullscreenModal from '../../Components/FullscreenModal';
import draw6 from '../../assets/images/sketches/09exigo.jpg';
import draw7 from '../../assets/images/sketches/13exigo.jpg';
import draw9 from '../../assets/images/sketches/15exigo.jpg';
import { useMediaQuery } from 'react-responsive';
import spriteSheet from '../../assets/images/spritesheet.png';
import './Home.css';
import dragonHeader from '../../assets/images/front-images/dragon-header1.png';
import robotHeader from '../../assets/images/front-images/robot-header1.png';
import womanHeader from '../../assets/images/front-images/woman-header1.png';
import womanHeader2 from '../../assets/images/front-images/woman-header2.png';
import dragonHeader2 from '../../assets/images/front-images/dragon-header2.png';
import discord from '../../assets/images/social/discord.svg';
import twitter from '../../assets/images/social/twitter.svg';
import calendar from '../../assets/images/social/nftcalendar.png';
import discordo from '../../assets/images/social/discord_orange.svg';
import twittero from '../../assets/images/social/twitter_orange.svg';
import calendaro from '../../assets/images/social/nftcalendar_orange.png';

import osIcono from '../../assets/images/social/opensea.svg';
import osIcon from '../../assets/images/social/opensea_orange.svg';

import galaxisIcono from '../../assets/images/social/galaxis_swirl_only.svg';
import galaxisIcon from '../../assets/images/social/galaxis_swirl_only_orange.svg';

import presaleDecoration from '../../assets/images/presale_decoration.svg';
import saleDecoration from '../../assets/images/sale_decoration.svg';
import banner2 from '../../assets/images/header-banner.jpg';
import {
  galleryImages,
  slideImages,
  girlImages,
  dragonImages,
  robotImages,
  collections,
  artImages,
  collection,
} from './data';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import zIndex from '@mui/material/styles/zIndex';
import Sales from '../../Components/SaleComponent/Sales';
import Counter from '../../Components/Counter/Counter';
import config from '../../config/config';
import useWeb3Ctx from '../../hooks/useWeb3Ctx';
import TwitterSection from '../../Components/Twitter/TwitterSection';
import SalesV3 from '../../Components/SaleComponent/SalesV3';

import newHeaderImage from "../../assets/images/new-header/grd_new_header.png"
import serpent from "../../assets/images/new-header/serpent.png"
import mobileHeader from "../../assets/images/new-header/mobileHeader.png"
import DecoratedTitle from '../../Components/DecoratedTitle';
const Carousel = lazy(() => import('./components/Carousel'));
const RulesGallery = lazy(() => import('./components/RulesGallery'));
const GameTable = lazy(() => import('./components/GameTable'));
const Levels = lazy(() => import('./components/Levels'));

const BP1 = '@media (max-width: 580px)';
const BP2 = '@media (max-width: 500px)';
const BP4 = '@media (max-width: 600px)';
const BP5 = '@media (max-width: 410px)';
const BP6 = '@media (max-width: 900px)';
const BP7 = '@media (max-width: 1024px)';

const sx = {
  body: {
    pt: '18px',
    pb: '18px',
    mt: '-10px',
    // backgroundColor: '#152D2E',
  },
  content: {
    margin: 'auto',
    px: '20px',
    pb: '95px',
  },
  backgroundIcon: {
    position: 'absolute',
    margin: 'auto',
    width: '75%',
    marginTop: '100px',
  },
  line: {
    background: 'linear-gradient(0.25turn, #393A2A, #D1D3C1)',
    width: '100%',
  },
  bodyicon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    transform: 'rotate(180deg)',
  },
  description: {
    margin: 'auto',
    maxWidth: '715px',
    fontFamily: 'poppins',
    fontSize: '14px',
    color: '#D2D4C2',
    textAlign: 'center',
    lineHeight: '1.8',
  },
  description_v1: {
    margin: 'auto',
    maxWidth: '715px',
    fontFamily: 'poppins',
    fontSize: '14px',
    color: '#D2D4C2',
    textAlign: 'center',
    lineHeight: '1.8',
    [BP6]: {
      mt: '50px',
    },
  },
  buyButton: {
		height: 'auto',
		fontWeight: '500',
		fontSize: '20px',
		lineHeight: 'inherit',
		textTransform: 'uppercase',
		padding: '6px 16px',
		borderRadius: '5px',
		whiteSpace: 'nowrap',
		backgroundColor: '#FF692B',
		border: '2px solid #FF692B',
		fontFamily: 'boucherie-block',
		color: '#fff',
		'&:hover': {
			border: '2px solid #fff',
			backgroundColor: '#fff',
			color: '#FF692B',
		},
		'&:disabled': {
			opacity: '0.7'

		},
  },
  date: {
    color: '#D2D4C2',
    fontFamily: 'boucherie-block',
    fontSize: '46px',
    mt: '50px',
    mb: '50px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    maxWidth: '1280px',
    margin: 'auto',
    maxHeight: '100%',
    overflowY: 'auto',
    minHeight: '300px',
  },
  title: {
    fontFamily: 'boucherie-block',
    fontSize: '40px',
    textAlign: 'center',
    lineHeight: '45px',
    color: '#D1D3C1',
  },
  subTitle: {
    fontFamily: 'boucherie-block',
    fontSize: '40px',
    textAlign: 'center',
    lineHeight: '45px',
    color: '#D1D3C1',
    margin: 'auto',
    my: '50px',
    maxWidth: '700px',
  },
  subTitle2: {
    fontFamily: 'boucherie-block',
    fontSize: '40px',
    textAlign: 'center',
    lineHeight: '45px',
    color: '#D1D3C1',
    margin: 'auto',
    my: '50px',
    maxWidth: '700px',
    [BP2]: {
      maxWidth: '350px',
    },
  },
  highlineText: {
    fontFamily: 'boucherie-block',
    fontSize: '25px',
    color: '#D1D3C1',
    textAlign: 'center',
    margin: 'auto',
    my: '50px',
    maxWidth: '700px',
  },
  firstDescHolder: {
    margin: 'auto',
    mt: '-45px',
    mb: '85px',
    maxWidth: '700px',
    position: 'relative',
    zIndex: '2',
    [BP1]: {
      mt: '0px',
    },
  },
  gallery: {
    maxWidth: '100%',
    display: 'flex',
    gap: '16px',
    flexDirection: 'row',
    margin: 'auto',
    [BP6]: {
      flexWrap: 'wrap',
      maxWidth: '600px',
    },
  },
  frontImage: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: '25%',
    width: '100%',
    height: `calc(160% - 50px)`,
    marginTop: '0',
    backgroundImage: `url(${spriteSheet})`,
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  newHeader:{
    height:"530px",
    [BP7]:{
      display:"none"
    }
  },
  social: {
    position: 'relative',
    margin: 'auto',
    width: 'min-content',
    display: 'flex',
    mt: '-105px',
    mb: '30px',
    zIndex: '10',
    [BP6]: {
      mt: '-40px',
    },
    [BP2]: {
      mt: '-10px',
    },
  },
  mobileHeader:{
    display:"none",
    [BP7]:{
      display:"block",
      paddingTop:"50px"
    },
    '& img': {
      maxWidth: '100%',
    },
  }
};
const Home = () => {
  const location = useLocation();
  const history = useHistory();
  const dropRef = useRef(null);
  const benefitsRef = useRef(null);
  const bonusArtRef = useRef(null);
  const dustProofRef = useRef(null);
  const redeemablesRef = useRef(null);
  const demoRef = useRef(null);
  const gameRef = useRef(null);
  const presaleRef = useRef(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fullscreenSrc, setFullscreenSrc] = useState(null);
  const [openRank, setOpenRank] = useState(false);
  const [openDraw, setOpenDraw] = useState(false);
  const [orangeTwitterIcon, setOrangeTwitterIcon] = useState(false);
  const [orangeDiscordIcon, setOrangeDiscordIcon] = useState(false);
  const [orangeCalendarIcon, setOrangeCalendarIcon] = useState(false);
  const [orangeOpenseaIcon, setOrangeOpenseaIcon] = useState(false);
  const [orangeGalaxisIcon, setOrangeGalaxisIcon] = useState(false);
  const { address, handleDisconnect } = useWeb3Ctx();

  const setFullscreenSource = (src) => {
    setFullscreenSrc(src);
    setOpenRank(true);
  };
  const idArray = ['womanHeader', 'robotHeader', 'dragonHeader', 'womanHeader2'];
  const DATE = new Date('2022-06-06T13:00:00.000Z');
  const [open, setOpen] = useState(false);
  const [openSide, setOpenSide] = useState(false);
  const [openCollection, setOpenCollection] = useState(false);
  const [carouselId, setCarouselId] = useState(null);

  const isBigScreen = useMediaQuery({ query: '(min-width: 600px)' });

  const fullscreenCarousel = (id) => {
    setCarouselId(id);
  };
  const scrollToRef = (ref) => {
    window.scrollTo({
      left: 0,
      top: ref.current.offsetTop - 200,
      behavior: 'smooth',
    });
  };

  const clickedToTheSameLink = () => {
    if (location.hash === '#collection') {
      scrollToRef(dropRef);
    } else if (location.hash === '/#') {
      window.scrollTo(0, 0);
    } else if (location.hash === '#game') {
      scrollToRef(gameRef);
    } else if (location.hash === '#benefits') {
      scrollToRef(benefitsRef);
    } else if (location.hash === '#demo-nft') {
      scrollToRef(demoRef);
    } else if (location.hash === '#pre-sale') {
      scrollToRef(presaleRef);
    } else if (location.hash === '#dust') {
      scrollToRef(dustProofRef);
    } else if (location.hash === '#redeemables') {
      scrollToRef(redeemablesRef);
    }
  };
  useEffect(() => {
    if (location.hash === '#collection') {
      scrollToRef(dropRef);
    } else if (location.hash === '/#') {
      window.scrollTo(0, 0);
    } else if (location.hash === '#game') {
      scrollToRef(gameRef);
    } else if (location.hash === '#benefits') {
      scrollToRef(benefitsRef);
    } else if (location.hash === '#demo-nft') {
      scrollToRef(demoRef);
    } else if (location.hash === '#pre-sale') {
      scrollToRef(presaleRef);
    } else if (location.hash === '#dust') {
      scrollToRef(dustProofRef);
    } else if (location.hash === '#redeemables') {
      scrollToRef(redeemablesRef);
    }
  }, [location.hash]);

  // useEffect(() => {
  //   let counter = 1;
  //   const intervalId = setInterval(() => {
  //     document
  //       .querySelectorAll('.active-header')
  //       .forEach((e) => e.classList.remove('active-header'));
  //     const selectedElem = document.getElementById(`${idArray[counter]}`);
  //     selectedElem.classList.add('active-header');
  //     if (counter < idArray.length - 1) {
  //       counter++;
  //     } else {
  //       counter = 0;
  //     }
  //   }, 4800);
  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <>
      <Box sx={sx.root}>
        <Header
          clickedToTheSameLink={clickedToTheSameLink}
          address={address}
          handleDisconnect={handleDisconnect}
        />
        <Box style={{ position: 'relative' }}>
          <Box sx={sx.newHeader}>
            <div className='bannerImg'>
              <img src={newHeaderImage}/>
            </div>
            <div className='serpent'>
              <img src={serpent}/>
            </div>
          </Box>
          <Box sx={sx.mobileHeader}>
            <img src={mobileHeader}/>
          </Box>
          <Box
            className='header-animation grd-logo'>
            <img src={grdlogo} alt="icon" width="100%" className='header-animation-img grd-logo' />
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
      <Box sx={sx.body}>
        {/* <Box
          sx={sx.line}
          style={{
            height: '16px',
          }}
        ></Box>
        <Box
          sx={sx.line}
          style={{
            marginTop: '5px',
            height: '5px',
          }}
        ></Box> */}
        <Box sx={sx.content}>
          {/* <Box sx={sx.bodyicon}>
            <img src={bodyicon} alt="icon" style={{ width: '110px' }} />
          </Box> */}
          <Box
            style={{
              position: 'relative',
              maxWidth: '1280px',
              margin: 'auto',
              zIndex: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {/* <Box sx={sx.backgroundIcon}>
              <LazyLoadImage src={backgroundIcon} alt="icon" width="100%" />
            </Box> */}
          </Box>
          <Box
            className='grd-logo-box'
            style={{
              position: 'relative',
              zIndex: 1,
              margin: 'auto',
              opacity: 0,

            }}
          >
            <img src={grdlogo} alt="icon" width="100%" />
          </Box>

          {/* 
            #################################
            #
            # ART
            #
            #################################
          */}
          {/* 
          <Decoration
            ref={artRef}
            text="The art"
            style={{ maxHeight: '84px' }}
            img={decoration_v1}
          /> */}
          <Box sx={sx.social}>
            <img
              src={orangeDiscordIcon ? discord : discordo}
              alt="unset"
              style={{
                marginRight: '20px',
                width: '35px',
                height: 'auto',
              }}
              className="header-social-icons"
              onMouseOver={() => setOrangeDiscordIcon(true)}
              onMouseLeave={() => setOrangeDiscordIcon(false)}
              onClick={() => {
                window.open('https://discord.gg/YAHJghT5ZK', '_blank');
              }}
            />
            <img
              src={orangeTwitterIcon ? twitter : twittero}
              alt="unset"
              className="header-social-icons"
              style={{
                marginRight: '15px',
                width: '35px',
                height: 'auto',
              }}
              onMouseOver={() => setOrangeTwitterIcon(true)}
              onMouseLeave={() => setOrangeTwitterIcon(false)}
              onClick={() => {
                window.open('https://twitter.com/GRD_Fans', '_blank');
              }}
            />
            <img
              src={orangeCalendarIcon ? calendar : calendaro}
              alt="unset"
              onMouseOver={() => setOrangeCalendarIcon(true)}
              onMouseLeave={() => setOrangeCalendarIcon(false)}
              className="header-social-icons"
              style={{
                marginRight: '15px',
                width: '35px',
                height: 'auto',
                transform: 'translateY(4px)',
              }}
              onClick={() => {
                window.open(
                  'https://nftcalendar.io/event/girls-robots-dragons',
                  '_blank'
                );
              }}
            />

            <img
              src={orangeOpenseaIcon ? osIcono : osIcon}
              alt="unset"
              onMouseOver={() => setOrangeOpenseaIcon(true)}
              onMouseLeave={() => setOrangeOpenseaIcon(false)}
              className="header-social-icons"
              style={{
                marginRight: '15px',
                width: '35px',
                height: 'auto',
              }}
              onClick={() => {
                window.open(
                  'https://opensea.io/collection/girls-robots-dragons-official',
                  '_blank'
                );
              }}
            />

            <img
              src={orangeGalaxisIcon ? galaxisIcono : galaxisIcon}
              alt="unset"
              onMouseOver={() => setOrangeGalaxisIcon(true)}
              onMouseLeave={() => setOrangeGalaxisIcon(false)}
              className="header-social-icons"
              style={{
                marginRight: '15px',
                width: '35px',
                height: 'auto',
              }}
              onClick={() => {
                window.open('https://galaxis.xyz', '_blank');
              }}
            />
          </Box>

          <Typography
            ref={dropRef}
            sx={sx.description}
            style={{
              marginBottom: '50px',
              zIndex: '2',
              position: 'relative',
            }}
          >
            This exclusive collection was created by the legendary artists{' '}
            <a href="https://boros-szikszai.com/" target="_blank">
              Zoltan Boros and Gabor Szikszai
            </a>
            . <br />
            There are 15 main characters (5 girls, 5 robots, 5 dragons) in front
            of two randomly selected layers.
          </Typography>

          {/* <DecoratedTitle>
             STICKERBook
          </DecoratedTitle>
          <Box sx={{ textAlign: 'center',marginBottom:'3rem'}}>
            <Typography
                variant="text"
                component="p"
                sx={{ mb: 4, color: '#D2D4C2', marginTop: '2rem' }}
              >
                Reward claim is open! If you have completed a GRD Stickerbook, claim your reward now!
            </Typography>

            <Button
              variant="banner"
              sx={sx.buyButton}
              
              style={{ margin: '10px',height: '60px' }}
              href='https://grd-rewards.galaxis.xyz/ '
              target='_blank'>

              CLAIM REWARD
            </Button>


          </Box> */}


          <div ref={presaleRef} />
          <SalesV3
            tokenAddress={config.GRD_TOKEN_CONTRACT}
            saleAddress={config.GRD_SALE_CONTRACT}
            symbol={'Girls, Robots, Dragons'}
            labels={{
              preSaleTitle: 'Whitelist Mint',
              preSaleDescription:
                'For GRD holders only!',
              publicSaleTitle: 'Dust Sale',
              publicSaleDescription: 'Open for everyone!',
            }}
            whitelist={null}
          />
          {/*  <div style={{marginTop:'40px'}}/>
          <Suspense fallback={<div>Loading carousel...</div>}>
            <Carousel
              
              collections={collections}
              onClick={(e) => {
                setOpenCollection(true);
                fullscreenCarousel(e);
              }}
            />
          </Suspense> */}

          {/* 
            #################################
            #
            # SALE
            #
            #################################
          */}

          {/*           <Decoration
            ref={presaleRef}
            text="PRE-SALE"
            img={presaleDecoration}
            style={{
              marginBottom: '60px',
              marginTop: '60px',
              maxHeight: '84px',
            }}
          />
          <Typography
            align="center"
            sx={sx.date}
            style={{
              marginTop: '75px',
              marginBottom: '10px',
            }}
          >
            JUNE 06, 2022
          </Typography>
          <Typography
            sx={sx.subTitle2}
            style={{ fontSize: '35px', marginTop: '0', color: '#D2D4C2' }}
          >
            3000 Pieces Only @ 0.07 ETH
          </Typography>

          <Counter date={DATE} />
          <Button
            sx={sx.button}
            variant="contained"
            style={{
              marginBottom: '20px',
              width: '265px',
              height: '60px',
            }}
            href="https://www.premint.xyz/grd/"
            target="_blank"
          >
            Pre-sale registration
          </Button>
          <Typography sx={sx.description}>
            Registration for Pre-Sale ends on June 3, 2022
          </Typography>

          <Typography sx={sx.highlineText} style={{ marginBottom: '85px' }}>
            {' '}
          </Typography>
          <Decoration
            text="SALE"
            img={saleDecoration}
            style={{
              marginBottom: '60px',
              marginTop: '60px',
              maxHeight: '84px',
            }}
          />
          <Typography
            align="center"
            sx={sx.date}
            style={{
              marginTop: '75px',
              marginBottom: '0px',
            }}
          >
            JUNE 07, 2022
          </Typography>
          <Typography sx={sx.highlineText} style={{ marginBottom: '85px' }}>
            {' '}
          </Typography> */}

          {/* 
            #################################
            #
            # TWITTER
            #
            #################################
          */}

          <TwitterSection />
          {/* 
            #################################
            #
            # BENEFITS (UTILITY TRAITS) 
            #
            #################################
          */}

          <Decoration
            ref={benefitsRef}
            text="BENEFITS"
            img={decoration_v1}
            style={{
              marginBottom: '75px',
              marginTop: '75px',
              maxHeight: '84px',
            }}
          />

          <Typography sx={sx.subTitle} style={{ marginTop: '85px' }}>
            <span
              className="decorated-text"
              onClick={() => {
                scrollToRef(bonusArtRef);
              }}
            >
              collect bonus art!
            </span>
          </Typography>

          <Typography sx={sx.subTitle}>
            <span
              className="decorated-text"
              onClick={() => {
                scrollToRef(gameRef);
              }}
            >
              play & Win ETH!
            </span>
          </Typography>

          <Typography sx={sx.subTitle}>
            <span
              className="decorated-text"
              onClick={() => {
                scrollToRef(redeemablesRef);
              }}
            >
              Merch!
            </span>
          </Typography>

          <Typography sx={sx.subTitle} style={{ marginBottom: '85px' }}>
            <span
              className="decorated-text"
              onClick={() => {
                scrollToRef(dustProofRef);
              }}
            >
              Get your purchase price back!!!
            </span>
          </Typography>

          {/* 
            #################################
            #
            # BONUS ART
            #
            #################################
          */}

          <Decoration
            ref={bonusArtRef}
            text="BONUS ART"
            img={decoration_v1}
            style={{
              marginBottom: '60px',
              marginTop: '60px',
              maxHeight: '84px',
            }}
          />
          <Typography
            sx={sx.description}
            style={{ marginTop: '85px', marginBottom: '50px' }}
          >
            As a tribute to the artists' decades-long history, the back of every
            NFT will showcase one of their classic artworks. The images on the
            back will be determined by the layer combination on the front.
            Different layer combos will produce different classic art.
          </Typography>
          <Suspense fallback={<div>Loading carousel...</div>}>
            <Carousel
              collections={artImages}
              onClick={(e) => {
                setOpenSide(true);
                fullscreenCarousel(e);
              }}
            />
          </Suspense>

          {/* 
            #################################
            #
            # THE GAME
            #
            #################################
          */}

          <Decoration
            ref={gameRef}
            text="The Game"
            img={decorPoolsTraits}
            style={{
              maxHeight: '84px',
            }}
          />
          <Typography
            sx={sx.highlineText}
            style={{ fontSize: '30px', marginTop: '85px' }}
          >
            PRIZE POOL: 20% of total sale + 25% of royalties!
          </Typography>

          <Typography sx={sx.subTitle}>STEP 1: COLLECT THE FULL SET</Typography>

          <Suspense fallback={<div>Loading...</div>}>
            <RulesGallery
              images={girlImages}
              setFullscreenSource={setFullscreenSource}
            />
            <RulesGallery
              images={dragonImages}
              setFullscreenSource={setFullscreenSource}
            />
            <RulesGallery
              images={robotImages}
              setFullscreenSource={setFullscreenSource}
            />
          </Suspense>

          <Typography sx={sx.subTitle}>STEP 2: Redeem Prizes</Typography>
          <Suspense fallback={<div>Loading...</div>}>
            <GameTable />
          </Suspense>

          <Typography sx={sx.subTitle}>Rarity Explanation</Typography>

          <Suspense fallback={<div>Loading ...</div>}>
            <Levels />
          </Suspense>

          <FullscreenModal
            setOpen={setOpenRank}
            isOpen={openRank}
            imgSrc={fullscreenSrc}
          />
          <Typography sx={sx.description_v1}>
            After submitting your collection, you are going to keep your NFTs,
            but you cannot use them to submit more than once.
          </Typography>
          <Box style={{ marginTop: '50px', marginBottom: '50px' }}>
            <Typography sx={sx.subTitle}>The Galaxis Forges</Typography>
            <Typography sx={sx.description} style={{ marginTop: '50px' }}>
              Naturally occurring “perfect” layer combos will be extremely rare,
              or even non-existent. That is why the Galaxis Layer Forge will
              enable players to “forge” layers from two different cards, thus
              creating a “perfect” layer combo. The forge will cost gas fees
              (naturally) and a sacrifice will have to be made -- one of the two
              cards will burn away. (You can select which one to keep.)
            </Typography>
            <Typography sx={sx.description} style={{ marginTop: '50px' }}>
              You can also use the Galaxis Character Forge to forge 5 Common
              versions of any character into a Rare one. The sacrifice is
              greater here -- you will lose 4 Commons to turn the fifth one into
              a Rare.
            </Typography>
            <Typography sx={sx.description} style={{ marginTop: '50px' }}>
              The Galaxis Forges will come alive shortly after the end of the
              sale.
            </Typography>
          </Box>
          <Box style={{ marginTop: '50px', marginBottom: '85px' }}>
            <Typography sx={sx.subTitle}>Further Releases</Typography>
            <Typography sx={sx.description} style={{ marginTop: '50px' }}>
              There is an optional additional set of 6000 cards that will
              benefit the original 3k holders, if the decision will be made to
              release the set. Details coming soon.
            </Typography>
          </Box>

          {/* 
            #################################
            #
            # REDEEMABLES
            #
            #################################
          */}

          <Decoration
            ref={redeemablesRef}
            text="REDEEMABLES"
            img={decoration_v1}
            style={{
              marginBottom: '60px',
              marginTop: '60px',
              maxHeight: '84px',
            }}
          />
          <Typography
            sx={sx.description}
            style={{ marginTop: '75px', marginBottom: '50px' }}
          >
            These cards can do much more than being pretty. Among other things,
            they have awesome utilities: redeemables, autographs, and meetings
            with the artists. The currently confirmed utilities are:
          </Typography>
          <Typography sx={sx.title}>One Acrylic Painting</Typography>
          <Typography
            sx={sx.description}
            style={{ marginTop: '55px', marginBottom: '50px' }}
          >
            The artists have agreed to create a brand new acrylic painting (on
            stretched canvas) for the very lucky person, who redeems these
            unique traits (terms and conditions apply). Following is an example
            of their work - copyright by Wizards of the Coast - only showed as
            an illustration:
          </Typography>
          <Box
            style={{
              maxWidth: '470px',

              margin: 'auto',
            }}
          >
            <img
              width="100%"
              src={angel}
              alt="icon"
              style={{ borderRadius: '15px' }}
            />
          </Box>
          <Typography sx={sx.title} style={{ marginTop: '50px' }}>
            Five Online Meetings
          </Typography>
          <Typography
            sx={sx.description}
            style={{ marginTop: '55px', marginBottom: '50px' }}
          >
            Five very lucky individuals are going to have an opportunity to set
            up an online meeting with the artists, and talk with them for an
            hour about art, ask questions and advice from the artists, or even
            ask them to draw something simple for them (only digitally). (Be
            mindful of the time limit, terms and conditions apply!)
          </Typography>
          <Typography sx={sx.title}>Six three-tone drawings</Typography>
          <Typography
            sx={sx.description}
            style={{ marginTop: '55px', marginBottom: '50px' }}
          >
            The artists are going to create six unique three-tone images of the
            subject of their own choice, similar to the sample shown below. (The
            image is copyrighted by Wizards of the Coast, shown as an example of
            the technique.)
          </Typography>
          <Box
            style={{
              maxWidth: '470px',
              margin: 'auto',
              marginBottom: '70px',
            }}
          >
            <img
              width="100%"
              src={drawing_example}
              alt="icon"
              style={{ borderRadius: '15px' }}
            />
          </Box>
          <Typography sx={sx.title}>Ten sketches</Typography>
          <Typography
            sx={sx.description}
            style={{ marginTop: '55px', marginBottom: '50px' }}
          >
            The artists provided 10 of their historical sketches as redeemable
            art. Anyone who has one of these traits can redeem one of the
            pieces, on a first-come-first-serve basis.
          </Typography>
          <Box sx={sx.gallery}>
            <Box>
              <img
                width="100%"
                src={draw6}
                style={{ borderRadius: '15px' }}
                alt="icon"
              />
            </Box>
            <Box>
              <img
                width="100%"
                src={draw7}
                style={{ borderRadius: '15px' }}
                alt="icon"
              />
            </Box>
            <Box>
              <img
                width="100%"
                src={draw9}
                style={{ borderRadius: '15px' }}
                alt="icon"
              />
            </Box>
          </Box>
          <Button
            onClick={() => setOpenDraw(true)}
            variant="contained"
            style={{ marginTop: '40px', marginBottom: '60px' }}
          >
            MORE
          </Button>
          <Typography sx={sx.title}>100 Autographs</Typography>
          <Typography
            sx={sx.description}
            style={{ marginTop: '55px', marginBottom: '50px' }}
          >
            One hundred lucky redeemers can use our new autograph functionality,
            submit their names, and observe the artists’ message and autograph
            appear on their NFT!
          </Typography>
          <Typography sx={sx.title}>
            300 pieces of signed and numbered prints
          </Typography>
          <Typography
            sx={sx.description}
            style={{ marginTop: '55px', marginBottom: '90px' }}
          >
            By redeeming one of these traits, the lucky owner will receive a
            signed and numbered print of one of the 15 main characters from the
            collection.
          </Typography>

          {/* 
            #################################
            #
            # DUST PROOFING
            #
            #################################
          */}

          <Decoration
            ref={dustProofRef}
            text="Dust Proofed!"
            img={decoration_v1}
            style={{
              marginBottom: '60px',
              marginTop: '60px',
              maxHeight: '84px',
            }}
          />

          <Typography sx={sx.title}>
            Get back the purchase price of your NFTs!
          </Typography>
          <Typography sx={sx.description} style={{ marginTop: '55px' }}>
            We left the best for last. With Galaxis' amazing Dust Proofing
            technology, the buyers of the first 3000 cards will get back the
            purchase price in DUST, over a period of a year. DUST will start
            trickling on your card on day 1, and will continue to do so until
            the full amount is returned.
          </Typography>
          <Typography
            sx={sx.description}
            style={{ marginTop: '20px', marginBottom: '90px' }}
          >
            (The amount is calculated and set on the day of the purchase, based
            on exhange rates from that day and will not change)
          </Typography>

          {/* 
            #################################
            #
            # DEMO CARD
            #
            #################################
          */}

          <Decoration
            ref={demoRef}
            text="Demo card"
            style={{ maxHeight: '84px' }}
            img={decoration_v1}
          />
          <Box style={{ marginTop: '70px', marginBottom: '10px' }}>
            <Typography sx={sx.description} style={{ marginBottom: '50px' }}>
              Click on the example NFT to discover utilities and features
            </Typography>
            <Card />
            {/* <Button
              sx={sx.button}
              variant="contained"
              style={{
                width: '250px',
                height: '60px',
              }}
              href="https://www.premint.xyz/grd/"
              target="_blank"
            >
              PRE-SALE REGISTRATION
            </Button> */}
          </Box>
          {/* <Decoration
            ref={charactersRef}
            text="Characters"
            style={{ maxHeight: '84px' }}
            img={decoration_v1}
          />
          <StoryPart /> */}

          <Typography
            align="center"
            sx={sx.date}
            style={{
              fontSize: '20px',
              marginBottom: '10px',
              marginTop: '10px',
            }}
          >
            POWERED BY
          </Typography>
          <Box
            style={{ maxWidth: '150px', margin: 'auto', marginBottom: '60px' }}
          >
            <img
              onClick={() => {
                window.open('https://galaxis.xyz/#/', '_blank');
              }}
              width="100%"
              src={galaxisLogo}
              alt="icon"
              style={{ cursor: 'pointer' }}
            />
          </Box>

          <Footer />
        </Box>
        <Box
          sx={sx.line}
          style={{
            height: '16px',
          }}
        ></Box>
        <Box
          sx={sx.line}
          style={{
            marginTop: '5px',
            height: '5px',
          }}
        ></Box>
      </Box>
      <Modal
        sx={sx.modal}
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalRewards onClose={() => setModalIsOpen(false)} />
      </Modal>
      <GalleryModal
        images={slideImages}
        isOpen={openSide}
        id={carouselId}
        setOpen={setOpenSide}
        style={{ maxWidth: '610px' }}
      />
      <GalleryModal
        images={galleryImages}
        isOpen={openDraw}
        setOpen={setOpenDraw}
      />
      <GalleryModal
        images={collection}
        id={carouselId}
        isOpen={openCollection}
        setOpen={setOpenCollection}
        style={{ maxWidth: '570px' }}
      />
    </>
  );
};

export default Home;
