import { Box, Grid, Typography, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import WalletCards from './WalletCards';
import { images } from '../components/collection-game-data';
import PlaceholderCard from './PlaceholderCard';
import right_arrow from '../../../assets/images/right_arrow.svg';
import left_arrow from '../../../assets/images/left_arrow.svg';

const sx = {
  nftGallery: {
    maxWidth: '1140px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '15px',
    flexWrap: 'wrap',
    mx: 'auto',
  },
  button: {
    minWidth: '18px',
    cursor: 'pointer',
  },
  paginationText: {
    color: '#CACCB5',
  },
  paginationTitle: {
    fontFamily: 'boucherie-block',
    fontSize: '30px',
    color: '#CACCB5',
    lineHeight: '45px',
    textAlign: 'center',
    margin: 'auto',
    mb: '15px',
    width: '100%',
  },
};

const CollectionWallet = ({
  tokenMetas,
  traitTypes,
  etherscanLink,
  scrollTo,
  detailsDisabled,
  style,
  tab,
  handleClick,
  selectedmaxPageCount,
  setnftsPaginationTab,
  nftsPaginationTab,
  title

}) => {
  const [mdGrid, setMdGrid] = useState(2.4);
  const [xsGrid, setXsGrid] = useState(12);
  const [smGrid, setSmGrid] = useState(4);

  useEffect(() => {
    if (window.innerWidth < 950) {
      setMdGrid(4);
    }
    if (window.innerWidth < 890) {
      setSmGrid(4);
    }
    if (window.innerWidth < 760) {
      setSmGrid(6);
    }
    if (window.innerWidth < 600) {
      setXsGrid(6);
    }
    if (window.innerWidth < 450) {
      setXsGrid(12);
    }
  }, []);
  const decreaseTab = () => {
    setnftsPaginationTab(nftsPaginationTab - 1);
  };
  const increaseTab = () => {
    setnftsPaginationTab(nftsPaginationTab + 1);
  };
  // console.log(tokenMetas);
  return (
    <>
      <Box sx={sx.nftGallery} style={style}>
        <Typography sx={sx.paginationTitle}>
          {title} Collection{' '}
          <span style={{ color: '#FF692B' }}>#{nftsPaginationTab}</span>
        </Typography>
        <Button
          onClick={decreaseTab}
          disabled={nftsPaginationTab === 1}
          sx={sx.button}
          style={{ opacity: nftsPaginationTab === 1 ? '0.4' : '1' }}
        >
          <img src={left_arrow} style={{ width: '15px' }} />
        </Button>
        <Typography sx={sx.paginationText}>
          {nftsPaginationTab}
          {' / '}
          {selectedmaxPageCount === 0 ? 1 : selectedmaxPageCount}
        </Typography>
        <Button
          sx={sx.button}
          onClick={increaseTab}
          disabled={nftsPaginationTab >= selectedmaxPageCount}
          style={{
            opacity: nftsPaginationTab >= selectedmaxPageCount ? '0.4' : '1',
          }}
        >
          <img src={right_arrow} style={{ width: '15px' }} />
        </Button>
        <Grid container spacing={1.5}>
          {tokenMetas.map((meta, index) => (
            <Grid
              key={index}
              item
              xs={xsGrid}
              sm={smGrid}
              md={mdGrid}
              style={{ marginBottom: '1.5rem' }}
            >
              {tab == 1 &&
                (meta.length === 0 ||
                  meta[nftsPaginationTab - 1] === undefined) && (
                  <PlaceholderCard card={images.common[index]} />
                )}
              {tab == 2 &&
                (meta.length === 0 ||
                  meta[nftsPaginationTab - 1] === undefined) && (
                  <PlaceholderCard card={images.rare[index]} />
                )}
              {tab == 3 &&
                (meta.length === 0 ||
                  meta[nftsPaginationTab - 1] === undefined) && (
                  <PlaceholderCard card={images.epic[index]} />
                )}
              {tab == 4 &&
                (meta.length === 0 ||
                  meta[nftsPaginationTab - 1] === undefined) && (
                  <PlaceholderCard card={images.legendary[index]} />
                )}
              {meta.length > 0 &&
                tab !== 0 &&
                meta[nftsPaginationTab - 1] !== undefined && (
                  <>
                    <WalletCards cardArray={meta} tab={tab} nftsPaginationTab={nftsPaginationTab}/>
                    {/* <ExplorerCard
                  metadata={meta}
                  traitTypes={traitTypes}
                  imageContainerWidth={120}
                  fsHandler={handleClick}
                  dlEnabled={true}
                /> */}
                  </>
                )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
export default CollectionWallet;
