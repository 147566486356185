import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

const sx = {
  root: {
    minHeight: "60px",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    px: 2,
  },
  time: {
    color: "#D2D4C2",
	  fontFamily: "boucherie-block",
    fontSize: "26px",
    lineHeight: "28px",
  },
  title: {
    color: "#D2D4C2",
	  fontFamily: "boucherie-block",
    fontSize: "12px",
    lineHeight: "20px",
    mt: "5px",
  },
};
const Timer = ({ time, title }) => {
  return (
    <Box sx={sx.root}>
      <Typography sx={sx.time}>{time}</Typography>
      <Typography sx={sx.title}>{title}</Typography>
    </Box>
  );
};

Timer.propTypes = {
  time: PropTypes.any.isRequired,
};
export default Timer;
