import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { SpinnerRound } from 'spinners-react'
import dustIcon from '../../../../../assets/images/colored-dust-icon.svg'

const BP1 = '@media (max-width: 520px)';
const BP2 = '@media (max-width: 450px)';
const sx = {
  root: {
    minWidth: '400px',
    border: '2px solid #FC6405',
    overflow: 'hidden',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [BP1]:{
      minWidth: 'auto'
    }
  },
  labelContainer: {
    width: 'auto',
    px: '10px',
  },
  label: {
    fontFamily: 'boucherie-block',
    fontSize: '20px',
    color: '#CACCB5',
    [BP2]:{
      fontSize: '15px'
    }
  },
  spanButton: {
    color: '#FAFAF2',
    fontFamily: 'boucherie-block',
    fontSize: '20px',
    lineHeight: 'normal',
    [BP2]:{
      fontSize: '15px'
    }
  }
}


const DustItem = ({ cardAmount, dustAmount, chainName, buttonLabel, onClick, disable }) => {
  return (
    <Box sx={sx.root}>
      <Box sx={sx.labelContainer}>
        <Typography sx={sx.label}>{`${cardAmount} CARDS (`}<img className='dust-item-icon' src={dustIcon} alt="" /> {`${dustAmount}) ON ${chainName}`}</Typography>
      </Box>
      <span
        className={`dust-span-container ${disable ? "disable" : ""}`}
        onClick={onClick}
      >
        {disable ? <SpinnerRound className='transaction-loading-spinner' color='#FFF'/> :<Typography sx={sx.spanButton}>{buttonLabel}</Typography>}
      </span>
    </Box>
  )
}

export default DustItem