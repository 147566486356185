import { useContext, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import './ExplorerCard.css';
import { LazyImage } from './LazyImage';
import { Box, Button, Typography, Modal } from '@mui/material';

const sx = {
  traitType : {
    fontFamily: 'poppins'
  },
  traitValue: {
    fontFamily: 'poppins-semibold',
  },
  titleHolder: {
    minHeight: '35px',
    marginBottom:'10px'
  },
  title: {
    fontFamily: 'boucherie-block',
    fontSize: '16px',
    color: '#D2D4C2',
  },

}

const ExplorerCardItem = ({ card, showToken, handleClick }) => {
  const [loading, setLoading] = useState(true);
  const CardDetails = () => {
    return (
      <>
      {(card.attributes && card.attributes.length>0)&&<div className="card-details">

        <ul>
          {card.attributes.map((attribute) => {
            return (
              <li style={{ paddingTop: '15px' }} key={attribute.trait_type}>
                <Typography sx={sx.traitType} className="trait-type">{Object.values(attribute)[1]}</Typography>
                <Typography sx={sx.traitValue} className="trait-value">{Object.values(attribute)[0]}</Typography>
              </li>
            );
          })}
        </ul>

      </div>} 
    </>
    );
  };


  return (
    <div className="root">
      <div className="image-container">
        <div style={{ position: 'relative', minHeight: '100px' }}
          onClick={() => handleClick(card)}
        >
          <ClipLoader
            size={150}
            color="rgb(252, 108, 3)"
            loading={loading}
            css={{
              position: 'absolute',
              border: '5px solid rgb(252, 108, 3)',
              borderBottomColor: 'transparent',
              margin: 'auto',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            speedMultiplier={0.5}
          />
          <CardDetails />
          <LazyImage
            src={card.image}
            imageOnLoad={() => setLoading(false)}
            alt="Card"
          />
        </div>
        {!loading && (
          <Box sx={sx.titleHolder} className="card-content" style={{ zIndex: '1' }}>
            <div>
              {card.collection_name?<Typography sx={sx.title}>
                {card.collection_name}
              </Typography>
              :
              <>
              {card.name&&<Typography sx={sx.title} >
                {card.name}
              </Typography>}
              </>
              }
            </div>
            <div>
              {showToken && (
                <Typography sx={sx.title} >{card.tokenId}</Typography>
              )}
            </div>
          </Box>
        )}
      </div>
    </div>
  );
};

export default ExplorerCardItem;
