import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import randomImage from '../../assets/images/ranks/rare.png';
import close from '../../assets/images/white_close_4x.png';
import fullScreen from '../../assets/images/fullscreen-icon.svg';
import FullscreenModal from '../../Components/FullscreenModal';
import Gallery from '../GalleryModal';
import flip_icon from '../../assets/images/flip-icon.svg';

import { slideImages } from '../../View/Home/data';
const sx = {
  closeButton: {
    width: '17.5px',
    height: '17.5px',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 5,
    top: '30px',
    right: '30px',
    webkitBoxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
    mozBoxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
    boxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
  },
  root: {
    height: '100%',
    transform: 'rotateY(180deg)',
    borderRadius: '9px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  galleryButton: {
    bottom: '10px',
    top: 0,
    left: 0,
    mx: 'auto',
    mb: '20px',
  },
  iconsHolder: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: '100%',
    maxHeight: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  centerIconHolder: {
    width: 'auto',
    height: 'auto',
    backgroundColor: 'black',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#FC6405',
    },
  }
};

const CardBack = ({ imageArray, onClick }) => {
  const [showGallery, setshowGallery] = useState(false);
  const [showFlipIcon, setshowFlipIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [fullscreenSrc, setFullscreenSrc] = useState(null);
  const [mobileView, setmobileView] = useState(false);
  const setFullscreenSource = (src) => {
    setFullscreenSrc(src);
    setOpen(true);
  };
  useEffect(() => {
    if (window.innerWidth < 900) {
      setmobileView(true);
    }
  }, []);

  return (
    <>
      <Box
        sx={sx.root}
        style={{ backgroundImage: `url(${imageArray.smallimg})` }}
        onMouseOver={() => {
          setshowFlipIcon(true);
        }}
        onMouseLeave={() => {
          setshowFlipIcon(false);
        }}
      >
        {/* <img
                    src={close}
                    style={sx.closeButton}
                    alt=''
                    onClick={() => onClick()}
                /> */}

        <Box>
          <Box sx={sx.iconsHolder}>
            <Box sx={sx.centerIconHolder}
              style={{ marginRight: '10px' }}
            >
              <img
                src={fullScreen}
                alt=""
                className="fullscreen-icon"
                // style={{ display: showFlipIcon || mobileView ? 'block' : 'none' }}
                onClick={() => {
                  setFullscreenSource(randomImage);
                }}
              />
            </Box>
            <Box sx={sx.centerIconHolder}>
              <img
                src={flip_icon}
                className="flip-icon"
                alt="not found"
                // style={{ display: showFlipIcon || mobileView ? 'block' : 'none' }}
                onClick={() => {
                  onClick();
                }}
              />
            </Box>
          </Box>

          <Button
            sx={sx.galleryButton}
            variant="contained"
            onClick={() => {
              setshowGallery(true);
            }}
          >
            Examples
          </Button>
        </Box>
      </Box>
      <Gallery
        images={slideImages}
        isOpen={showGallery}
        setOpen={setshowGallery}
      />
      <FullscreenModal setOpen={setOpen} isOpen={open} imgSrc={imageArray.orginalArt} />
    </>
  );
};

export default CardBack;
