import { Box, Button } from '@mui/material';
import React from 'react'
import './OrangeSwitch.css'

const OrangeSwitch = ({ isOn, handleToggle, onColor }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box className={`mask-box ${isOn ? "active" : ""}`}>
        <Box
          className="mask"
          style={{
            transform: `translateX(${isOn ? 0 : "25px"})`
          }}
        />
        <Button
          disableRipple
          variant="text"
          sx={{ fontSize: isOn  ? "16px" : "0px", color: isOn && "#FAFAF2" }}
          onClick={() => handleToggle(true)}
        >
          On
        </Button>
        <Button
          disableRipple
          variant="text"
          sx={{ fontSize: !isOn ? "16px" : "0px", color: !isOn && "#152D2E"  }}
          onClick={() => handleToggle(false)}
        >
          Off
        </Button>
      </Box>
    </Box>
  );
}

export default OrangeSwitch