import { Box } from '@mui/system';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import config from '../../config/config';
import TwitterCard from './TwitterCard';
import ItemsCarousel from 'react-items-carousel';
import decoration_v1 from '../../assets/images/decoration_v1.svg';
import Decoration from '../Decoration';

const sx = {
  root: {
    my: '70px',
    display: 'flex',
    gap: '16px',
  },
  hoverStyle: {
    '&:hover': {
      position: 'relative',
      transform: 'translateY(-10px)',
      webkitBoxShadow: '0px 13px 32px 0px rgba(0,0,0,0.24)',
      mozBoxShadow: '0px 13px 32px 0px rgba(0,0,0,0.24)',
      boxShadow: '0px 13px 32px 0px rgba(0,0,0,0.24) !important',
    },
  },
};
const TwitterSection = () => {
  const autoPlayDelay = 5000;
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [noOfCards, setNoOfCards] = useState(3);
  const [sortedTweets, setsortedTweets] = useState([]);
  const [userData, setuserData] = useState(undefined);
  const [error, setError] = useState(undefined);

  const tick = () => {
    let active = activeItemIndex + 1;
    setActiveItemIndex(active);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      tick();
    }, autoPlayDelay);
    return () => clearInterval(interval);
  }, [activeItemIndex]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.innerWidth < 1200) {
      setNoOfCards(3);
    }
    if (window.innerWidth < 970) {
      setNoOfCards(2);
    }
    if (window.innerWidth < 600) {
      setNoOfCards(1);
    }
  }, []);

  useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: `Bearer ${config.TWITTER_BEARER}` },
    };
    axios
      .get(
        `${'https://cors-proba.herokuapp.com/' + config.TWITTER_API
        }?user.fields=profile_image_url`,
        axiosConfig
      )
      .then((response) => {
        setuserData(response.data.data);
      })
      .catch((error) => {
        setError(error);
        console.log(error)
      });
    axios
      .get(
        `${'https://cors-proba.herokuapp.com/' + config.TWITTER_API
        }/tweets?tweet.fields=created_at`,
        axiosConfig
      )
      .then((response) => {
        removeRTElements(response.data.data);
      })
      .catch((error) => {
        setError(error);
        console.log(error)
      });
  }, []);
  const removeRTElements = (tweetsArray) => {
    let tmpArray = [];
    tmpArray = tweetsArray;
    tmpArray.forEach((element, index) => {
      if (element.text.startsWith('RT')) {
        tmpArray = tmpArray.filter(function (item) {
          return item !== element;
        });
      }
    });
    setsortedTweets(tmpArray);
  };
  return error ? (
    <></>
  ) : (
    <>
      <Decoration
        text='FRESH TWEETS'
        img={decoration_v1}
        style={{
          marginBottom: '75px',
          marginTop: '75px',
          maxHeight: '84px',
        }}
      />
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={noOfCards}
        gutter={9}
        infiniteLoop
      >
        {sortedTweets.length > 0 &&
          sortedTweets.map((item,idx) => {
            return <TwitterCard key={idx} tweetData={item} userData={userData} />;
          })}
      </ItemsCarousel>
    </>
  );
};
export default TwitterSection;
