import { Box, Typography } from '@mui/material'
import { borderRadius } from '@mui/system'
import React, { useState } from 'react'
import meeting from '../../../assets/images/traits/meeting-black.svg'

const BP1 = '@media (max-width: 500px)';
const BP2 ='@media (max-width: 400px)';
const sx = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    gap: '20px',
    px: '70px',
    mb: '30px',
    overflowY: 'auto',
    [BP1]: {
      px: '10px'
    }
  },

  header: {
    display: 'flex',
    mt: '45px'
  },
  imageHolder: {
    width: '44px',
    height: '44px',
    border: '2px solid #030000',
    p: '10px',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    maxWidth: '416px'
  },
  title: {
    fontFamily: 'Boucherie-block',
    fontSize: '38px',
    textAlign: 'center',
    lineHeight: '40px',
    color: '#FC6405',
    mb: '20px'
  },
  description: {
    fontFamily: 'poppins',
    fontSize: '16px',
    textAlign: 'center',
  },
  bookHolder: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '10px',
    BP2:{
      justifyContent: 'row',
    }
  },
  date: {
    maxWidth: '190px',
    minWidth: '190px',
    maxHeight: '51px',
    minHeight: '51px',
    border: '1px solid #050000',
    borderRadius: '6px',
    transition: '0.5s',
    [BP1]: {
      maxWidth: '160px',
      minWidth: '160px',
      maxHeight: '51px',
      minHeight: '51px',
    },
    
  },
  dateText: {
    fontFamily: 'poppins',
    fontSize: '12px',
    py: '17px',
    pl: '23px',
    [BP1]: {
      py: '17px',
      pl: '5px',
    }
  }
}
const availableDates = [
  {
    date: 'Wed, May 11 2022 1:00pm',
    available: true,
  },
  {
    date: 'Wed, May 11 2022 1:30pm',
    available: false,
  },
  {
    date: 'Wed, May 11 2022 2:00pm',
    available: true,
  },
  {
    date: 'Wed, May 11 2022 2:30pm',
    available: true,
  },
  {
    date: 'Wed, May 11 2022 3:00pm',
    available: true,
  },
  {
    date: 'Thu, May 12 2022 1:30pm',
    available: true,
  }, {
    date: 'Thu, May 12 2022 2:00pm',
    available: true,
  }, {
    date: 'Thu, May 12 2022 2:30pm',
    available: true,
  },
]
const MeetingClaim = ({ trait, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  return (
    <Box sx={sx.root}>
      <Box sx={sx.header}>
        <Box sx={sx.imageHolder}><img src={meeting} alt="" height={'100%'} width={'100%'} /></Box>
      </Box>
      <Box sx={sx.content}>
        <Typography sx={sx.title}>Online Meeting</Typography>
        <Typography sx={sx.description}>The 1:1 online Meeting Trait is very intuitive - select an available time slot to chat with the NFT creator, and press the “Book” button to complete the process.</Typography>
      </Box>
      <Box sx={sx.bookHolder}>
        {availableDates.map((item, index) => {
          if (item.available)
            return (
              <Box sx={sx.date} 
              key={index}
              style={{
                // marginLeft: index % 2 === 1 && window.innerWidth >= 362 ? '16px' : '0px',
                backgroundColor: selectedDate === index && '#FF692B',
                border: selectedDate === index && '1px solid #FF692B',
                cursor: item.available && 'pointer'
              }}
                onClick={() => {
                  if (item.available)
                    setSelectedDate(index);
                }}
              >
                <Typography sx={sx.dateText}
                style={{fontFamily: selectedDate === index && 'poppins-semibold',
                color: selectedDate === index && '#FCF7F7'
              }}
                >{item.date}</Typography>
              </Box>
            )
          else return (
            <Box sx={sx.date}
              style={{
                opacity: '0.5',
                // marginLeft: index % 2 === 1 && window.innerWidth >= 362 ? '16px' : '0px',
              }}

            >
              <Typography sx={sx.dateText}>{item.date}</Typography>
            </Box>
          )
        })
        }
      </Box>
    </Box>
  )
}

export default MeetingClaim