import { Input, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import physical from '../../../assets/images/traits/physical-black.svg'
import './ModalChildren.css';
const BP1 = '@media (max-width: 500px)';
const sx = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    gap: '20px',
    px: '70px',
    mb: '30px',
    [BP1]: {
      px: '10px'
  }
  },

  header: {
    display: 'flex',
    mt: '45px'
  },
  imageHolder: {
    width: '44px',
    height: '44px',
    border: '2px solid #030000',
    p: '10px',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    maxWidth: '416px'
  },
  title: {
    fontFamily: 'Boucherie-block',
    fontSize: '38px',
    textAlign: 'center',
    lineHeight: '40px',
    color: '#FC6405',
    mb: '20px'
  },
  description: {
    fontFamily: 'poppins',
    fontSize: '16px',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  city_zip: {
    display: 'flex',
    gap: '16px'
  }
}

const PhysicalCalim = ({ trait }) => {
  return (
    <Box sx={sx.root}>
      <Box sx={sx.header}>
        <Box sx={sx.imageHolder}><img src={physical} alt="" height={'100%'} /></Box>
      </Box>
      <Box>
        <Typography sx={sx.title}>Physical Redeem</Typography>
        <Typography sx={sx.description}>Please fill out the input fields and click submit to initiate the transaction. This data is not saved anywhere - the form is used for demo purposes only.</Typography>
      </Box>
      <Box sx={sx.form}>
        <input
          placeholder="FULL NAME"
          id='physical-input'
        ></input>
        <input
          placeholder="EMAIL"
          id='physical-input'
        ></input>
        <input
          placeholder="ADDRESS"
          id='physical-input'
        ></input>
        <Box sx={sx.city_zip}>
          <input
            placeholder="CITY"
            id='physical-input-small'
          ></input>
          <input
            placeholder="ZIP CODE"
            id='physical-input-small'
          ></input>
        </Box>
        <input
          placeholder="COUNTRY"
          id='physical-input'
        ></input>
      </Box>
    </Box>
  )
}

export default PhysicalCalim