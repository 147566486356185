import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import ExplorerCardItem from './ExplorerCardItem';
import RenderModal from './RenderModal';

const BP3 = '@media (min-width: 1145px)';
const BP1 = '@media (max-width: 999px)';

const sx = {
  root: { width: '100%', display: 'flex', justifyContent: 'center' },
  container: {
    p: '10px',
    height: '100%',
    width: '100%',
    [BP3]: {
      maxWidth: '1175px',
    },
  },
};

const CardList = ({ metadata, showToken }) => {
  const [open, setOpen] = useState(false);
  const [selectedMetadata, setselectedMetadata] = useState(undefined);

  const handleClick = (card) => {
    setselectedMetadata(card);
    setOpen(true);
  };
  return (
    <>
      <Box sx={sx.root}>
        <Grid container sx={sx.container} spacing={2} justifyContent="center">
          {metadata.length > 0 &&
            metadata.map((elem) => {
              return (
                <Grid item sx={sx.cardItem} sm={6} md={3}>
                  <ExplorerCardItem
                    card={elem}
                    showToken={showToken}
                    handleClick={handleClick}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Box>
      <RenderModal
        setOpen={setOpen}
        isOpen={open}
        metadata={selectedMetadata}
        imageContainerWidth={600}
      />
    </>
  );
};

export default CardList;
