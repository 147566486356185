import { createTheme } from '@mui/material';
const BP1 = '@media (max-width: 600px)';
const COLORS = {
  primaryColor: '#FF692B',
  blue2: '#00A5F7',
  blue3: '#7FC4FD',
  blue4: '#BCE0FD',
  grey: '#F1F9FF',
  light: '#FFF',
};

const theme = createTheme({
  typography: {},
  palette: {
    primary: {
      main: COLORS.primaryColor,
    },
    background: {
      socialIcon: COLORS.blue2,
      grey: COLORS.grey,
      infoBox: COLORS.blue3,
      image: COLORS.blue4,
      light: COLORS.light,
    },
    light: {
      main: '#FFF',
    },
    text: {
      primary: '#FFF',
    },
    error: {
      main: '#EE1B11',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontFamily: 'poppins',
          fontSize: '18px',
        },
      },
      defaultProps: {
        variantMapping: {
          body1: 'div',
          bannerTitle: 'div',
          bannerText: 'div',
          heading1: 'div',
          heading2: 'div',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          display: 'flex',
          fontSize: '20px',
          color: '#FAFAF2',
          fontFamily: 'boucherie-block',
          border: 'solid 2px #FF692B',
          backgroundColor: '#FF692B',
          padding: '6px 16px',
          lineHeight: 'inherit',
          borderRadius: '7px',
          margin: '0 auto',
          '&:hover': {
            backgroundColor: '#FCFCF9',
            border: 'solid 2px #FCFCF9',
            color: '#FF692B',
          },
        },
      },
    },
  },
});

export default theme;
