import { Box, Typography } from '@mui/material';
import React from 'react'
import dustIcon from '../../../assets/images/colored-dust-icon.svg'

const BP1 = '@media (max-width: 600px)';
const sx = {
  root: {
    display: 'flex',
    width: 'fit-content',
    maxWidth: '120px',
    flexDirection: 'column',
    alignItems: 'center',
    border: 'solid 2px #D2D4C2',
    borderRadius: '12px',
    margin: 'auto',
    p: '12px',
    gap: '5px',
    [BP1]: {
      marginBottom: '30px'
    }
  },
  dustContent: {
    display: 'flex',
    gap: '5px',
    alignItems: 'baseline',
    '& img': {
      height: '17px',
    },
    '& p ': {
      fontSize: '22px',
      textTransform: 'uppercase',
      fontFamily: 'boucherie-block',
      color: '#D2D4C2',
      m: 0,
    }
  },
  content: {
    fontSize: '16px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    fontFamily: 'boucherie-block',
    width: '90%',
    color: '#D2D4C2',
  },
   claimButton : {
    my: '30px'
   }
}

const DustOnEther = ({amount, text}) => {
  return (
    <Box sx={sx.root}>
        <Box sx={sx.dustContent}>
          <img src={dustIcon} alt="" />
          <p>{parseFloat(amount).toFixed(2)}</p>
        </Box>
        <Typography sx={sx.content}>
         {text}
        </Typography>
      </Box>
  )
}

export default DustOnEther