import React, { useState } from 'react'

const FilterCheckbox = ({filterChanged}) => {
    const cheched = () => {
        var checkBox = document.getElementById('checkbox');
        if (checkBox.checked == true) {
            filterChanged('MintedOnly', true)
        } else
            filterChanged('MintedOnly', false)
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
            <label>Minted only</label>
            <input type="checkbox" name="acceptRules"
                className="checkbox"
                id="checkbox"
                onClick={() => {
                    cheched();
                }
                }
            />
        </div>
    )
}

export default FilterCheckbox