import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { getFilteredCards, getFilters, getTraitTypes } from '../../Api';
import Header from '../../Components/Header/Header';
import CardList from './Components/CardList';
import { removeFilter } from './Components/ExplorerHelpers';
import ExplorerScroller from './Components/ExplorerScroller';
import FilterHeader from './Components/FilterHeader';
// import metadataJson from './metadata.json';

export const Explorer = () => {
  const [metadata, setMetadata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [traits, setTraits] = useState(undefined);
  const [filters, setFilters] = useState({});
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [activeFilters, setActiveFilters] = useState(null);
  const [filtering, setFiltering] = useState(false);
  const [projectName, setProjectName] = useState('Project');
  const [showTokenId, setShowTokenId] = useState(false);

  useEffect(() => {
    (async () => {
      getTraitTypes().then((res) => {
        setTraits(res);
      });
      getFilters().then((res) => {
        setFilters(res);
      });
    })();
  }, []);

  const filterChanged = (keyname, value) => {
    let updatedValue = {};
    updatedValue[keyname] = value;

    setActiveFilters((activeFilters) => ({
      ...activeFilters,
      ...updatedValue,
    }));
  };

  const showToken = (state) => {
    setShowTokenId(state);
  };

  useEffect(() => {
    /// remove the "All" filter before the api call
    let filters = activeFilters ? removeFilter(activeFilters) : {};
    setMetadata([]);

    (async () => {
      setFiltering(true);
      getFilteredCards(filters)
        .then((response) => {
          if (response.length > 0) {
            setFiltering(false);
            setMetadata(response);
            setProjectName(response[0].collection_name);
            setDataLoaded(true);
          } else {
            setFiltering(false);
            setDataLoaded(true);
          }
        })
        .catch((e) => {
          setFiltering(false);
        });
    })();
  }, [activeFilters]);
  console.log(metadata);
  return (
    <>
      <FilterHeader
        filters={filters}
        filterChanged={filterChanged}
        projectName={projectName}
        showToken={showToken}
      />
      <ClipLoader
        size={150}
        color="rgb(252, 108, 3)"
        loading={!isDataLoaded}
        css={{
          position: 'absolute',
          border: '5px solid rgb(252, 108, 3)',
          borderBottomColor: 'transparent',
          margin: 'auto',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        speedMultiplier={0.5}
      />
      <Box>
        {isDataLoaded && (
          <ExplorerScroller
            nfts={metadata}
            showToken={showTokenId}
            filtersAreActive={activeFilters}
          />
        )}
      </Box>
    </>
  );
};
