import React, { useState, useEffect } from 'react';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderButton from './HeaderButton';
import collapsedIcon from '../../assets/images/collapse_icon.svg';
import menuIcon from '../../assets/images/menu_icon.svg';
import discord from '../../assets/images/social/discord_white.svg';
import twitter from '../../assets/images/social/twitter_white.svg';
import calendar from '../../assets/images/social/nftcalendar_white.png';
import discordo from '../../assets/images/social/discord_orange.svg';
import twittero from '../../assets/images/social/twitter_orange.svg';
import calendaro from '../../assets/images/social/nftcalendar_orange.png';
import osIcono from '../../assets/images/social/openseaW.svg';
import osIcon from '../../assets/images/social/opensea_orange.svg';
import galaxisIcono from '../../assets/images/social/galaxis_swirl_only_white.svg';
import galaxisIcon from '../../assets/images/social/galaxis_swirl_only_orange.svg';

import '../../View/Home/Home.css';



const BP1 = '@media (max-width: 999px)';
const BP2 = '@media (max-width: 401px)';
const BP3 = '@media (max-width: 1150px)';
const BP4 = '@media (max-width: 1190px)';

const sx = {
  root: {
    transition: '1s ease-out',
    position: 'fixed',
    width: '100%',
    height: '120px',
    zIndex: 11,
    maxWidth: '1280px',
    [BP1]: {
      display: 'none',
    },
  },
  content: {
    width: '100%',
    maxWidth: '1140px',
    height: '100%',
    mx: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    width: '100%',
    maxWidth: '90px',
    cursor: 'pointer',
    [BP2]: {
      maxWidth: '130px',
    },
  },
  text: {
    '&:disabled': {
      color: '#FF692B',
    },
  },
  btnContainer: {
    minHeight: '55px',
    mt: '25px',
    display: 'flex',
    flexDirection: 'row',
  },
  btn: {
    fontFamily: 'poppins',
    fontSize: '20px',
    color: '#FFF',
  },
  mobileContainer: {
    display: 'none',
    [BP1]: {
      display: 'block',
      gap: '30px',
    },
  },
  headerRoot: {
    transition: '1s ease-out',
    position: 'absolute',
    width: '100%',
    zIndex: '11',
    maxWidth: '1272px',
    height: '100px',
    mx: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [BP1]: {
      position: 'fixed',
    },
  },
  collapsedMenu: {
    position: 'fixed',
    right: '0',
    top: '100px',
    zIndex: '2222',
    width: '240px',
    pt: '10px',
    pl: '20px',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: '25px',
    backgroundColor: '#152d2ebf',
    borderRadius: '0px 0px 15px 15px',
  },
  menuContainer: {
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '9px 0',
    zIndex: '1',
  },
  presaleButton: {
    fontSize: '14px',
    py: '10px',
    pt: '13px',
    height: '40px',
    lineHeight: 1,
  },
  presaleText: {
    fontFamily: 'Poppins',
    fontSize: '10px',
    mt: '5px',
    color: 'white',
    textAlign:'center'
  },
  registrationButtonHolder: {
    [BP3]: {
      pr: '24px',
    },
  },
  grdLogo: {
    [BP4]: {
      pl: '24px',
    },
  },
};
const BUTTONS = [
  'COLLECTION',
  'PRE-SALE/SALE',
  'BENEFITS',
  'GAME',
  'REDEEMABLES',
  'DUST PROOFING',
  'DEMO NFT',
];
const PATHS = [
  '/#collection',
  '/#pre-sale',
  '/#benefits',
  '/#game',
  '/#redeemables',
  '/#dust',
  '/#demo-nft',
];

const Header = ({ clickedToTheSameLink, background, address, handleDisconnect }) => {
  const location = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const [showMenuBgr, setShowMunuBgr] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [orangeTwitterIcon, setOrangeTwitterIcon] = useState(false);
  const [orangeDiscordIcon, setOrangeDiscordIcon] = useState(false);
  const [orangeCalendarIcon, setOrangeCalendarIcon] = useState(false);
  const [orangeOpenseaIcon, setOrangeOpenseaIcon] = useState(false);
  const [orangeGalaxisIcon, setOrangeGalaxisIcon] = useState(false);

  useEffect(() => {
    // console.log('header+++++++++',handleDisconnect,address);
    window.scrollTo(0, 0);
    if (window.innerWidth < 600) {
      setMobileView(true);
    }
  }, []);

  const handleNavigation = (path) => {
    setActiveTab(path);

    if (PATHS[path] === '/' + location.hash) {
      clickedToTheSameLink();
    }
    if (location.pathname !== path) {
      history.push(path);
      history.push(PATHS[path]);
    }
  };

  const handleNavigationLogo = (path) => {
    setActiveTab(null);
    window.location.hash = '';

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });

    if (location.pathname !== path) {
      history.push(path);
      history.push(PATHS[path]);
    }
  };

  const handleMouseOver = (path) => {
    setActiveTab(path);
  };
  const handleMouseLeave = () => {
    if (location.hash == '#collection') {
      setActiveTab(0);
    } else if (location.hash == '#pre-sale') {
      setActiveTab(1);
    } else if (location.hash == '#benefits') {
      setActiveTab(2);
    } else if (location.hash == '#game') {
      setActiveTab(3);
    } else if (location.hash == '#redeemables') {
      setActiveTab(4);
    } else if (location.hash == '#dust') {
      setActiveTab(5);
    } else if (location.hash == '#demo-nft') {
      setActiveTab(6);
    } else {
      setActiveTab(null);
    }
  };
  window.addEventListener('scroll', (event) => {
    if (getYPosition() > 500 && !mobileView) {
      setShowMunuBgr(true);
    } else if (getYPosition() > 250 && mobileView) {
      setShowMunuBgr(true);
    } else {
      setShowMunuBgr(false);
    }
  });
  function getYPosition() {
    var top = window.pageYOffset || document.documentElement.scrollTop;
    return top;
  }

  const getShortAddress = ()=>{
    let addr = "";
    if(address){
     addr = address.substr(0,6)+'...'+address.substr(address.length-4,4)
    }
    return addr;
  }

  return (
    <>
      <Box
        sx={sx.root}
        style={{
          backgroundColor:
            showMenuBgr || background ? '#152d2ebf' : 'transparent',
        }}
      >
        <Box sx={sx.content}>
          <Box sx={sx.logoContainer} onClick={() => handleNavigationLogo('/')}>
            <Typography
              style={{
                color: '#CACCB5',
                fontSize: '40px',
                fontFamily: 'boucherie-block',
              }}
              sx={sx.grdLogo}
            >
              GRD
            </Typography>
          </Box>
          <Box sx={sx.btnContainer} style={{}}>
            {BUTTONS.map((btn, i) => {
              return (
                <HeaderButton
                  key={btn}
                  active={activeTab === i}
                  text={btn}
                  onClick={() => handleNavigation(i)}
                  onMouseOver={() => handleMouseOver(i)}
                  onMouseLeave={handleMouseLeave}
                />
              );
            })}
            <Box sx={sx.social} style={{ marginRight: '20px' }}>
              <img
                src={orangeDiscordIcon ? discordo : discord}
                alt="unset"
                style={{ marginRight: '10px', paddingTop: '4px' }}
                className="header-social-icons"
                onMouseOver={() => setOrangeDiscordIcon(true)}
                onMouseLeave={() => setOrangeDiscordIcon(false)}
                onClick={() => {
                  window.open('https://discord.gg/YAHJghT5ZK', '_blank');
                }}
              />
              <img
                onMouseOver={() => setOrangeTwitterIcon(true)}
                onMouseLeave={() => setOrangeTwitterIcon(false)}
                src={orangeTwitterIcon ? twittero : twitter}
                style={{ marginRight: '10px' }}
                alt="unset"
                className="header-social-icons"
                onClick={() => {
                  window.open('https://twitter.com/GRD_Fans', '_blank');
                }}
              />
              <img
                src={orangeCalendarIcon ? calendaro : calendar}
                alt="unset"
                style={{transform:'translateY(2px)'}}
                onMouseOver={() => setOrangeCalendarIcon(true)}
                onMouseLeave={() => setOrangeCalendarIcon(false)}
                className="header-social-icons"
                onClick={() => {
                  window.open(
                    'https://nftcalendar.io/event/girls-robots-dragons',
                    '_blank'
                  );
                }}
              />
              <img
                src={orangeOpenseaIcon ? osIcon : osIcono}
                alt="unset"
                style={{marginLeft:'10px'}}
                onMouseOver={() => setOrangeOpenseaIcon(true)}
                onMouseLeave={() => setOrangeOpenseaIcon(false)}
                className="header-social-icons"
                onClick={() => {
                  window.open(
                    'https://opensea.io/collection/girls-robots-dragons-official',
                    '_blank'
                  );
                }}
              />
              <img
                src={orangeGalaxisIcon ? galaxisIcon : galaxisIcono}
                alt="unset"
                style={{marginLeft:'10px'}}
                onMouseOver={() => setOrangeGalaxisIcon(true)}
                onMouseLeave={() => setOrangeGalaxisIcon(false)}
                className="header-social-icons"
                onClick={() => {
                  window.open(
                    'https://galaxis.xyz',
                    '_blank'
                  );
                }}
              />
            </Box>
            {address&&<Box sx={sx.registrationButtonHolder}>
              <Button
                sx={sx.presaleButton}
                variant="contained"
                onClick={handleDisconnect}
              >
                DISCONNECT
              </Button>
              <Typography sx={sx.presaleText}>
                {getShortAddress()}
              </Typography>
            </Box>}
          </Box>
        </Box>
      </Box>
      <Box sx={sx.mobileContainer}>
        <Box
          sx={sx.headerRoot}
          style={{
            backgroundColor:
              showMenuBgr || background ? '#152d2ebf' : 'transparent',
          }}
        >
          <Typography
            onClick={() => handleNavigationLogo('/')}
            style={{
              color: '#CACCB5',
              fontSize: '40px',
              fontFamily: 'boucherie-block',
              paddingLeft: '24px',
              cursor: 'pointer',
            }}
          >
            GRD
          </Typography>
          <Box
            sx={sx.menuContainer}
            onClick={() => setMenuOpened((state) => !state)}
          >
            <Button sx={sx.menubtn}>
              <img
                style={{ width: '100%', paddingRight: '10px' }}
                src={menuOpened ? collapsedIcon : menuIcon}
                alt="Menu icon"
              />
            </Button>
          </Box>
        </Box>
        <Collapse in={menuOpened} timeout="auto" unmountOnExit>
          <Box sx={sx.collapsedMenu}>
            {BUTTONS.map((btn, i) => {
              return (
                <HeaderButton
                  key={btn}
                  active={activeTab === i}
                  text={btn}
                  onClick={() => {
                    handleNavigation(i);
                    setMenuOpened(false);
                  }}
                />
              );
            })}
            <Box sx={sx.social} style={{ paddingRight: '25px' }}>
              <img
                src={orangeDiscordIcon ? discordo : discord}
                alt="unset"
                style={{
                  marginRight: '10px',
                  paddingTop: '4px',
                  marginTop: '10px',
                }}
                onMouseOver={() => setOrangeDiscordIcon(true)}
                onMouseLeave={() => setOrangeDiscordIcon(false)}
                className="header-social-icons"
                onClick={() => {
                  window.open('https://discord.gg/YAHJghT5ZK', '_blank');
                }}
              />
              <img
                src={orangeTwitterIcon ? twittero : twitter}
                alt="unset"
                onMouseOver={() => setOrangeTwitterIcon(true)}
                onMouseLeave={() => setOrangeTwitterIcon(false)}
                style={{ marginRight: '10px' }}
                className="header-social-icons"
                onClick={() => {
                  window.open('https://twitter.com/GRD_Fans', '_blank');
                }}
              />
              <img
                src={orangeCalendarIcon ? calendaro : calendar}
                alt="unset"
                style={{transform:'translateY(2px)'}}
                onMouseOver={() => setOrangeCalendarIcon(true)}
                onMouseLeave={() => setOrangeCalendarIcon(false)}
                className="header-social-icons"
                onClick={() => {
                  window.open(
                    'https://nftcalendar.io/event/girls-robots-dragons',
                    '_blank'
                  );
                }}
              />
              <img
                src={orangeOpenseaIcon ? osIcon : osIcono}
                alt="unset"
                style={{marginLeft:'10px'}}
                onMouseOver={() => setOrangeOpenseaIcon(true)}
                onMouseLeave={() => setOrangeOpenseaIcon(false)}
                className="header-social-icons"
                onClick={() => {
                  window.open(
                    'https://opensea.io/collection/girls-robots-dragons-official',
                    '_blank'
                  );
                }}
              />

              <img
                src={orangeGalaxisIcon ? galaxisIcon : galaxisIcono}
                alt="unset"
                style={{marginLeft:'10px'}}
                onMouseOver={() => setOrangeGalaxisIcon(true)}
                onMouseLeave={() => setOrangeGalaxisIcon(false)}
                className="header-social-icons"
                onClick={() => {
                  window.open(
                    'https://galaxis.xyz',
                    '_blank'
                  );
                }}
              />
            </Box>
            
            {address!==undefined&& <Box style={{ paddingRight: '25px', marginTop: '10px' }}>
              <Button
                style={{ fontSize: '18px' }}
                variant="contained"
                onClick={handleDisconnect}
              >
                DISCONNECT
              </Button>
              <Typography sx={sx.presaleText} align="center">
                {getShortAddress()}
              </Typography>
            </Box>}

          </Box>
        </Collapse>
      </Box>
    </>
  );
};

export default Header;
