import React, { useEffect, useState } from "react";
import "./Counter.css";
import leftArrow from '../../assets/images/counter-left-arrow.svg'
import rightArrow from '../../assets/images/counter-right-arrow.svg'
const MIN = 60000;
const HOUR = MIN * 60;
const DAY = HOUR * 24;

const INIT = { d: 0, h: 0, m: 0, s: 0 };

const Counter = ({ date, onFinish, id, isActive, blockTime}) => {
  const [values, setValues] = useState(INIT);


  useEffect(() => {
 /*    const dayHand = document.getElementById(id ? `${id}_days` : "days");
    const hourHand = document.getElementById(id ? `${id}_hours` : 'hours');
    const minuteHand = document.getElementById(id ? `${id}_mins` : 'mins');
    const secondHand = document.getElementById(id ? `${id}_sec` : 'sec'); */


    const interval = setInterval(() => {
      if (date != null) {
        let dif = date.getTime() - new Date().getTime();
        if (dif > 0) {
          setValues({
            d: Math.floor(dif / DAY),
            h: Math.floor((dif % DAY) / HOUR),
            m: Math.floor((dif % HOUR) / MIN),
            s: Math.floor((dif % MIN) / 1000),
          });
        } else {
          setValues(INIT);
          clearInterval(interval);
          if (onFinish) {
            onFinish()
          }
        }
      } else {
        setValues(INIT);
        clearInterval(interval);
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [date]);

  return (
    <div className={`counter-container pt-4 ${!isActive?'disabled':''}`}>
      <img src={leftArrow} alt='' className="counter-left-arrow"/>
      <CounterCircle name="DAYS" value={values.d} />
      <CounterCircle name="HOURS" value={values.h} />
      <CounterCircle name="MINS" value={values.m} />
      <CounterCircle name="SEC" value={values.s} />
      <img src={rightArrow} alt='' className="counter-right-arrow"/>
    </div>
  );
};

const CounterCircle = ({ name, value }) => (
  
  <div className="counter-circle">
    <div className="counter-text">{name}</div>
    <div className="counter-text-value">{value}</div>
  </div>
);

export default Counter;
