import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import perfectBadge from '../../../assets/images/perfect-badge.png';
import right_arrow from '../../../assets/images/right_arrow.svg';
import left_arrow from '../../../assets/images/left_arrow.svg';
import orangeBadge from '../../../assets/images/orange_badge.svg';
import './CollectionWallet.css';
import InformationModal from './Modals/InformationModal';
import LevelsModal from './Modals/LevelsModal';
const sx = {
  root: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontFamily: 'boucherie-block',
    fontSize: '16px',
    color: '#D2D4C2',
  },
  imgHolder: {
    position: 'relative',
  },
  holder: {
    position: 'absolute',
    height: '10px',
    width: '100%',
    zIndex: '0',

    margin: 'auto',
    mt: '-12px',
  },
  cardHolder: {
    width: '94%',
    margin: 'auto',
    height: '12px',
    backgroundColor: '#FF692B',
    borderRadius: '25px 25px 0px 0px',
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: '10px',
  },
  button: {
    minWidth: '18px',
    cursor: 'pointer',
  },
};

const CardsSwitch = ({ dataArray, tab , nftsPaginationTab}) => {
  const [data, setdata] = useState(dataArray);
  const [activeItem, setactiveItem] = useState(0);
  const [open, setOpen] = useState(false);
  const [openLevels, setOpenLevels] = useState(false);

  const increase = () => {
    setactiveItem(activeItem + 1);
  };
  const decrease = () => {
    setactiveItem(activeItem - 1);
  };

  const handleClick = () => {
    setOpen(true);
  };
  const levelClick = () => {
    setOpenLevels(true);
  };
  useEffect(() => {
    setactiveItem(nftsPaginationTab - 1);
    setdata(dataArray);
  }, [tab, nftsPaginationTab]);
  return (
    <>
      <Box sx={sx.root}>
        <Box sx={sx.imgHolder}>
          <img
            src={data[activeItem].image}
            alt=""
            width={'100%'}
            style={{ borderRadius: '10px' }}
          />
          {data[activeItem].perfect && (
            <img src={perfectBadge} alt="" className="wallet-badge-image" />
          )}
          <Box className="orange-badge-image">
            <Box
              style={{ position: 'relative', cursor: 'pointer' }}
              onClick={handleClick}
            >
              <img src={orangeBadge} alt="" />
              <Typography
                className="orange-badge-number"
                style={{
                  fontFamily: 'boucherie-block',
                  fontSize: '18px',
                }}
              >
                {data.length}
              </Typography>
            </Box>
            {tab !== 3 && data[activeItem].perfect && !data[activeItem].rare && (
              <Box
                onClick={levelClick}
                style={{ cursor: 'pointer', position: 'relative' }}
              >
                <img src={orangeBadge} alt="" />
                <Typography
                  className="orange-badge-number"
                  style={{ fontFamily: 'boucherie-block', fontSize: '22px' }}
                >
                  E
                </Typography>
              </Box>
            )}
            {tab !== 2 && data[activeItem].rare && !data[activeItem].perfect && (
              <Box
                onClick={levelClick}
                style={{ cursor: 'pointer', position: 'relative' }}
              >
                <img src={orangeBadge} alt="" />
                <Typography
                  className="orange-badge-number"
                  style={{ fontFamily: 'boucherie-block', fontSize: '22px' }}
                >
                  R
                </Typography>
              </Box>
            )}
            {tab !== 4 && data[activeItem].rare && data[activeItem].perfect && (
              <Box
                onClick={levelClick}
                style={{ cursor: 'pointer', position: 'relative' }}
              >
                <img src={orangeBadge} alt="" />
                <Typography
                  className="orange-badge-number"
                  style={{ fontFamily: 'boucherie-block', fontSize: '22px' }}
                >
                  L
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={sx.cardName}>
          <Button
            onClick={decrease}
            style={{
              padding: 'unset',
              opacity: activeItem === 0 ? '0.4' : '1',
            }}
            sx={sx.button}
            disabled={activeItem === 0}
          >
            <img src={left_arrow} style={{ width: '15px' }} />
          </Button>
          <Typography sx={sx.title}>
            {data[activeItem].collection_name
              ? data[activeItem].collection_name
              : 'Girls Robots Dragons'}{' '}
            #{data[activeItem].id}
          </Typography>{' '}
          <Button
            style={{
              padding: 'unset',
              opacity: activeItem === data.length - 1 ? '0.4' : '1',
            }}
            onClick={increase}
            sx={sx.button}
            disabled={activeItem === data.length - 1}
          >
            {' '}
            <img src={right_arrow} style={{ width: '15px' }} />
          </Button>
        </Box>
        <InformationModal setOpen={setOpen} isOpen={open} />
        <LevelsModal setOpen={setOpenLevels} isOpen={openLevels} />
      </Box>
    </>
  );
};

export default CardsSwitch;
