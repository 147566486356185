import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Grid, Modal } from '@mui/material';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import bodyicon from '../../assets/images/bodyicon.svg';
import grdlogo from '../../assets/images/logo.png';
import backgroundIcon from '../../assets/images/backgroundIcon.png';

import galaxisLogo from '../../assets/images/galaxis-logo.svg';

import Header from '../../Components/Header/Header';

import Footer from '../../Components/Footer';

import { LazyLoadImage } from 'react-lazy-load-image-component';

// import HomeBanner from './HomeBanner';

const BP1 = '@media (max-width: 580px)';
const BP4 = '@media (max-width: 600px)';
const BP5 = '@media (max-width: 410px)';

const sx = {
  root: {},
  body: {
    pt: '18px',
    pb: '18px',
    mt: '-10px',
    backgroundColor: '#152D2E',
  },
  content: {
    margin: 'auto',
    px: '20px',
    pb: '95px',
  },
  backgroundIcon: {
    position: 'absolute',
    margin: 'auto',
    width: '75%',
    marginTop: '100px',
  },
  line: {
    background: 'linear-gradient(0.25turn, #393A2A, #D1D3C1)',
    width: '100%',
  },
  bodyicon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    transform: 'rotate(180deg)',
    mb: '40px',
  },
  descriptionFirst: {
    margin: 'auto',
    maxWidth: '900px',
    fontFamily: 'poppins',
    fontSize: '14px',
    color: '#D2D4C2',
    textAlign: 'center',
    lineHeight: '1.8',
    mt: '0px',
    position: 'relative',
    zIndex: '2',
  },
  description: {
    margin: 'auto',
    maxWidth: '900px',
    fontFamily: 'poppins',
    fontSize: '16px',
    color: '#D2D4C2',
    lineHeight: '1.8',
    mb: '16px',
    // wordBreak: 'break-all',
    // overflowWrap: 'break-word',
  },
  title: {
    fontFamily: 'boucherie-block',
    fontSize: '40px',
    textAlign: 'center',
    lineHeight: '45px',
    cursor: 'pointer',
    color: '#D1D3C1',
    mb: '8px',
  },
  date: {
    color: '#FF692B',
    fontFamily: 'boucherie-block',
    fontSize: '46px',
    mt: '50px',
    mb: '50px',
  },
};
const PrivacyPolicy = () => {
  const location = useLocation();
  const history = useHistory();
  const dropRef = useRef(null);
  const booksRef = useRef(null);
  const poolsRef = useRef(null);
  const editionsRef = useRef(null);
  const doubleRef = useRef(null);
  const charactersRef = useRef(null);
  const utilitiesRef = useRef(null);

  const scrollToRef = (ref) => {
    window.scrollTo({
      left: 0,
      top: ref.current.offsetTop - 200,
      behavior: 'smooth',
    });
  };

  const clickedToTheSameLink = () => {
    if (location.hash === '#drop') {
      scrollToRef(dropRef);
    } else if (location.hash === '#utilities') {
      scrollToRef(utilitiesRef);
    } else if (location.hash === '/#') {
      window.scrollTo(0, 0);
    } else if (location.hash === '#editions') {
      scrollToRef(editionsRef);
    } else if (location.hash === '#books') {
      scrollToRef(booksRef);
    } else if (location.hash === '#double-sided') {
      scrollToRef(doubleRef);
    } else if (location.hash === '#pools') {
      scrollToRef(poolsRef);
    } else if (location.hash === '#characters') {
      scrollToRef(charactersRef);
    }
  };
  useEffect(() => {
    if (location.hash === '#drop') {
      scrollToRef(dropRef);
    } else if (location.hash === '#utilities') {
      scrollToRef(utilitiesRef);
    } else if (location.hash === '/#') {
      window.scrollTo(0, 0);
    } else if (location.hash === '#editions') {
      scrollToRef(editionsRef);
    } else if (location.hash === '#books') {
      scrollToRef(booksRef);
    } else if (location.hash === '#double-sided') {
      scrollToRef(doubleRef);
    } else if (location.hash === '#pools') {
      scrollToRef(poolsRef);
    } else if (location.hash === '#characters') {
      scrollToRef(charactersRef);
    }
  }, [location.hash]);

  return (
    <>
      <Box sx={sx.root}>
        <Header clickedToTheSameLink={clickedToTheSameLink} />
        {/* <Box sx={sx.banner}>
          <LazyLoadImage src={banner} width="100%" alt="banner" />
          <LazyLoadImage
            src={women}
            style={{
              position: 'absolute',
              zIndex: 1,
              paddingLeft: '20.5%',
              left: 0,
              width: '35%',
              marginTop: '0',
            }}
            alt="women"
          />
        </Box> */}
        <Box sx={sx.body}>
          <Box
            sx={sx.line}
            style={{
              height: '16px',
              marginTop: '85px',
            }}
          ></Box>
          <Box
            sx={sx.line}
            style={{
              marginTop: '5px',
              height: '5px',
            }}
          ></Box>
          <Box sx={sx.content}>
            <Box sx={sx.bodyicon}>
              <LazyLoadImage
                src={bodyicon}
                alt="icon"
                style={{ width: '110px' }}
              />
            </Box>
            <Box
              style={{
                position: 'relative',
                maxWidth: '1280px',
                margin: 'auto',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box sx={sx.backgroundIcon}>
                <LazyLoadImage src={backgroundIcon} alt="icon" width="100%" />
              </Box>
            </Box>
            <Typography sx={sx.title}>GALAXIS PRIVACY POLICY</Typography>
            <Typography sx={sx.description}>
              Effective Date: 8 October, 2021
            </Typography>
            {/* <Typography sx={sx.descriptionFirst}>
              This exclusive collection was created by the legendary artists{' '}
              <a href="https://boros-szikszai.com/" target="_blank">
                Zoltan Boros and Gabor Szikszai
              </a>
              . They provided hundreds of illustrations and became significant
              artistic contributors to the world-wide success of the Magic: The
              Gathering TCG, Dungeons & Dragons, Star Wars RPG, World of
              Warcraft TCG, and then Hearthstone.
            </Typography> */}
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>ABOUT THIS POLICY</li>
                </strong>
              </ul>
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    Equinoctial Inc (BVI Company Registration Number: 2077416)
                    (“Company”) operates the Website, NFT Exchange, and NFT
                    Exchange Service (all of the foregoing as defined in the
                    Terms and Conditions made available at
                    dust-pools.ether.cards)
                  </li>
                </ul>
                <ul>
                  <li>This Policy applies to:</li>
                  <ul>
                    <li>the collection or your personal data by the Company</li>
                    <li>the Company when it has ratified this Policy; and</li>
                    <li>
                      other organizations related or affiliated to the Company
                      which have adopted this Policy as their own
                    </li>
                  </ul>
                </ul>
              </ul>
            </Typography>
            <Typography sx={sx.description}>
              (collectively referred to as " <strong>we</strong>", "
              <strong>us</strong>" or " <strong>our</strong>"). Although this
              Privacy Policy is in common use by all of the above entities, each
              is only responsible to you in relation to its own collection and
              use of your personal data.
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    This Privacy Policy describes how we may collect, use,
                    disclose and process your personal data when you:
                  </li>
                  <ul>
                    <li>
                      access or use our websites, including
                      https://grd.fan/, and applications (including mobile
                      and web-based applications) (collectively, “
                      <strong>Applications</strong>”), and services; and/or
                    </li>
                    <li>provide us with your personal data.</li>
                  </ul>
                  <li>
                    We will only use your personal data where you have given us
                    your consent or where we have other lawful basis for doing
                    so, and in the manner set out in this Privacy Policy.
                  </li>
                  <li>
                    By providing us with personal data, you acknowledge that our
                    collection, use, disclosure and processing of personal data
                    will be in accordance with this Policy, including, for the
                    avoidance of doubt, the cross-jurisdictional transfer of
                    your data. DO NOT provide any personal data to us if you do
                    not accept this Privacy Policy.
                  </li>
                  <li>
                    This Privacy Policy supplements but does not supersede or
                    replace any consents you may have provided to us, or any
                    other agreements or arrangements that you may have with us,
                    in respect of your personal data.
                  </li>
                  <li>
                    Our Applications may contain links to other websites that
                    are not owned or maintained by us. These links are provided
                    only for your convenience. You may also be accessing our
                    Applications through third party websites and/or platforms.
                    This Privacy Policy only applies to our Applications. When
                    visiting these third party websites, their privacy policies
                    apply.
                  </li>
                  <li>
                    If you have any feedback or issues in relation to your
                    personal data, or about this Privacy Policy, or wish to make
                    a complaint to us, you may contact our Data Protection
                    Officer at :
                  </li>
                </ul>
              </ul>
            </Typography>
            <Box
              style={{
                position: 'relative',
                maxWidth: '1280px',
                margin: 'auto',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box sx={sx.backgroundIcon}>
                <img src={backgroundIcon} alt="icon" width="100%" />
              </Box>
            </Box>
            <Typography sx={sx.description}>Email: dpo@ether.cards</Typography>
            <Typography sx={sx.description}>
              This does not affected your statutory rights. For example, if the
              GDPR applies to you, you may also have a right to lodge a
              complaint with a European supervisory authority, in particular in
              the Member State in the European Union where you are habitually
              resident, where you work or where an alleged infringement of Data
              Protection law has taken place.
            </Typography>
            <Typography sx={sx.description}>
              When you contact us, we may require that you submit certain forms
              or provide certain information, including verification of your
              identity, before we are able to respond.
            </Typography>
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>AMENDMENTS TO THIS PRIVACY POLICY</li>
                </strong>
              </ul>
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    We may amend this Privacy Policy from time to time without
                    notice to you, in compliance with applicable laws or as we
                    update our data usage and handling processes. The updated
                    policy will supersede earlier versions and will apply to
                    personal data provided to us previously. The updated Privacy
                    Policy will take effect when made available on
                    https://grd.fan/#/privacy-policy. If we make a change that
                    significantly affects your rights or, to the extent we are
                    permitted to do so, significantly changes how or why we use
                    personal data, we will notify you by way of a prominent
                    notice on our website or, if we have your email address, by
                    email.
                  </li>
                </ul>
              </ul>
            </Typography>
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>WHAT PERSONAL DATA WE COLLECT</li>
                </strong>
              </ul>
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>What is personal data.</strong> “Personal data” is
                    data that can be used to identify a natural person. Examples
                    of personal data include name, address, contact details,
                    identification numbers, financial information, transactional
                    information based on your activities on our websites,
                    applications and platforms, telephone numbers, email
                    address, images, and any other information of a personal
                    nature.
                  </li>
                  <li>
                    <strong>Voluntary provision of personal data.</strong> We
                    may collect personal data (1) that you voluntarily provide
                    to us; or (2) from third parties; or (3) through your use of
                    our (or our services provider’s) digital technologies and
                    services (Please see Section 4 How We Collect Personal Data
                    for further details). What personal data we collect depends
                    on the purposes for which the personal data is collected and
                    what you have chosen to provide.
                  </li>
                </ul>
              </ul>
            </Typography>
            <Typography sx={sx.description}>
              When our collection is based on consent, you can choose not to
              provide us with personal data. You also have the right to withdraw
              your consent for us to continue collecting, using, disclosing and
              processing your personal data, by contacting us in accordance with
              paragraph 1.8. However, if you do so, it may not be possible for
              us to fulfil the purposes for which we require the personal data,
              including processing your transactions or providing you with the
              products and services that you require.
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>
                      Providing personal data belonging to others.{' '}
                    </strong>{' '}
                    In certain circumstances, you may also provide us with
                    personal data of persons other than yourself (such as your
                    family members and next-of-kin). If you do so, you represent
                    and warrant that you have brought this Privacy Policy to
                    his/her attention, informed him/her of the purposes for
                    which we are collecting his/her personal data and that
                    he/she has consented to your disclosure of his/her personal
                    data to us for those purposes and accepts this Privacy
                    Policy. You agree to indemnify and hold us harmless from and
                    against any and all claims by such individuals relating to
                    our collection, use and disclosure of such personal data in
                    accordance with the terms of this Privacy Policy.
                  </li>
                  <li>
                    <strong>
                      Accuracy and completeness of personal data.{' '}
                    </strong>{' '}
                    You are responsible for ensuring that all personal data that
                    you provide to us is true, accurate and complete. You are
                    responsible for informing us of any changes to your personal
                    data.
                  </li>
                  <li>
                    <strong>
                      Minors. Our Applications and/or services are not intended
                      to be accessed or used by children, minors or persons who
                      are not of legal age. If you are a parent or guardian and
                      you have reason to believe your child or ward has provided
                      us with their personal data without your consent, please
                      contact us.
                    </strong>{' '}
                  </li>
                </ul>
              </ul>
            </Typography>
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>HOW WE COLLECT PERSONAL DATA</li>
                </strong>
              </ul>
            </Typography>
            <Box
              style={{
                position: 'relative',
                maxWidth: '1280px',
                margin: 'auto',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box sx={sx.backgroundIcon}>
                <img src={backgroundIcon} alt="icon" width="100%" />
              </Box>
            </Box>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Personal data you provide.</strong> We collect
                    personal data that is relevant to our relationship with you.
                    We may collect your personal data directly or indirectly
                    through various channels, such as when:
                  </li>
                  <ul>
                    <li>
                      you access, download or use our Applications and services;
                    </li>
                    <li>
                      you authorise us to obtain your personal data from a third
                      party;
                    </li>
                    <li>you enter into agreements with us;</li>
                    <li>
                      you transact with us, contact us or request that we
                      contact you through various communication channels, for
                      example, through social media platforms, messenger
                      platforms, face-to-face meetings, telephone calls, emails,
                      fax and letters;
                    </li>
                    <li>
                      you request to be included in an e-mail or our mailing
                      list;
                    </li>
                    <li>you attend events or functions organised by us;</li>
                    <li>
                      we seek information about you and receive your personal
                      data in connection with your relationship with us; and
                    </li>
                    <li>
                      when you submit your personal data to us for any other
                      reason.
                    </li>
                  </ul>
                  <li>
                    <strong>Personal data provided by others.</strong> Depending
                    on your relationship with us, we may also collect your
                    personal data from third party sources, for example, from:
                  </li>
                  <ul>
                    <li>
                      any third parties whom you have authorised us to obtain
                      your personal data from;
                    </li>
                    <li>
                      entities in which you (or a party connected to you) have
                      an interest;
                    </li>
                    <li>
                      our business partners such as third parties providing
                      services to us;
                    </li>
                    <li>
                      your family members or friends who provide your personal
                      data to us on your behalf; and/or
                    </li>
                    <li>public agencies or other public sources.</li>
                  </ul>
                </ul>
              </ul>
            </Typography>
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>WHAT WE DO WITH YOUR PERSONAL DATA</li>
                </strong>
              </ul>
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <strong>
                    <li>
                      What we do. We collect, use, disclose and process your
                      personal data where:
                    </li>
                  </strong>
                  <ul>
                    <li>
                      you have voluntarily provided us with your personal data;
                    </li>
                    <li>you have given us consent;</li>
                    <li>
                      necessary to comply with our legal or regulatory
                      obligations, e.g. responding to valid requests from public
                      authorities;
                    </li>
                    <li>
                      necessary to support our legitimate business interests,
                      provided that this does not override your interests or
                      rights; and
                    </li>
                    <li>
                      necessary to perform a transaction you have entered into
                      with us, or provide a service that you have requested or
                      require from us.
                    </li>
                  </ul>
                  <li>
                    <strong>General purposes.</strong> We collect, use, disclose
                    and process your personal data for purposes connected or
                    relevant to our business, to manage your relationship with
                    us. Such purposes would include:
                  </li>
                  <ul>
                    <li>
                      carrying out your transactions with us and our business
                      partners, taking steps as may be directed by you, or to
                      provide products and/or services to you;
                    </li>
                    <li>
                      facilitating your use of our Applications, including
                      verifying and establishing your identity;
                    </li>
                    <li>facilitating business asset transactions;</li>
                    <li>
                      communicating with you, and assisting you with your
                      queries, requests, applications, complaints, and feedback;
                    </li>
                    <li>
                      resolving any disputes, investigating any complaint, claim
                      or dispute or any actual or suspected illegal or unlawful
                      conduct;
                    </li>
                    <li>
                      administrative purposes, including finance, IT and HR
                      purposes, quality assurance and staff training, and
                      compliance with internal policies and procedures,
                      including audit, accounting, risk management and record
                      keeping;
                    </li>
                    <li>
                      carrying out research and statistical analysis, including
                      development of new products and services or evaluation and
                      improvement of our existing products and services;
                    </li>
                    <li>
                      security purposes, e.g. protecting our Applications from
                      unauthorised access or usage and to monitor for security
                      threats;
                    </li>
                    <li>
                      compliance with any legal or regulatory obligations,
                      applicable laws, regulations, codes of practices,
                      guidelines, or rules (including anti-money laundering and
                      countering the financing of terrorism laws), or to assist
                      in law enforcement and investigations conducted by any
                      governmental and/or regulatory authority;
                    </li>
                    <li>
                      performing data analytics and related technologies on
                      data, to enable us to deliver relevant content and
                      information to you, and to improve our websites and
                      digital platforms;
                    </li>
                    <li>
                      managing and engaging third parties or data processors
                      that provide services to us, e.g. IT services,
                      technological services, delivery services, and other
                      professional services;
                    </li>
                    <li>carrying out our legitimate business interests;</li>
                    <li>
                      such purposes that may be informed to you when your
                      personal data is collected; and/or
                    </li>
                    <li>
                      any other reasonable purposes related to the aforesaid.
                    </li>
                  </ul>
                </ul>
              </ul>
            </Typography>
            <Typography sx={sx.description}>
              Where personal data is used for a new purpose and where required
              under applicable law, we shall obtain your consent.
            </Typography>
            <Box
              style={{
                position: 'relative',
                maxWidth: '1280px',
                margin: 'auto',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box sx={sx.backgroundIcon}>
                <img src={backgroundIcon} alt="icon" width="100%" />
              </Box>
            </Box>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Legitimate business interests.</strong> managing our
                    business and relationship with you, and providing services
                    to our customers;
                  </li>
                  <ul>
                    <li>
                      assistance of carrying out corporate restructuring plans;
                    </li>
                    <li>complying with internal policies, and procedures;</li>
                    <li>
                      protecting our rights and interests, and those of our
                      customers;
                    </li>
                    <li>
                      enforcing our terms and conditions, and obligations owed
                      to us, or protecting ourselves from legal liability;
                    </li>
                    <li>
                      managing our investor and shareholder relations; and
                    </li>
                    <li>
                      process or share your personal data to facilitate
                      acquisitions, mergers, or transfers of our business.
                    </li>
                  </ul>
                  <li>
                    <strong>
                      Marketing purposes. In order for us to market products,
                      events and services which are of specific interest and
                      relevance to you, we may analyse and rely on your personal
                      data provided to us, or data collected from your
                      interactions with us However, no marketing, using your
                      personal data in non-aggregated and/or identifiable form
                      would be carried out unless you have provided us with your
                      consent to use your personal data for such marketing
                      purposes. If you do not want us to use your personal data
                      for the purposes of marketing you can withdraw your
                      consent at any time by contacting us in accordance with
                      paragraph 1.8 above.
                    </strong>
                  </li>
                  <li>
                    <strong>Use permitted under applicable laws.</strong> We may
                    also collect, use, disclose and process your personal data
                    for other purposes, without your knowledge or consent, where
                    this is required or permitted by law. Your personal data may
                    be processed if it is necessary on reasonable request by a
                    law enforcement or regulatory authority, body or agency or
                    in the defence of a legal claim. We will not delete personal
                    data if relevant to an investigation or a dispute. It will
                    continue to be stored until those issues are fully resolved.
                  </li>
                  <li>
                    <strong>Contacting you.</strong> When we contact or send you
                    information for the above purposes and purposes for which
                    you have consented, we may do so by post, e-mail, SMS,
                    telephone or such other means provided by you. If you do not
                    wish to receive any communication or information from us, or
                    wish to restrict the manner by which we may contact or send
                    you information, you may contact us in accordance with
                    paragraph 1.8 above.
                  </li>
                </ul>
              </ul>
            </Typography>
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>USE OF AUTOMATED DATA COLLECTION TECHNOLOGIES</li>
                </strong>
              </ul>
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <strong>
                    <li>
                      Cookies. In order to improve our products or services, we
                      collect data by way of “cookies”. A cookie is a small file
                      of letters and numbers that we store via your browser on
                      the hard drive of your computer or mobile device. There
                      are three main types of cookies:
                    </li>
                  </strong>
                  <ul>
                    <strong>
                      <li>
                        Session cookies: specific to a particular visit and
                        limited to sending session identifiers (random numbers
                        generated by the server) so you don't have to re-enter
                        information when you navigate to a new page or check
                        out. Session cookies are not permanently stored on your
                        device and are deleted when the browser closes;
                      </li>
                    </strong>
                    <strong>
                      <li>
                        Persistent cookies: record information about your
                        preferences and are stored in your browser cache or
                        mobile device; and
                      </li>
                    </strong>
                    <strong>
                      <li>
                        Third party cookies: placed by someone other than us
                        which may gather data across multiple websites or
                        sessions
                      </li>
                    </strong>
                  </ul>
                  <strong>
                    <li>
                      How we use cookies. We use cookies for the following
                      purposes:
                    </li>
                  </strong>
                  <ul>
                    <strong>
                      <li>
                        Strictly necessary: These cookies are essential for you
                        to browse our Applications and use its features. The
                        information collected relates to the operation of the
                        Applications (e.g. website scripting language and
                        security tokens) and enables us to provide you with the
                        service you have requested.
                      </li>
                    </strong>
                    <strong>
                      <li>
                        Functionality: These cookies remember the choices you
                        have made, for example the country you visit our Website
                        from, your language and any changes you have made to
                        text size and other parts of the web pages that you can
                        customise to improve your user experience and to make
                        your visits more tailored and enjoyable. The information
                        these cookies collect may be anonymised and cannot be
                        used to track your browsing activity on other websites
                      </li>
                    </strong>
                    <strong>
                      <li>
                        Performance/analytics: These cookies collect information
                        on how users use our Website, for example which pages
                        you visit most often, whether you receive any error
                        messages and how you arrived at our Applications.
                        Information collected by these cookies is used only to
                        improve your use of our Applications. These cookies are
                        sometimes placed by third party providers of web traffic
                        and analysis services. We use Google Analytics. For
                        information on how Google processes and collects your
                        information and how you can opt out, please click here.
                      </li>
                    </strong>
                    <strong>
                      <li>
                        Targeting or advertising: These cookies collect
                        information about your browsing habits and inferred
                        interests to make advertising more relevant to you. They
                        are also used to limit the number of times you see an
                        advert as well as to measure the effectiveness of an
                        advertising campaign. These cookies are usually placed
                        by third party advertising networks. They remember the
                        other websites you visit and this information is shared
                        with third party organisations, for example advertisers.
                      </li>
                    </strong>

                    <li>
                      {' '}
                      <strong>Social Media: </strong> These cookies allow users
                      to share our website content on social media such as
                      Facebook and Twitter. These cookies are not within our
                      control. Please refer to the respective privacy policies
                      of the social media providers for how their cookies work.
                    </li>
                  </ul>
                </ul>
              </ul>
            </Typography>
            <Typography sx={sx.description}>
              We may also automatically collect and store certain information
              about your interaction with our Applications including IP address,
              browser type, internet service provider, referring/exit pages,
              operating system, date/time stamps and related data.
            </Typography>
            <Box
              style={{
                position: 'relative',
                maxWidth: '1280px',
                margin: 'auto',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box sx={sx.backgroundIcon}>
                <img src={backgroundIcon} alt="icon" width="100%" />
              </Box>
            </Box>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Other technologies.</strong> In addition to cookies,
                    we use some other similar technologies as follows:
                  </li>
                  <ul>
                    <li>
                      <strong>Web Beacons</strong>: small graphic images
                      included on our Applications or emails or those of third
                      parties to provide analytics information.
                    </li>
                    <li>
                      <strong>Social widgets</strong>: buttons or icons provided
                      by third party social media networks which may collect
                      browsing data and will be received by the third party and
                      are controlled by the third party.
                    </li>
                    <li>
                      <strong>SDKs</strong>: these are mobile application third
                      party software development kits used in our apps. They
                      enable us to collect information about app activity and
                      the device it runs on.
                    </li>
                    <li>
                      <strong>Local shared objects</strong>: these are sometimes
                      called flash cookies and can be stored on your browser.
                      They are used to maintain preferences and usage records.
                    </li>
                  </ul>
                  <li>
                    <strong>Cookies we use.</strong> A list of cookies and
                    similar technologies used in our Applications are as
                    follows:
                  </li>
                </ul>
              </ul>
            </Typography>
            <Typography sx={sx.description} style={{ fontStyle: 'italic' }}>
              <strong>Our cookies</strong>
            </Typography>
            <Box
              style={{
                margin: 'auto',
                maxWidth: '900px',
                marginBottom: '16px',
                overflowX: 'auto',
              }}
            >
              <table cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Name of Cookie</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Why we use it</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Expiration</strong>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={sx.description}>N/A</Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>N/A</Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>N/A</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Typography sx={sx.description} style={{ fontStyle: 'italic' }}>
              <strong>Third party cookies</strong>
            </Typography>
            <Box
              style={{
                margin: 'auto',
                maxWidth: '900px',
                marginBottom: '16px',
                overflowX: 'auto',
              }}
            >
              <table cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Name of cookie</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Why it is used</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Company</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Third party privacy policies</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Expiration</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>User controls</strong>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={sx.description}>
                        Google Analytics
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        Website analytics
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>Google</Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        https://policies.google.com/privacy?hl=en-US
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}></Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}></Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Typography sx={sx.description}>
              <strong>Other similar technologies used in our apps</strong>
            </Typography>
            <Box
              style={{
                margin: 'auto',
                maxWidth: '900px',
                marginBottom: '16px',
                overflowX: 'auto',
              }}
            >
              <table cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Name</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Why we use it</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Third party privacy policy</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>User control</strong>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={sx.description}>N/A</Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>N/A</Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>N/A</Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>N/A</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Typography sx={sx.description}>
              Where we allow third parties identified above to drop cookies, we
              have no control over those cookies or the data generated. Please
              refer to the third party's privacy policy for information on what
              information they collect and how they use it.
            </Typography>

            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Refusing or deleting cookies.</strong> Most internet
                    browsers are set up by default to accept cookies. However if
                    you want to refuse or delete them (or similar technologies)
                    please refer to the help and support area on your browser
                    for instructions on how to block or delete cookies (for
                    example: Internet Explorer, Google Chrome, Mozilla Firefox
                    and Safari). Please note you may not be able to take
                    advantage of all the features of our Applications, including
                    certain personalised features, if you delete or refuse
                    cookies.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Mobile Opt-out.</strong> If you access our
                    Applications through mobile devices, you can enable a "do
                    not track" feature so as to control interest-based
                    advertising on an iOS or Android mobile device by selecting
                    the Limit Add Tracking option in the privacy section of your
                    Settings on iOS or via advertising preferences on Android
                    devices (e.g. in Google Settings). This will not prevent the
                    display of advertisements but will mean that they will no
                    longer be personalised to your interests.
                  </li>
                  <ul>
                    <li style={{ wordBreak: 'break-all' }}>
                      To opt out of Google Analytics, visit
                      https://tools.google.com/dlpage/gaoptout
                    </li>
                    <li style={{ wordBreak: 'break-all' }}>
                      For more information on managing cookies, please go to
                      www.allaboutcookies.org
                    </li>
                  </ul>
                  <li>
                    <strong>
                      If you are a resident in the EU or United States.
                    </strong>
                    For more information on managing cookies, please visit:
                  </li>
                  <ul>
                    <li style={{ wordBreak: 'break-all' }}>
                      EU residents: www.youronlinechoices.eu and
                      www.aboutcookies.org; or
                    </li>
                    <li style={{ wordBreak: 'break-all' }}>
                      US residents: www.aboutads.info and
                      www.networkadvertising.org
                    </li>
                  </ul>
                </ul>
              </ul>
            </Typography>
            <Box
              style={{
                position: 'relative',
                maxWidth: '1280px',
                margin: 'auto',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box sx={sx.backgroundIcon}>
                <img src={backgroundIcon} alt="icon" width="100%" />
              </Box>
            </Box>
            <Typography sx={sx.description}>
              The above links have further information about behavioural
              advertising and online privacy.
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Changes to our uses of Cookies.</strong> If we
                    change anything important about this Paragraph 6 on cookies,
                    we will notify you through a pop-up on the website for a
                    reasonable length of time prior to and following the change.
                    You may review this Paragraph by visiting the sites and
                    clicking on the “Privacy Policy” link.
                  </li>
                </ul>
              </ul>
            </Typography>
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>DISCLOSURE OF PERSONAL DATA</li>
                </strong>
              </ul>
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Disclosure to related parties.</strong> We may
                    disclose or share your personal data with our related
                    parties, namely, any of the group entity referred to in
                    paragraph 1.2.3 above in order to provide our services to
                    you, manage our shareholder and investor returns, for
                    management and compliance purposes, and to utilise shared
                    group IT functions .
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Disclosure to third parties.</strong> We may also
                    disclose your personal data to third parties in connection
                    with purposes described in paragraph 5, including without
                    limitation the following circumstances:
                  </li>
                  <ul>
                    <li>
                      disclosing your personal data to third parties who provide
                      services to us (including, but not limited to, data
                      providers, technology providers, insurance providers, and
                      other professional services (including accountants,
                      lawyers and auditors));
                    </li>
                    <li>
                      disclosing your personal data to third parties in order to
                      fulfil such third party products and/or services as may be
                      requested or directed by you;
                    </li>
                    <li>
                      disclosing your personal data to third parties that we
                      conduct marketing and cross promotions with;
                    </li>
                    <li>
                      disclosing your personal data to authorities, governments,
                      law enforcement agencies or public agencies;
                    </li>
                    <li>
                      If we are discussing selling or transferring part or all
                      of our business – the information may be transferred to
                      prospective purchasers under suitable terms as to
                      confidentiality;
                    </li>
                    <li>
                      If we are reorganised or sold, information may be
                      transferred to a buyer who can continue to provide
                      continued relationship with you; and
                    </li>
                    <li>
                      If we are defending a legal claim your information may be
                      transferred as required in connection with defending such
                      claim.
                    </li>
                  </ul>
                  <li>
                    When disclosing personal data to third parties, we will
                    (where appropriate and permissible) enter into contracts
                    with these third parties to protect your personal data in a
                    manner that is consistent with all applicable laws and/or
                    ensure that they only process your personal data in
                    accordance with our instructions.
                  </li>
                </ul>
              </ul>
            </Typography>
            <Box
              style={{
                position: 'relative',
                maxWidth: '1280px',
                margin: 'auto',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box sx={sx.backgroundIcon}>
                <img src={backgroundIcon} alt="icon" width="100%" />
              </Box>
            </Box>
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>TRANSFER OF PERSONAL DATA TO OTHER COUNTRIES</li>
                </strong>
              </ul>
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Transfers.</strong> We may transfer your personal
                    data to different jurisdictions in connection with the
                    purposes described in paragraph 5:
                  </li>
                  <ul>
                    <li>
                      from the jurisdiction where it is collected (or where you
                      are located) to any other jurisdictions that we operate
                      in; and
                    </li>
                    <li>to third parties in other jurisdictions.</li>
                  </ul>
                  <li>
                    <strong>Safeguards.</strong> Where we transfer your personal
                    data across jurisdictions, we will ensure that your personal
                    data is protected in accordance with this policy and
                    applicable laws regardless of the jurisdictions they are
                    transferred to, but in any event to a level that is no less
                    stringent than the jurisdiction from which the personal data
                    is transferred. When we transfer your personal data
                    internationally and where required by applicable law we put
                    in place appropriate safeguards including EU Model Clauses
                    or rely on EU Commission adequacy decisions. You may obtain
                    details of these safeguards by contacting us.
                  </li>
                </ul>
              </ul>
            </Typography>
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>SECURITY AND RETENTION OF PERSONAL DATA</li>
                </strong>
              </ul>
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Unauthorised access.</strong> While we take
                    reasonable precautions to safeguard your personal data in
                    our possession or under our control, you agree not to hold
                    us liable or responsible for any loss or damage resulting
                    from unauthorised or unintended access that is beyond our
                    control, such as hacking or cybercrimes.
                  </li>
                  <li>
                    <strong>Vulnerabilities.</strong> We do not make any
                    warranty, guarantee, or representation that your use of our
                    systems or applications is safe and protected from malware,
                    and other vulnerabilities. We also do not guarantee the
                    security of data that you choose to send us electronically.
                    Sending such data is entirely at your own risk.
                  </li>
                  <li>
                    <strong>Period of retention.</strong> We retain your
                    personal data only for as long as is necessary to fulfil the
                    purposes we collected it for, and to satisfy our business
                    and/or legal purposes, including data analytics, audit,
                    accounting or reporting purposes. How long we keep your
                    personal data depends on the nature of the data, e.g. we
                    keep personal data for at least the duration of the
                    limitation period for bringing claims if the personal data
                    may be required to commence or defend legal proceedings.
                    Some information may also be retained for longer, e.g. where
                    we are required to do so by law.
                  </li>
                </ul>
              </ul>
            </Typography>
            <Typography sx={sx.description}>
              We have set out how long we will typically keep certain types of
              personal data below.
            </Typography>
            <Box
              style={{
                margin: 'auto',
                maxWidth: '900px',
                marginBottom: '16px',
                overflowX: 'auto',
              }}
            >
              <table cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Category of personal information</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>Examples</strong>
                      </Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>
                        <strong>
                          How long we will keep this personal information
                        </strong>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography sx={sx.description}>Email</Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}></Typography>
                    </td>
                    <td>
                      <Typography sx={sx.description}>5 years</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Anonymised data.</strong> In some circumstances we
                    may anonymise your personal data so that it can no longer be
                    associated with you, in which case we are entitled to retain
                    and use such data without restriction.
                  </li>
                </ul>
              </ul>
            </Typography>
            <Typography className="firstList" sx={sx.description}>
              <ul>
                <strong>
                  <li>YOUR RIGHTS</li>
                </strong>
              </ul>
            </Typography>
            <Box
              style={{
                position: 'relative',
                maxWidth: '1280px',
                margin: 'auto',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box sx={sx.backgroundIcon}>
                <img src={backgroundIcon} alt="icon" width="100%" />
              </Box>
            </Box>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Rights you may enjoy.</strong> Depending on the
                    jurisdiction that you are in or where we operate, you may
                    enjoy certain rights under applicable law in relation to our
                    collection, use, disclosure and processing of your personal
                    data. Such rights may include:
                  </li>
                  <ul>
                    <li>
                      <strong>Access</strong>: you may ask us if we hold your
                      personal data and, if we are, you can request access to
                      your personal data. This enables you to receive a copy of
                      and information on the personal data we hold about you.
                    </li>
                    <li>
                      <strong>Correction</strong>: you may request that any
                      incomplete or inaccurate personal data we hold about you
                      is corrected.
                    </li>
                    <li>
                      <strong>Erasure</strong>: you may ask us to delete or
                      remove personal data that we hold about you in certain
                      circumstances.
                    </li>
                    <li>
                      <strong>Restriction</strong>: you may withdraw consent for
                      our use of your personal data, or ask us to suspend the
                      processing of certain of your personal data about you, for
                      example if you want us to establish its accuracy.
                    </li>
                    <li>
                      <strong>Portability</strong>: you may request the transfer
                      of certain of your personal data to another party under
                      certain conditions.
                    </li>
                    <li>
                      <strong>Objection</strong>: where we are processing your
                      personal data based on a legitimate interest (or those of
                      a third party) you may object to processing on this
                      ground.
                    </li>
                  </ul>
                </ul>
              </ul>
            </Typography>
            <Typography sx={sx.description}>
              If you wish to exercise any of your rights, you may contact us in
              accordance with paragraph 1.8. We may require that you submit
              certain forms or provide certain information to process your
              request. Where permitted by law, we may also charge you a fee to
              process your request.
            </Typography>
            <Typography className="secondList" sx={sx.description}>
              <ul>
                <ul>
                  <li>
                    <strong>Limitations.</strong> We may be permitted under
                    applicable laws to refuse a request, for example, we may
                    refuse (a) a request for erasure where the personal data is
                    required for in connection with claims; or (b) an objection
                    request and continue processing your personal data based on
                    compelling legitimate grounds for the processing.
                  </li>
                </ul>
              </ul>
            </Typography>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: 'auto',
                gap: '0px',
                marginBottom: '10px',
                flexWrap: 'wrap',
              }}
            >
              <Box>
                <Typography
                  align="center"
                  sx={sx.date}
                  style={{
                    fontSize: '20px',
                    marginBottom: '10px',
                    marginTop: '80px',
                  }}
                >
                  POWERED BY
                </Typography>
                <Box
                  style={{
                    maxWidth: '150px',
                    margin: 'auto',
                    marginBottom: '20px',
                  }}
                >
                  <img
                    onClick={() => {
                      window.open('https://galaxis.xyz/#/', '_blank');
                    }}
                    width="100%"
                    src={galaxisLogo}
                    alt="icon"
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              </Box>
              <Box sx={sx.bodyicon}>
                <img
                  src={bodyicon}
                  alt="icon"
                  style={{
                    width: '110px',
                    transform: 'rotate(180deg)',
                  }}
                />
              </Box>

              <Footer />
            </Box>
            <Box
              sx={sx.line}
              style={{
                height: '16px',
                marginTop: '30px',
              }}
            ></Box>
            <Box
              sx={sx.line}
              style={{
                marginTop: '5px',
                height: '5px',
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
