import { Typography } from '@mui/material'
import React from 'react'
import dustIcon from '../../../../assets/images/dust-icon.svg'

const sx = {
  typo: {
    display: 'flex',
    gap: '5px',
    fontSize: '20px',
    width: 'auto',
    color: '#FFF',
    alignItems: 'center',
    '& img': {
      maxHeight: '20px'
    }
  }
}

const DustButton = ({ dustAmount, traitsVisible, handleDustClick}) => {
  return (
    <div
      className={`explorer-dust-amount-container ${traitsVisible ? 'hide' : ''
        }`}
        onClick={handleDustClick}
    >
      <p className='explorer-dust-text-element'>
        <img src={dustIcon} alt="" />
        {parseFloat(dustAmount).toFixed(2)}
      </p>
    </div>
  )
}

export default DustButton