import axios from 'axios';
import config from './config/config';

const urlParam = window.location.hash.slice(2);
const BASE_URL = `https://grd-metadata-staging.herokuapp.com/api`;

const GALAXIS_BASE_URL = 'https://cms.staging.galaxis.xyz';

export const getFilters = async () => {
  if (!urlParam){
    console.log("reload")
    window.location.reload();
  }
  return new Promise((resolve, reject) => {
    axios.get(`${BASE_URL}/filters`).then((response) => {
      resolve(response.data);
    });
  });
};
export const getFilteredCards = async (filters) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/metadata/filter`, filters)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

export const getTraitTypes = async() =>{
  return new Promise((resolve, reject) => {
    axios.get(`${GALAXIS_BASE_URL}/trait_types`).then((response) => {
      resolve(response.data);
    });
  });
}

export const getMetadata = async(metadataUrl) =>{
  return new Promise((resolve, reject) => {
    axios.get(`${metadataUrl}`).then((response) => {
      resolve(response.data);
    });
  });
}