import { Typography, Box } from "@mui/material";
import PopupModal from "./PopupModal";
import MintQuantity from "./MintQuantity";
import { SALE_STATUS } from "../Sales";

const sx = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  title: {
    fontFamily: "poppins",
    fontWeight: 700,
    size: 26,
    mb: 1,
  },
  text: {
    fontFamily: "poppins",
    fontSize: 12,
  },
};

const CheckoutModal = ({
  tokenName,
  setOpen,
  isOpen,
  mintPresale,
  mintSale,
  isPresale,

  salePrice,
  presalePrice,
  tokenPrice,
  presaleTokenPrice,
  
  whitelistLimit,
  withToken,
  erc677Symbol
}) => {
  
  const headerText = isPresale ? "Whitelist Checkout" : "Checkout";

  return (
    <PopupModal setOpen={setOpen} isOpen={isOpen} header={headerText}>
      <Box sx={sx.content}>
        <Typography sx={sx.title}>{tokenName} Token</Typography>
        <Typography sx={sx.text}>
          Please select the number of NFT you wish to mint.
        </Typography>

        {isPresale && (
          <MintQuantity
            price={Number(withToken?presaleTokenPrice:presalePrice)}
            maxAmount={whitelistLimit}
            onClickMint={mintPresale}
            tokenSymbol={withToken?erc677Symbol:'ETH'}
            withToken={withToken}
          />
        )}
        {!isPresale && (
          <MintQuantity
            price={Number(withToken?tokenPrice:salePrice)}
            maxAmount={whitelistLimit}
            onClickMint={mintSale}
            tokenSymbol={withToken?erc677Symbol:'ETH'}
            withToken={withToken}
          />
        )}
      </Box>
    </PopupModal>
  );
};

export default CheckoutModal;
