import React, { useState } from 'react';

const ShowTokenCheckbox = ({ showToken }) => {
  const cheched = () => {
    var checkBox = document.getElementById('checkbox');
    if (checkBox.checked == true) {
      showToken(true);
    } else showToken(false);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <label>Show token #</label>
      <input
        type="checkbox"
        name="acceptRules"
        className="checkbox"
        id="checkbox"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          cheched();
        }}
      />
    </div>
  );
};

export default ShowTokenCheckbox;
