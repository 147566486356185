import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
const sx = {
  root: {
    width: '566px',
    height: 'fit-content',
    backgroundColor: '#FFFFFF',
    border: '1px solid #707070',
    borderRadius: '27px',
    overflow: 'hidden',
    webkitBoxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
    mozBoxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
    boxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',

  },
  content: {
    p: '70px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },

  title: {
    fontFamily: 'Boucherie-block',
    fontSize: '38px',
    textAlign: 'center',
    lineHeight: '40px',
    color: '#FC6405',
  },
  description: {
    fontFamily: 'poppins',
    fontSize: '16px',
    textAlign: 'center',
  },
  button: {
    border: '2px solid #FC6405',
    backgroundColor: 'unset',
    color: '#FC6405',
    boxShadow: 'unset',
    '&:hover': {
        border: '2px solid #FC6405',
        backgroundColor: '#FC6405',
        color: '#fafaf2',
    },
    '&:active': {
        border: '2px solid #FC6405',
        backgroundColor: '#FC6405',
        color: '#fafaf2',
    },
},
}
const SuccesModal = ({ onClose }) => {
  return (
    <Box sx={sx.root}>
      <Box sx={sx.content}>
        <Typography sx={sx.title}>Demo completed</Typography>
        <Typography sx={sx.description}>Claiming a utility trait is as easy as selecting the option and signing a wallet transaction. When you authorise a transaction, the request is processed and the utility trait will be burned.</Typography>
        <Button variant="contained" sx={sx.button}
          onClick={() => { onClose() }}>CLOSE</Button>
      </Box>
    </Box >
  )
}

export default SuccesModal