import { Box, Button, Modal } from '@mui/material'
import React, { useState } from 'react'
import AutographClaim from './AutographClaim'
import MeetingClaim from './MeetingClaim'
import PhysicalCalim from './PhysicalCalim'
import close from '../../../assets/images/black-close.svg';
import SuccesModal from './SuccesModal'
import Gallery from '../../GalleryModal'
import { galleryImages} from '../../../View/Home/data';
const sx = {
    root: {
        maxWidth: '566px',
        width: '100%',
        maxHeight: '100vh',
        overflowY: 'auto',
        backgroundColor: '#FFFFFF',
        border: '1px solid #707070',
        borderRadius: '27px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        webkitBoxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
        mozBoxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
        boxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
    },
    closeButton: {
        width: '17.5px',
        height: '17.5px',
        position: 'absolute',
        right: '38px',
        top: '37px',
        cursor: 'pointer'
    },
    button: {
        border: '2px solid #FC6405',
        backgroundColor: 'unset',
        color: '#FC6405',
        boxShadow: 'unset',
        '&:hover': {
            border: '2px solid #FC6405',
            backgroundColor: '#FC6405',
            color: '#fafaf2',
        },
        '&:active': {
            border: '2px solid #FC6405',
            backgroundColor: '#FC6405',
            color: '#fafaf2',
        },
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        maxWidth: '1280px',
        margin: 'auto'
    },
    buttonHolder: {
        display: 'flex',
        gap: '20px',
        margin: 'auto',
    }
}

const ModalChildren = ({ trait, onClose }) => {
    const [openFinalModal, setopenFinalModal] = useState(false);
    const [openGallery, setOpenGallery] = useState(false);
    const finnish = () => {
        onClose();
        setopenFinalModal(false);
    }
    return (
        <>
            <Box sx={sx.root}>
                <img src={close} style={sx.closeButton} alt="" onClick={() => { onClose(); setopenFinalModal(false) }} />
                {trait.type === 'physical' && <PhysicalCalim trait={trait} />}
                {trait.type === 'meeting' && <MeetingClaim trait={trait} />}
                {trait.type === 'autograph' && <AutographClaim trait={trait} />}
                <Box sx={sx.buttonHolder}>
                    <Button variant="contained"
                        sx={sx.button}
                        onClick={() => { setopenFinalModal(true) }}
                        style={{ marginBottom: '30px' }}>
                        {trait.type === 'meeting' ? 'Book' : 'Submit'}
                    </Button>
                    {trait.type === 'physical' &&
                        <Button variant="contained"
                            sx={sx.button}
                            onClick={() => { setOpenGallery(true) }}
                            style={{ marginBottom: '30px' }}>
                            Gallery
                        </Button>
                    }
                </Box>
            </Box>
            <Gallery
            images={galleryImages}
            isOpen={openGallery}
            setOpen={setOpenGallery}
          />
            <Modal sx={sx.modal} open={openFinalModal} >
                <SuccesModal onClose={() => {
                    finnish()
                }} />
            </Modal>
        </>
    )
}

export default ModalChildren