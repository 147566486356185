import React, { useEffect, useState } from 'react';
import close from '../../../assets/images/white_close_4x.png';

const TraitCard = ({ trait, onClick, image, traitImg, onPolygon,claimDisabled }) => {
    useEffect(() => {
        if (trait.type === 'badge') {
            document.getElementById('explorer-badge-description').innerHTML = trait.description;
        }
    }, [trait])
    
    return (
        <>
            <img
                className='explorer-close-button'
                src={close}
                alt=''
                onClick={(e) => onClick(e)}
            />
            {trait.type && trait.type === 'badge' ? (
                    <div
                        className='explorer-badge-card-root'
                        style={{ backgroundImage: `url(${trait.image})` }}
                    >
                        <div className='explorer-badge-card-content'>
                            <div className='explorer-trait-card-content-holder'>
                                <div className='explorer-trait-card-trait-icon-holder'>
                                    <img src={trait.icon} alt='' />
                                </div>
                                <div className='explorer-trait-card-title-holder'>
                                    <p className='explorer-trait-card-title'>Perfect</p>
                                </div>
                                <div className='explorer-trait-card-description-holder'>
                                   <p className='explorer-badge-card-description' id='explorer-badge-description'
                                        dangerouslySetInnerHTML={{__html: trait.description}}/>
                                </div>
                            </div>
                        </div>
                    </div>
            ) : (
                <div
                    className='explorer-trait-card-root'
                    style={{ backgroundImage: `url(${image})` }}
                >
                    <div className='explorer-trait-card-content'>
                        <div className='explorer-trait-card-content-holder'>
                            <div className='trait-card-trait-icon-holder'>
                               <img src={trait.icon_url} alt='' style={{width:'50px'}} />
                            </div>
                            <div className='explorer-trait-card-title-holder'>
                                <p className='explorer-trait-card-title' style={{fontSize:'1.2rem'}}>{trait.name}</p>
                            </div>
                            <div className='explorer-trait-card-description-holder'>
                                <p className='explorer-trait-card-description'>{trait.description}</p>
                                
                                

                            </div>
                            
                            <div className="claim-button-holder">
                                {(trait.claim_url && !claimDisabled)&&
                                    <>
                                        {!onPolygon?<p>
                                            <a href='https://nftbridge.galaxis.xyz/#/' className="trait-claim-btn" target="_blank">BRIDGE</a>
                                        </p>
                                        :
                                        <p>
                                            <a href={trait.claim_url} className="trait-claim-btn" target="_blank">CLAIM</a>
                                        </p>}

                                    </>
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TraitCard;
