import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import close from '../../assets/images/white_close_4x.png';
import { Button, Modal, Typography } from '@mui/material';
import leftArrow from '../../assets/images/white-left-arrow.svg';
import rightArrow from '../../assets/images/white-right-arrow.svg';
import trait_card from '../../assets/images/trait_card.png';
import perfectBadge from '../../assets/images/perfect-badge.png';
import ModalChildren from './Claim/ModalChildren';
import ModalRewards from './Claim/ModalRewards';

const BP1 = '@media (max-width: 450px)'

const sx = {
    root: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
        backgroundImage: `url(${trait_card})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    content: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
        backgroundColor: '#fc6405b3',
    },
    closeButton: {
        width: '20.5px',
        height: '20.5px',
        cursor: 'pointer',
        position: 'absolute',
        top: '30px',
        left: '30px',
        zIndex: 5,
        WebkitTransform: 'translate3d(0,0,0)',
    },
    contentHolder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90%',
        [BP1]: {
            height: '100%',
        }
    },
    traitIconHolder: {
        width: '55px',
        height: '55px',
        mb: '20px',
        [BP1]: {
            mb: '5px',
            width: '45px',
            height: '45px',
        }
    },
    traitIcon: {
        width: '100%',
        height: '100%',
        transition: '0',
        objectFit: 'contain !important',
        borderRadius: '0px !important',
        position: 'inherit !important',
        transform: 'rotateY(180deg)',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        alignItems: 'center',
    },
    titleText: {
        transform: 'rotateY(180deg)',
        fontSize: '36px',
        fontFamily: 'Boucherie-block',
        lineHeight: '42px',
        textAlign: 'center',
        color: '#FAFAF2',
        width: 'min-content',
        minWidth: '150px',
        [BP1]: {
            fontSize: '30px',
            lineHeight: '35px',
        }
    },
    descriptionHolder: {
        mt: '25px',
        display: 'flex',
        justifyContent: 'center',
        [BP1]: {
            mt: '10px',
        }
    },
    description: {
        transform: 'rotateY(180deg)',
        fontFamily: 'bau',
        fontSize: '18px',
        lineHeight: '28px',
        textAlign: 'center',
        maxWidth: '200px',
        minWidth: '200px',
        color: '#FAFAF2',
        [BP1]: {
            fontSize: '16px',
        }
    },
    button: {
        mt: '25px',
        transform: 'rotateY(180deg)',
        border: '2px solid #FAFAF2',
        backgroundColor: 'unset',
        boxShadow: 'unset',
        '&:hover': {
            border: '2px solid black',
            backgroundColor: 'black',
        },
        '&:active': {
            border: '2px solid black',
            backgroundColor: 'black',
        },
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        maxWidth: '1280px',
        margin: 'auto',
        maxHeight: '100%',
        overflowY: 'auto',
    },
    secondRoot: {
        height: '100%',
        transform: 'rotateY(180deg)',
        borderRadius: '9px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    secondContent: {
        px: '20px'
    },
    badgeTitle: {
        color: '#FAF6F4',
        textAlign: 'center',
        fontFamily: 'Poppins-semibold',
        fontSize: '24px'

    },
    badgeDescription: {
        color: '#FAF6F4',
        fontFamily: 'bau',
        fontSize: '18px',
        textAlign: 'center',
        lineHeight: '28px',
        px: '5px',
        maxHeight: '225px',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.2em',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#FF692B',
        },
    },
    secondButton: {
        mb: '25px',
        border: '2px solid #FAFAF2',
        backgroundColor: 'unset',
        boxShadow: 'unset',
        '&:hover': {
            border: '2px solid black',
            backgroundColor: 'black',
        },
        '&:active': {
            border: '2px solid black',
            backgroundColor: 'black',
        },
    },
    rewardModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        maxWidth: '1280px',
        margin: 'auto',
        maxHeight: '100%',
        overflowY: 'auto',
        minHeight: '300px',
    },
    badgeIconHolder: {
        transform: 'rotateY(180deg)',
        width: '100px',
        height: '100px',
    }
};

const TraitCard = ({ trait, onClick }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [rewardModalIsOpen, setRewardModalIsOpen] = useState(false);
    useEffect(() => {
        if (trait.type === 'badge') {
            document.getElementById('badgeDescription').innerHTML = trait.description;
        }
    }, [trait])



    return (
        <>
            <img
                src={close}
                style={sx.closeButton}
                alt=''
                onClick={() => onClick()}
            />
            {trait.type !== 'medal' && trait.type !== 'badge' ? (
                <Box sx={sx.root}>
                    <Box sx={sx.content}>
                        <Box sx={sx.contentHolder}>
                            <Box sx={sx.traitIconHolder}>
                                <img src={trait.icon} alt='' style={sx.traitIcon} />
                            </Box>
                            <Box sx={sx.title}>
                                <img
                                    className='arrow'
                                    src={leftArrow}
                                    alt=''
                                    style={{ height: '40px' }}
                                />
                                <Typography sx={sx.titleText}>{trait.title}</Typography>
                                <img
                                    className='arrow'
                                    src={rightArrow}
                                    alt=''
                                    style={{ height: '40px' }}
                                />
                            </Box>
                            <Box sx={sx.descriptionHolder}>
                                <Typography sx={sx.description}>{trait.description}</Typography>
                            </Box>
                            <Button
                                sx={sx.button}
                                variant='contained'
                                onClick={() => {
                                    setModalIsOpen(true);
                                }}
                            >
                                Demo
                            </Button>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box sx={sx.secondRoot}
                    style={{ backgroundImage: `url(${trait.icon})`, justifyContent: trait.type === 'medal' && 'flex-end' }}
                >
                    {trait.type === 'badge' && <Box sx={sx.badgeIconHolder}>
                        <img src={perfectBadge} alt='' style={sx.traitIcon} />
                    </Box>
                    }
                    <Box sx={sx.secondContent}>
                        {trait.title &&
                            <Typography sx={sx.badgeTitle}>{trait.title}</Typography>
                        }
                        {trait.description &&
                            <Typography id="badgeDescription" sx={sx.badgeDescription}></Typography>
                        }
                    </Box>
                    {/* {trait.type === 'medal' &&
                        <Button
                            sx={sx.secondButton}
                            variant='contained'
                            onClick={() => {
                                setRewardModalIsOpen(true);
                            }}
                        >
                            Demo
                        </Button>} */}
                </Box>
            )}
            <Modal
                sx={sx.modal}
                open={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
                aria-labelledby='parent-modal-title'
                aria-describedby='parent-modal-description'
            >
                <ModalChildren trait={trait} onClose={() => setModalIsOpen(false)} />
            </Modal>

            <Modal
                sx={sx.rewardModal}
                open={rewardModalIsOpen}
                onClose={() => setRewardModalIsOpen(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <ModalRewards onClose={() => setRewardModalIsOpen(false)} />
            </Modal>
        </>
    );
};

export default TraitCard;
