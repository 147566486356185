import { useState } from 'react';

const CustomFilterSelect = ({ keyname, filters, filterChanged }) => {
  const [selectValue, setSelectValue] = useState('');

  const handleSelectChange = (keyname, e) => {
    setSelectValue(e.target.value);
    filterChanged(keyname, e.target.value);
  };

  const renderFilters = () => {
    return filters[keyname].map((filter) => {
      return <option key={`filter` + Math.random()}>{filter}</option>;
    });
  };

  return (
    <div className="d-flex flex-column mx-2 custom-filter-select">
      <label>{keyname}</label>
      <div className="select-parent">
        <select
          value={selectValue}
          onChange={(e) => handleSelectChange(keyname, e)}
        >
          <option>All</option>
          {renderFilters()}
        </select>
      </div>
    </div>
  );
};

export default CustomFilterSelect;
