import React from 'react'
import styled from 'styled-components';
import dustIcon from '../../../../assets/images/dust-icon.png'
import closeX from '../../../../assets/images/white_close.svg'
import './DustLayer.css'

const CloseButton = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  & img {
    width: 16px;
    height: 16px;
  }

  // &:hover {
  //   filter: brightness(0) saturate(100) invert(23%) sepia(93%) saturate(5853%)
  //     hue-rotate(286deg) brightness(98%) contrast(93%);
  // }
`;

const SlidePopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: #fc6405;
  // border-radius: 1rem;
  transform-origin: top center;
  z-index: 11;
  overflow: hidden;
  transform: ${({ showPopup }) => (showPopup ? "scale(1)" : "scale(0)")};
  visibility: ${({ showPopup }) => (showPopup ? 'visible' : 'hidden')};
  transition: all 0.4s ease-in-out;
  text-align: center;
`;

const SlideContent = styled.div`
  position: absolute;
  width: 92%;
  left: 4%;
  right: 4%;
  top: 10%;
  bottom: 4%;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  overflow: auto;
`;
const Amount = styled.div`
  width: fit-content;
  background-color: #000;
  border-radius: 0.3em;
  color: white;
  // font-size: 0.6em;
  margin: auto;
  display: flex;
  gap: 0.3em;
  align-items: center
`;


const DustLayer = ({ dustVisible, dustAmount, handleClose }) => {
  return (
    <>
      <SlidePopup showPopup={dustVisible} className={`explorer-dust-layer-container ${dustVisible && 'active'}`}>
        <img
          className='explorer-dust-close-button'
          src={closeX}
          alt=''
          onClick={handleClose}
        />

        <SlideContent
          className='explorer-dust-content'
        >
          <img src={dustIcon} alt="" className='explorer-dust-layer-icon' />

          <p className='explorer-dust-layer-title'>
            DUST
          </p>

          <p className='explorer-dust-layer-description'>
            Dust is the utility token used across the entire Galaxis ecosystem.
          </p>
          <div>
            <div className='explorer-dust-layer-description'>
              Amount:
              <Amount className='explorer-dust-amount-value-holder'>
                <img src={dustIcon} alt="" />
                <p className='explorer-dust-amount-value'>
                  {parseFloat(dustAmount).toFixed(2)}
                </p>
              </Amount>
            </div>
          </div>
        </SlideContent>
      </SlidePopup>
    </>
  )
}

export default DustLayer