import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Contract } from '@ethersproject/contracts';
import { DateTime } from 'luxon';
import config from '../../config/config';
import { BigNumber, ethers, providers } from 'ethers';
import { toast } from 'react-toast';

import useInterval from '../../hooks/useInterval';


import Counter from "../../Components/Counter/Counter";
import Timer from './components/Timer';
import useWeb3Ctx from '../../hooks/useWeb3Ctx';

import saleABI from './abi/SaleV3.json';
import tokenABI from './abi/TokenV3.json';
import erc1820RegistryABI from './abi/ERC1820Registry.json';
import erc677TokenABI from './abi/ERC677Token.json';
import erc777TokenABI from './abi/ERC777Token.json';
import CheckoutModal from './components/CheckoutModal';
import BackdropModal from './components/BackdropModal';
import TxProgressModal from './components/TxProgressModal';
import PermissionErrorModal from './components/PermissionErrorModal';
import './Sales.css';
import Wallet from './components/Wallet';
import axios from 'axios';
import { useContext } from 'react';
import { ERC1820_REGISTRY_ADDRESS } from './abi/constants/addresses';
import InfoBox from './components/InfoBox';
import DecoratedTitle from '../DecoratedTitle';
import { SpinnerCircularFixed } from 'spinners-react';
import PostponePanel from './components/PostponePanel';

import whitelist from "./whitleist";
import whitelistTest from "./whitleist-test";

const BP2 = '@media (max-width: 1345px)';
const BP4 = '@media (max-width: 600px)';
const BP5 = '@media (max-width: 899px)';
const BP6 = '@media (max-width: 700px)';
const BP3 = '@media (max-width: 384px)';
const BP7 = '@media (max-width: 820px)';
const BP1 = '@media (max-width: 570px)';

const sx = {
	saleRoot: {
		textAlign: 'center'
	},
	container: {
		margin: "auto",
		mt: "-5px",
		position: "relative",
		pt: "50px",
		pb: "100px",
		maxWidth: "1233px",
		overflow: "hidden",
		transition: "all .3s",
		backgroundColor: "#FFF",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		px: "20px",
		color: "#D2D4C2",
		fontFamily: "boucherie-block",
		[BP4]: {
			pt: "39px",
		},
	},
	nameContainer: {
		marginTop: "50px",
		position: "absolute",
		mx: "auto",
		display: "flex",
		maxWidth: "1140px",
		width: "100%",
		zIndex: "1",
		backgroundColor: "#511BA3",
		[BP4]: {
			marginTop: "35px",
		},
	},
	name: {
		position: "absolute",
		height: "135px",
		width: "135px",
		backgroundColor: "#511BA3",
		borderRadius: "15px",
		bottom: "4px",
		left: "0",
		right: "0",
		marginRight: "auto",
		marginLeft: "auto",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	infoContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		textAlign: "center",
		paddingTop: "0px"
	},
	infoContainerPool: {
		display: "inline-flex",
		flexDirection: "row",
		width: "auto",
		textAlign: "center",
		paddingTop: "0px"
	},
	poolCounter: {
		marginLeft: "12px"
	},
	poolCounterTitle: {
		color: "#D2D4C2",
		fontFamily: "boucherie-block",
		textAlign: "left",
	},
	poolCounterValue: {
		color: "#D2D4C2",
		fontFamily: "boucherie-block",
		textAlign: "left",
		fontSize: "20px"
	},
	badgeContainer: {
		position: "relative",
		margin: "auto",
		// maxWidth: "300px",
		borderRadius: "12px",
		border: "solid 2px #D2D4C2",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		px: "20px",
	},
	badgeLeft: {
		borderRight: "solid 2px #D2D4C2",
		paddingRight: "20px",
		[BP3]: {
			borderRight: "unset",
			borderBottom: "solid 2px #D2D4C2",
			width: "100%",
			paddingRight: "0",
		},
	},
	badgeCenter: {
	  borderRight: "solid 2px #D2D4C2",
		paddingLeft: "10px",
    paddingRight: "10px",
		[BP3]: {
			borderRight: "unset",
			borderBottom: "solid 2px #D2D4C2",
			width: "100%",
			paddingLeft: "0",
		},
	},
	badgeRight: {
    paddingLeft: "10px",
		[BP3]: {
			width: "100%",
      paddingLeft: "0",
		},
	},
	timeContainer: {
		padding: "4px",
		margin: "auto",
		display: "inline-flex",
		maxWidth: "512px",
		borderRadius: "12px",
		border: '2px solid #D2D4C2',
		flexWrap: "wrap",
		justifyContent: "center",
		gap: "12px",
		[BP4]: {
			padding: "10px",
		},
	},
	cardContainer: {
		mx: "auto",
		mt: "50px",
		mb: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		flexWrap: "wrap",
		gap: "40px 16px",
	},
	title: {
		fontSize: "36px",
	},
	titleLive: {
		color: "#000",
    fontFamily: "Boucherie-block"
	},
	socialContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		gap: "17px",
		mt: "20px",
		mb: "30px",
	},
	description: {
		fontFamily: "poppins",
		color: "#D2D4C2",
		fontSize: "16px",
		lineHeight: "24px",
		maxWidth: "606px",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		margin: "auto",
		mt: "25px",
	},
	nftContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	largeBanner: {
		display: "block",
		[BP7]: {
			display: "none",
		},
	},
	smallBanner: {
		display: "none",
		[BP7]: {
			display: "block",
		},
	},
	button: {
		backgroundColor: "#FF692B",
		color: "#FAFAFA",
		fontSize: "14px",
		padding: "14px",
		borderRadius: "8px",
		"&:hover": {
			backgroundColor: "#FF692B",
		},
	},
	buttonOutlined: {
		backgroundColor: "transparent",
		color: "#FF692B",
		fontSize: "14px",
		padding: "14px",
		borderRadius: "8px",
		"&:hover": {
			backgroundColor: "#FF692B",
			color: "white"
		},
	},

	tabContainer: {
		justifyContent: "center",
		paddingTop: "40px",
		paddingBottom: "40px",
		mx: 'auto',
	},
	tabBtns: {
		color: "#D2D4C2",
		borderColor: "#FF692B",
		borderWidth: "2px",
		padding: "6px 14px",
		fontSize: '20px',
		fontFamily: "boucherie-block",
		"&:hover": {
			borderColor: "#FF692B",
			backgroundColor: "#fff",
			borderWidth: "2px",
			color: "#FF692B",
			borderRightColor: "#FF692B !important",
		},
		borderRadius: "10px"
	},
	tabSelected: {
		backgroundColor: "#FF692B",
		color: "#fff",
	},
	buyButton: {
		height: 'auto',
		fontWeight: '500',
		fontSize: '20px',
		lineHeight: 'inherit',
		textTransform: 'uppercase',
		padding: '6px 16px',
		borderRadius: '5px',
		whiteSpace: 'nowrap',
		backgroundColor: '#FF692B',
		border: '2px solid #FF692B',
		fontFamily: 'boucherie-block',
		color: '#fff',
		'&:hover': {
			border: '2px solid #fff',
			backgroundColor: '#fff',
			color: '#FF692B',
		},
		'&:disabled': {
			opacity: '0.7'

		},
		[BP3]: {
			width: '100%',
			px: '14px',
		},
	},
	url: {
		textDecoration: "none",
		color: "#FF692B",
		fontWeight: "700",
	},
	description: {
		margin: 'auto',
		maxWidth: '715px',
		fontFamily: 'poppins',
		fontSize: '14px',
		color: '#D2D4C2',
		textAlign: 'center',
		lineHeight: '1.8',

	}
};



export const SALE_STATUS = {
  UPCOMING_PRESALE: 0,
  PRESALE: 1,
  PRESALE_ENDED: 2,
  SALE: 3,
  SALE_ENDED: 4,
};

const SalesV3 = ({ tokenAddress, saleAddress, symbol, labels, saleChain, openSeaName }) => {

  const BUY_TYPE_APSALE = 1;
  const BUY_TYPE_SALE = 2;

  const [postponedSale] = useState(false);

  const { onboard, address, handleConnect, ethersProvider , chainId} =
    useWeb3Ctx();

  const [tab, setTab] = useState(1);

  const [minted, setMinted] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);


  const [freeWhitesaleMint, setFreeWhitesaleMint] = useState(false);
  const [whiteListSaleEthPrice, setWhiteListSaleEthPrice] = useState(BigNumber.from(0));
  const [whiteListSaleTokenPrice,setWhiteListSaleTokenPrice]= useState(BigNumber.from(0));
  
  const [publicSaleEthPrice, setPublicSaleEthPrice] = useState(BigNumber.from(0));
  const [publicSaleTokenPrice, setPublicSaleTokenPrice] = useState(BigNumber.from(0));


  //sale states needed a different approach, because the sales can overlap each other :/

  const [preSaleStarted, setPreSaleStarted] = useState(false);
  const [preSaleFinished, setPreSaleFinished] = useState(false);

  const [mainSaleStarted, setMainSaleStarted] = useState(false);
  const [mainSaleFinished, setMainSaleFinished] = useState(false);

  const [presaleStartTime, setPresaleStartTime] = useState(null);
  const [saleStartTime, setSaleStartTime] = useState(null);
  const [presaleEndTime, setPresaleEndTime] = useState(null);
  const [saleEndTime, setSaleEndTime] = useState(null);

  const [presaleTimeCounter, setPresaleTimeCounter] = useState(null);
  const [saleTimeCounter, setSaleTimeCounter] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [tokenContract, setTokenContract] = useState(null);
  const [saleContract, setSaleContract] = useState(null);

  const [txEtherScan, setTxEtherScan] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [approveInProgress, setApproveInProgress] = useState(false);
  const [checkoutIsPresale, setCheckoutIsPresale] = useState(true);
  const [isCreditCard, setIsCreditCard] = useState(false);

  const [maxTokenPerAddress, setMaxTokenPerAddress] = useState(10);
  const [maxDiscountMintable, setMaxDiscountMintable] = useState(10);
  const [maxMintableDuringMainSale, setMaxMintableDuringMainSale] = useState(10);
  const [userMaxDiscountMintable, setUserMaxDiscountMintable] = useState(0);
  const [availableTokens, setAvailableTokens] = useState(0);

  const [refreshInterval, setRefreshInterval] = useState(null);

  const [signatures, setSignatures] = useState(null);

  const [saleLabels, setSaleLabels] = useState({
    preSaleTitle: 'Pre-Sale',
    preSaleDescription: '',
    publicSaleTitle: 'Dust Sale',
    publicSaleDescription: '',
  });

  const [maxMintPerTransaction, setMaxMintPerTransaction] = useState(10);

  const [tokenSaleActive,setTokenSaleActive]= useState(false);
  const [ethSaleActive,setEthSaleActive]= useState(false);
  const [userTokenAddress,setUserTokenAddress]= useState(null);
  const [userToken,setUserToken]= useState(null);
  const [ERC677Symbol,setERC677Symbol]= useState(null);
  const [tokenIsERC777,setTokenIsERC777]= useState(false);
  
  const [buyWithToken,setBuyWithToken]= useState(false);

  const [userOnList,setUserOnList] = useState(false);

  const [saleInfoLoaded, setSaleInfoLoaded] = useState(false);

  const AbiCoder = new ethers.utils.AbiCoder();

  useEffect(() => {
   /*  if (whitelist) {
      
      axios
        .get(config.AWS_URL + whitelist+'?noCache='+new Date().getTime())
        .then((res) => {
         // console.log(res.status);
          console.log('whitelist from aws', res);
          if (res && res.status === 200) {
            setSignatures(res.data);
          } else {
            toast.error(res.message);
          }
        })
        .catch((e) => console.log);
    } else {

      setSignatures(null);
    } */
    
    if (config.DEPLOYED_CHAIN_ID === 1) {
			setSignatures(whitelist);
		} else {
			setSignatures(whitelistTest);
		}

    setSaleLabels(labels);
  }, []);

  useEffect(() => {
    (async () => {
      if (sessionStorage.getItem('selectedWallet')) {
        let t = Number(localStorage.getItem('activeTab'));
        if (t === 1) {
          setTab(1);
        } else {
       //   setTab(0);
        }
      }
    })();
  }, [onboard]);

  useInterval(
    () => {
      if (mainSaleFinished) {
        setRefreshInterval(null);
      } else {
        console.log('interval get sale');
        getSaleInfo();
      }
    },
    refreshInterval,
    false
  );



  useEffect(() => {
    if (address && signatures) {
      let userData = getUserParams();
      if (userData) {
        console.log('userData', userData);
        setUserOnList(true);
        setFreeWhitesaleMint(userData.params.free_mint);
        setWhiteListSaleEthPrice(ethers.BigNumber.from(userData.params.eth_price));
        setWhiteListSaleTokenPrice(ethers.BigNumber.from(userData.params.token_price));
      }else{
        setUserOnList(false);
      }
    }
  }, [address, signatures]);

  useEffect(() => {
    const initContracts = async () => {

      if (tokenContract == null || saleContract === null){
        //console.log('EP in contract init',provider);
        const provider = new providers.JsonRpcProvider(config.RPC_URL);
        let token = new Contract(tokenAddress, tokenABI.abi, provider);
        if (!token) {
          console.error('Token contract not found on address', tokenAddress);
          return;
        }

        let sale = new Contract(saleAddress, saleABI.abi, provider);
        if (!sale) {
          console.error('Sale contract not found on address', saleAddress);
        }

       // console.log('CONTRACTS INITIATED', token, sale);
        setTokenContract(token);
        setSaleContract(sale);
      }
    };
 
    if (saleAddress && tokenAddress) {
      //console.log('props changed',saleAddress,tokenAddress,ethersProvider)

      initContracts();
  
    }
  }, [saleAddress, tokenAddress, ethersProvider]);

  useEffect(() => {
    const getInfo = async () => {
      //console.log('getting sale info start');

      let ms = await tokenContract.maxSupply().catch((e) => console.log);

      if (ms) {
        console.log('MAX SUPPPPPLY', Number(ms));
        setTotalAmount(Number(ms));
      }

      await getSaleInfo();
      //console.log('getting sale info end');
    };

    if (tokenContract !== null && saleContract !== null/*  && signatures != null */) {
     //console.log('+++++++++++++++++++++get sale info in effect', tokenContract,saleContract);
      getInfo();
      setRefreshInterval(20000);
    }
  }, [tokenContract, saleContract, signatures]);


  useEffect(()=>{
    const initUserToken = async ()=>{

      let is777 = false;
      let erc1820 = new Contract(ERC1820_REGISTRY_ADDRESS,erc1820RegistryABI.abi,ethersProvider);

      if(erc1820){
        const res = await erc1820.getInterfaceImplementer(userTokenAddress,"0xac7fbab5f54a3ca8194167523c6753bfeb96a445279294b6125b68cce2177054").catch(e=>console.log);
        console.log(res);

        if(res!==ethers.constants.AddressZero){
          is777 = true;
        }
      }

      setTokenIsERC777(is777);

      let token = new Contract(userTokenAddress, is777?erc777TokenABI.abi:erc677TokenABI.abi, ethersProvider);

      if (!token) {
        console.error('Token contract not found on address', userTokenAddress);
        return;
      }else{
        console.log(`user token ${is777?'777':'677'}`,token);
        setUserToken(token);
        let symbol = await token.symbol().catch(e=>console.log);
        if(symbol){
          setERC677Symbol(symbol)
        }else{
          setERC677Symbol('N/A');
        }

        console.log('SYMBOL',symbol);
      }

    }


    if(userTokenAddress!==null){
      initUserToken();
    }
  },[userTokenAddress])


  const getUserParams = () => {
    let up = null;

    
    if (address && signatures) {
      
     // console.log('SIGNATURES-------',signatures,address, typeof signatures);
      
      const key = Object.keys(signatures).find(
        (key) => {return key.toLowerCase() == address.toLowerCase()}
      );

    //  console.log('USER KEY', key);
      if (key) {
        const userParams = signatures[key].params;

        up = {
          params: {
            free_mint:userParams[2],
            max_mint: userParams[3],
            receiver: userParams[4],
            valid_from: userParams[5],
            valid_to: userParams[6],
            eth_price: userParams[7],
            token_price: userParams[8],
          },
          raw_params: userParams,
          signature: signatures[key].signature,
        };
      }
    }
    return up;
  };

  const getSaleInfo = async () => {
   // console.log('saleInfo',saleContract);
   
    const info = await saleContract
      .tellEverything()
      .catch((e) => {console.log('tellEverything err:', e);return});

    console.log('****info', info)

    const totalSupply = await tokenContract.totalSupply();
    //console.log('TS',totalSupply);

    const presaleStart = Number(info.config.approvedsaleStart);
    const presaleEnd = Number(info.config.approvedsaleEnd);
    const saleStart = Number(info.config.saleStart);
    const saleEnd = Number(info.config.saleEnd);

    const fullPrice = info.config.fullPrice;


    const tokensLeft = info.config.maxUserMintable.sub(info.userMinted);

    setAvailableTokens(Number(tokensLeft));

    console.log ('availableTokens',Number(tokensLeft));

    setMaxDiscountMintable(Number(info.config.maxPresalePerAddress));
    setMaxTokenPerAddress(Number(info.config.maxSalePerAddress));
    setMaxMintPerTransaction(Number(info.config.maxMintPerTransaction));
    setMinted(Number(totalSupply));

    let now = Date.parse(new Date()) / 1000;

    let presaleIsOver = presaleEnd - now <= 0;
    let saleIsOver = saleEnd - now <= 0;
    let saleIsOn = now >= saleStart && !saleIsOver;
    let presaleIsOn = now >= presaleStart && !presaleIsOver;

    //	let _discountPrice = 0;
   

    if (presaleIsOn) {
      let userParams = getUserParams();

      console.log('User params',userParams)

      if (userParams) {
        setFreeWhitesaleMint(userParams.params.free_mint);
        setWhiteListSaleEthPrice(ethers.BigNumber.from(userParams.params.eth_price));
        setWhiteListSaleTokenPrice(ethers.BigNumber.from(userParams.params.token_price));
      }
    }

    setPublicSaleEthPrice(ethers.BigNumber.from(fullPrice));
    setPublicSaleTokenPrice(ethers.BigNumber.from(info.config.fullDustPrice));

    if(info.config.erc777SaleEnabled){
      setTokenSaleActive(true);
      if(userTokenAddress===null){
        setUserTokenAddress(info.config.erc777tokenAddress);
        //setERC677TokenPrice(info.config.fullDustPrice);
      }
    }

    setEthSaleActive(info.config.ethSaleEnabled);

    setPreSaleStarted(presaleIsOn);
    setPreSaleFinished(presaleIsOver);

    setMainSaleStarted(saleIsOn);
    setMainSaleFinished(saleIsOver);

    setPresaleStartTime(new Date(presaleStart * 1000));
    setPresaleEndTime(new Date(presaleEnd * 1000));
    setSaleStartTime(new Date(saleStart * 1000));
    setSaleEndTime(new Date(saleEnd * 1000));

    if (!presaleIsOn && !presaleIsOver) {
      setPresaleTimeCounter(new Date(presaleStart * 1000));
    } else {
      //console.log('presale over, or on');
      if (!presaleIsOver) {
        setPresaleTimeCounter(new Date(presaleEnd * 1000));
      }
    }

    if (!saleIsOn && !saleIsOver) {
      setSaleTimeCounter(new Date(saleStart * 1000));
    } else {
      //console.log('sale over, or on');
      if (!saleIsOver) {
        setSaleTimeCounter(new Date(saleEnd * 1000));
      }
    }
    setIsLoading(false);
    setSaleInfoLoaded(true);
  };

  const handleDiscountMint = async (withToken) => {


    let maxMintable = 0;

    //mintInfo =  await saleContract.checkDiscountAvailable(address);

    const userParams = getUserParams();

    if (!userParams) {
      setShowErrorPopup(true);
      return;
    }

    const alreadyMintedByWallet = await saleContract
      ._mintedByWallet(address)
      .catch((e) => console.log);

    //console.log('minted by wallet',alreadyMintedByWallet);

    if (alreadyMintedByWallet) {
      maxMintable = userParams.params.max_mint - Number(alreadyMintedByWallet);
    }

    //console.log('maxMintable', maxMintable);

    if (maxMintable < 1) {
      toast.error('You have already used up your whitelist mint quota.');
      return;
    }


    if(availableTokens<maxMintable){
      maxMintable=availableTokens;
    }

    setBuyWithToken(withToken?true:false);
   // console.log('TOKEN PRICE', ERC677TokenPrice, Number(ERC677TokenPrice));

    setUserMaxDiscountMintable(maxMintable);
    setCheckoutIsPresale(true);
    setIsCreditCard(false);
    setShowCheckout(true);

  };

  const handleMint = async (withToken) => {

    setApproveInProgress(true);
    //console.log('!!!!!!!max tokens per adddres', maxTokenPerAddress);
    const alreadyMintedByWallet = await saleContract
      ._mintedByWallet(address)
      .catch((e) => console.log);

      
      console.log('max,already',maxTokenPerAddress,alreadyMintedByWallet)

    if (alreadyMintedByWallet) {
      let maxMintableMainSale = availableTokens;// maxTokenPerAddress - Number(alreadyMintedByWallet);

      if (maxMintableMainSale > 0) {
        setMaxMintableDuringMainSale(
          maxMintableMainSale < maxMintPerTransaction
            ? maxMintableMainSale
            : maxMintPerTransaction
        );


        setBuyWithToken(withToken?true:false);

        setCheckoutIsPresale(false);
        setIsCreditCard(false);
        setApproveInProgress(false);
        setShowCheckout(true);
      } else {
        setApproveInProgress(false);
        toast.error('You have alredy used up your quota.');
      }
    } else {
      setApproveInProgress(false);
      console.log("can't get already minted tokens");
    }
  };

  const mintDisco = async (amount, withToken) => {

    setShowCheckout(false);
    setApproveInProgress(true);
    let userParams = getUserParams();
    if (!userParams) {
      return;
    }

    let tx = null;

    if(withToken){
      console.log('with token');

      console.log('user params',userParams);

      let token = userToken.connect(ethersProvider.getSigner());
      if(token){


        let payload = await encodeUserDataForTokenTransfer([BUY_TYPE_APSALE, amount, [...userParams.raw_params, userParams.signature] ]);

        if(tokenIsERC777){
      
          tx = await token.send(
            saleAddress, 
            whiteListSaleTokenPrice.mul(amount),//default_sale_tokenPrice,
            payload,
            // {gasLimit: maxBlockGas}
          ).catch(handleError);
        }else{
          tx = await token.transferAndCall(
            saleAddress, 
            whiteListSaleTokenPrice.mul(amount),//default_sale_tokenPrice,
            payload,
            // {gasLimit: maxBlockGas}
          ).catch(handleError);
        }
        setApproveInProgress(false);
      }

    }else{
      let sc = saleContract.connect(ethersProvider.getSigner());
      //console.log('user PARAMS', userParams);
      
      tx = await sc
        .mint_approved([...userParams.raw_params, userParams.signature], amount, {
          value: whiteListSaleEthPrice.mul(amount),
        })
        .catch(handleError);

      setApproveInProgress(false);
    }

    if (tx) {
      setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
      setTxInProgress(true);
      await tx.wait().catch((e) => {
        handleError(e);
        setTxInProgress(false);
      });
      setTxInProgress(false);
      getSaleInfo();
      setTab(1); //-> wallet
      localStorage.setItem('activeTab', 1);
    }
  };


  const encodeUserDataForTokenTransfer = async (params) => {
    //console.log('encode params',params);
      const encoded =  AbiCoder.encode(
          [
              "uint256",
              "uint256",
              "tuple(uint256,uint256,bool,uint16,address,uint256,uint256,uint256,uint256,bytes)"
          ],
          params
      );

      return encoded;
  }


  const handleCreditCard = async () => {
    console.log('CC');
    setMaxMintableDuringMainSale(5);
    setCheckoutIsPresale(false);
    setApproveInProgress(false);
    setIsCreditCard(true);
    setShowCheckout(true);
  };

  const mintRegular = async (amount, withToken) => {
   // console.log(amount * price);
    let tx = null
    if(withToken){
      let token = userToken.connect(ethersProvider.getSigner());
      if(token){

        /* 
        projectID:  _projectID,
        chainID:    _chainID,
        free:       _free, 
        max_mint:   _max_mint,
        receiver:   _receiver,
        valid_from: _valid_from,
        valid_to:   _valid_to,
        eth_price:  _eth_price,
        dust_price: _dust_price
        */

        let params =[0,0,false,0,ethers.constants.AddressZero,0,0,0,0,0];
        let payload = await encodeUserDataForTokenTransfer([BUY_TYPE_SALE, amount, params ]);
        //console.log('PAYLOAD',payload);
        setShowCheckout(false);
        setApproveInProgress(true);

        if(tokenIsERC777){
          tx = await token.send(
            saleAddress, 
            publicSaleTokenPrice.mul(amount),//default_sale_tokenPrice,
            payload,
            // {gasLimit: maxBlockGas}
          ).catch(handleError);
        }else{
          tx = await token.transferAndCall(
            saleAddress, 
            publicSaleTokenPrice.mul(amount),//default_sale_tokenPrice,
            payload,
            // {gasLimit: maxBlockGas}
          ).catch(handleError);
        }



        //console.log('TX',tx);
      }
    }else{
      let sc = saleContract.connect(ethersProvider.getSigner());
      setShowCheckout(false);
      setApproveInProgress(true);
      tx = await sc
        .mint(amount, { value: publicSaleEthPrice.mul(amount) })
        .catch(handleError);
    }
    setApproveInProgress(false);

    if (tx) {
      setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
      setTxInProgress(true);
      await tx.wait().catch((e) => {
        handleError(e);
        setTxInProgress(false);
      });
      setTxInProgress(false);
      getSaleInfo();
      setTab(1); //-> wallet
      localStorage.setItem('activeTab', 1);
    }
  };

  const handleError = (e) => {
    console.error('handle error',e);
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };

  

  const handleSwitchChain = async ()  => {
    if (chainId !== config.DEPLOYED_CHAIN_ID) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: '0x'+config.DEPLOYED_CHAIN_ID,
          },
        ],
      });
    } 
  };

  return (
    <Box sx={sx.saleRoot}>
     
      {/* <Box sx={sx.infoContainer}>
        <Box
          sx={sx.badgeContainer}
        >
          {minted > 0 && (
            <InfoBox
              label="SOLD"
              value={minted}
              sx={sx.badgeLeft}
            />
          )}
          <InfoBox
            label="PRICE"
            value="6000"
            dust
            sx={sx.badgeRight}
          />
        </Box>
      </Box> */}

      {/* <ButtonGroup
				sx={sx.tabContainer}
				variant="outlined"
				size="medium"
				aria-label="medium outlined button group"
			>
				<Button
					sx={{
						...sx.tabBtns,
						...(tab === 0 && sx.tabSelected),
					}}
					onClick={() => { setTab(0); localStorage.setItem('activeTab', 0); }}
				>
					SALE INFO
				</Button>
				<Button
					sx={{ ...sx.tabBtns, ...(tab === 1 && sx.tabSelected) }}
					onClick={() => { setTab(1); localStorage.setItem('activeTab', 1); }}
				>
					COLLECTION WALLET
				</Button>
			</ButtonGroup> */}



      {tab === 0 ? (
        <>
          {postponedSale?<>
            <PostponePanel/>
          </>

          :

          <>
            {/* PRESALE */}

            {saleInfoLoaded?
            <>

            {(!preSaleFinished && availableTokens>0) && (
              <>

              <DecoratedTitle>
                {saleLabels.preSaleTitle + ' ' + ((!preSaleStarted && !preSaleFinished) ? 'is Coming Soon' : preSaleStarted ? 'is Live' : preSaleFinished ? 'Ended' : '')}
              </DecoratedTitle>




            {/*     <Typography align="center" sx={sx.title}>
                  {saleLabels.preSaleTitle}{' '}
                  {!preSaleStarted && !preSaleFinished && (
                    <>
                      <Box component="span">is Coming Soon</Box>
                      {!address && (
                        <Typography
                          sx={sx.description}
                          style={{ marginTop: 0, marginBottom:'2rem' }}
                        ></Typography>
                      )}
                    </>
                  )}
                  {preSaleStarted && (
                    <>
                      <Box
                        sx={sx.titleLive}
                        style={{ color:'#fff' }}
                        component="span"
                      >
                        is Live
                      </Box>
                    </>
                  )}
                  {preSaleFinished && <Box component="span">Ended</Box>}
                </Typography>
 */}


                {preSaleStarted && (
                  <p style={{ color:'#fff'}}>
                    It ends in
                  </p>
                )}

                {!preSaleStarted && !preSaleFinished && (
                  <Typography
                    sx={sx.description}
                    style={{ marginTop: '15px', marginBottom: '1rem' }}
                  >
                    It starts in
                  </Typography>
                )}

                <Counter
                  date={presaleTimeCounter}
                  isActive={preSaleStarted}
                  onFinish={() => {
                    console.log('presale timer is up');
                    getSaleInfo();
                  }}
                  id="presale"
                />

                <Box sx={sx.timeContainer}>
                  <Timer
                    time={
                      <>
                        {presaleStartTime
                          ? new DateTime.fromMillis(presaleStartTime.getTime())
                              .setZone('America/New_York')
                              .toFormat('dd. MM. hha') + ' EDT'
                          : ''}{' '}
                        -{' '}
                        {presaleEndTime
                          ? new DateTime.fromMillis(presaleEndTime.getTime())
                              .setZone('America/New_York')
                              .toFormat('dd. MM. hha') + ' EDT'
                          : ''}
                      </>
                    }
                  />
                </Box>
                <p style={{ marginTop: '15px', color: '#fff', marginBottom:'2rem' }}>
                  {saleLabels.preSaleDescription}
                </p>

                {preSaleStarted && (

                  <Box mt={2} mb={5}>
                  {(tokenSaleActive)&& <Button
                      sx={sx.buyButton}
                      style={{ margin: '10px', opacity:userOnList?'1':'0.5'}}
                      variant="banner"
                      onClick={(e)=>handleDiscountMint(true)}
                      disabled={!address || isLoading || !availableTokens}
                    >
                      BUY WITH {ERC677Symbol}
                    </Button>}

               {/*      {(tokenSaleActive && !whiteListSaleTokenPrice.eq(0))&& <Button
                      sx={sx.buyButton}
                      style={{ margin: '10px'}}
                      variant="banner"
                      onClick={(e)=>handleDiscountMint(true)}
                      disabled={!address || isLoading}
                    >
                      BUY WITH {ERC677Symbol}
                    </Button>}
 */}

                    {!address && (
                      <Button
                        variant="banner"
                        sx={sx.buyButton}
                        style={{ margin: '10px' }}
                        onClick={handleConnect}
                      >
                        CONNECT WALLET
                      </Button>
                    )}
                  </Box>
                )}
              </>
            )}

            {/* SALE */}
            {(!mainSaleFinished && availableTokens>0) && (
              <>
              <DecoratedTitle>
                {saleLabels.publicSaleTitle + ' ' + ((!mainSaleStarted && !mainSaleFinished) ? 'is Coming Soon' : mainSaleStarted ? 'is Live' : mainSaleFinished ? 'Ended' : '')}
              </DecoratedTitle>

            {/*   {!mainSaleStarted && (
                  <p style={{ color: '#fff' }}>
                    It starts in
                  </p>
                )} */}

                {/* <Typography align="center" mt={0} sx={sx.title}>
                  {saleLabels.publicSaleTitle}{' '}
                  {!mainSaleStarted && !mainSaleFinished && (
                    <Box component="span">Is Coming Soon</Box>
                  )}
                  {mainSaleStarted && (
                    <Box
                      sx={sx.titleLive}
                      style={{ color: '#fff' }}
                      component="span"
                    >
                      Is Live
                    </Box>
                  )}
                  {mainSaleFinished && <Box component="span">Ended</Box>}
                </Typography> */}

                {mainSaleStarted && (
                  <p style={{ color: '#fff' }}>
                    It ends in
                  </p>
                )}

                {preSaleFinished && (
                  <Counter
                    date={saleTimeCounter}
                    isActive={mainSaleStarted}
                    onFinish={() => {
                      console.log('sale timer is up');
                      getSaleInfo();
                    }}
                    id="sale"
                  />
                )}

                {!mainSaleStarted && !mainSaleFinished && (
                  <Typography
                    sx={sx.description}
                    style={{ marginTop: '15px', marginBottom: '1rem' }}
                  >
                    It starts at
                  </Typography>
                )}

                <Box
                  sx={sx.timeContainer}
                  style={{
                    border: '2px solid #fff'
                  }}
                >
                  <Timer
                    time={
                      <>
                        {saleStartTime
                          ? new DateTime.fromMillis(saleStartTime.getTime())
                              .setZone('America/New_York')
                              .toFormat('dd. MM. hha') + ' EDT'
                          : ''}{' '}
                        -{' '}
                        {saleEndTime
                          ? new DateTime.fromMillis(saleEndTime.getTime())
                              .setZone('America/New_York')
                              .toFormat('dd. MM. hha') + ' EDT'
                          : ''}
                      </>
                    }
                  />
                </Box>
                <p style={{ marginTop: '15px', color: '#fff' }}>
                  {saleLabels.publicSaleDescription}
                </p>

                {mainSaleStarted && (
                  <Box mt={2}>
                    {(ethSaleActive && !publicSaleEthPrice.eq(0)) && (
                      <Button
                        sx={sx.buyButton}
                        style={{ margin: '10px 10px 30px 10px' }}
                        variant="banner"
                        onClick={(e)=>handleMint(false)}
                        disabled={!address || isLoading  || !availableTokens}
                      >
                        BUY NFT
                      </Button>
                    )}

                    {(tokenSaleActive && !publicSaleTokenPrice.eq(0) && chainId===config.DEPLOYED_CHAIN_ID) && <Button
                      sx={sx.buyButton}
                      style={{ margin: '10px 10px 30px 10px'}}
                      variant="banner"
                      onClick={(e)=>handleMint(true)}
                      disabled={!address || isLoading || !availableTokens}
                    >
                      BUY WITH {ERC677Symbol}
                    </Button>}

                    {chainId!==config.DEPLOYED_CHAIN_ID && <Button
                      sx={sx.buyButton}
                      style={{ margin: '10px 10px 30px 10px'}}
                      variant="banner"
                      onClick={(e)=>handleSwitchChain(true)}
                      disabled={!address || isLoading}
                    >
                      Switch chain
                    </Button>}



                    {!address && (
                      <Button
                        sx={sx.buyButton}
                        variant="banner"
                        style={{ margin: '10px 10px 30px 10px' }}
                        onClick={handleConnect}
                      >
                        CONNECT WALLET
                      </Button>
                    )}
                    {/* <Button
                  sx={sx.buyButton}
                  variant="banner"
                  style={{ margin: "10px 10px 30px 10px" }}
                  onClick={handleCreditCard}
                >
                  PAY WITH CREDIT CARD
                </Button> */}
                  </Box>
                )}
              </>
            )}

            {preSaleFinished && mainSaleFinished && (
              <>
                {/* <Typography align="center" sx={sx.title}>
                  <Box
                    sx={sx.titleLive}
                    component="span"
                    style={{ color: '#fff' }}
                  >
                    The sale is over
                  </Box>
                </Typography> */}

                <DecoratedTitle>
                  The sale is over
                </DecoratedTitle>

                {openSeaName && <Typography
                  sx={sx.description}
                  style={{ marginTop: '3rem', marginBottom: '3rem' }}
                >
                  Missed our NFT sale? Check out the Collection on{' '}
                  <Box
                    component="a"
                    href={
                      `${config.OPENSEA_URL}collection/${openSeaName}`
                    }
                    sx={sx.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OpenSea
                  </Box>
                </Typography>}
              </>
            )}


            {((!preSaleFinished || !mainSaleFinished) && !availableTokens)&& 
            
              <DecoratedTitle>
                  Sold out
                </DecoratedTitle>
            
            
            }
            </>
            :
            <>
            <Box sx={{ textAlign: 'center', marginTop:'3rem' }}>
              <SpinnerCircularFixed color="#FF692B" />
            </Box>
            </>
            
            }

          </>}

        </>
      ) : (
        <>
          <Wallet tokenContract={tokenContract} />
        </>
      )}

      <PermissionErrorModal
        isOpen={showErrorPopup}
        setOpen={setShowErrorPopup}
      />

      <CheckoutModal
        tokenName={symbol}
        isOpen={showCheckout}
        setOpen={() => {
          if (!txInProgress && !approveInProgress) {
            setShowCheckout(false);
          }
        }}
        isPresale={checkoutIsPresale}
        withCreditCard={isCreditCard}
        whitelistLimit={
          checkoutIsPresale
            ? userMaxDiscountMintable
            : maxMintableDuringMainSale
        }
        salePrice={ethers.utils.formatEther(publicSaleEthPrice)}
        presalePrice={ethers.utils.formatEther(whiteListSaleEthPrice)}
        tokenPrice={ethers.utils.formatEther(publicSaleTokenPrice)}
        presaleTokenPrice={ethers.utils.formatEther(whiteListSaleTokenPrice)}
        withToken = {buyWithToken}
        erc677Symbol = {ERC677Symbol}
        mintSale={mintRegular}
        mintPresale={mintDisco}
      />
      <BackdropModal isOpen={approveInProgress} />

      <TxProgressModal isOpen={txInProgress} txEtherScan={txEtherScan} />
    </Box>
  );
};
export default SalesV3;
