import React from 'react';

import { Box, Modal } from '@mui/material';

import close from '../assets/images/white_close.svg';

const BP1 = '@media (max-width: 899px)';
const BP2 = '@media (max-width: 719px)';

const sx = {
  root: {
    height: '100%',
    backgroundColor: '#152d2ebf',
    px: '125px',
    position: 'relative',
    pointerEvents: 'none',
    transition: 'all .3s',
    [BP1]: {
      px: '85px',
    },
    [BP2]: {
      px: '25px',
    },
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  closeBtn: {
    position: 'absolute',
    width: '32px',
    min: '32px',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  imageContainer: {
    width: '100%',
    maxWidth: '440px',
    margin: 'auto',
    display: 'flex',
  },
};

const FullscreenModal = ({ isOpen, setOpen, imgSrc }) => {
  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        // if (reason !== "backdropClick") {
        //   setOpen(false);
        // }
        setOpen(false);
      }}
    >
      <Box
        sx={sx.root}
        style={{
          display: 'flex',
          jusifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={sx.imageContainer}>
          {imgSrc && (
            <img
              src={imgSrc}
              style={{ width: '100%', borderRadius: '15px' }}
              alt="image"
            />
          )}
        </Box>
        <Box sx={sx.closeBtn} onClick={() => setOpen(false)}>
          <img src={close} style={{ width: '100%' }} alt="Close" />
        </Box>
      </Box>
    </Modal>
  );
};

export default FullscreenModal;
