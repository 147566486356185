import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  useZoom2Contract,
  useZoom2PolygonContract,
} from '../../../hooks/useContract';
import { getTokenIds } from '../../../utils/zoom2';
import {
  dustContract,
  getAllDustAvailable,
} from '../../ExplorerCard/Components/Dust/getDust';
import DustOnEther from './DustOnEther';
import { SpinnerCircularFixed as Spinner } from 'spinners-react';
import { toast } from 'react-toast';
import config from '../../../config/config';
import { abi as ZOOM2_ABI } from '../../../Components/SaleComponent/abi/Zoom2.json';
import { Contract, ethers, providers } from 'ethers';
import { useContext } from 'react';
import Web3Ctx from '../../../context/Web3Ctx';
import { ZOOM_2_ADDRESSES } from '../../../Components/SaleComponent/abi/constants/addresses';
import { SpinnerRound } from 'spinners-react';

const sx = {
  root: {
    my: '50px',
  },
  body: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  button: {
    mt: '40px',
  },
};

const DustSection = ({ ethTokenContract, polygonTokenContract }) => {
  const { onboard, address, ethersProvider, chainId } = useContext(Web3Ctx);
  const [loading, setLoading] = useState(false);
  const [totalDustAmount, setTotalDustAmount] = useState(0);
  const [ethereumDustAmount, setEthereumDustAmount] = useState(0);
  const [polygonDustAmount, setPolygonDustAmount] = useState(0);
  const [usedZoomContract, setUsedZoomContract] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txCompleted, setTxCompleted] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(null);
  const [tokenIds, setTokenIds] = useState(null);
  const [disableButton, setdisableButton] = useState(false);
  const mainnetZoomContract = useZoom2Contract();
  const polygonZoomcontract = useZoom2PolygonContract();

  
  useEffect(() => {
    if ((address && usedZoomContract !== null) || txCompleted) {
      getTokens(ethTokenContract, polygonTokenContract);
      if (txCompleted) {
        setTxCompleted(false);
      }
    }
  }, [address, usedZoomContract, txCompleted]);


  useEffect(() => {
    if (chainId === 137 || chainId === 80001 ) {
      const provider = new providers.JsonRpcProvider(config.RPC_URL);
      const zoomContract = new Contract(
        ZOOM_2_ADDRESSES['1'],
        ZOOM2_ABI,
        provider
      );
      setUsedZoomContract(zoomContract);
    } else {
      setUsedZoomContract(mainnetZoomContract);
    }
  }, [chainId]);

  // console.log(onboard);
  const getTokens = async (tokenContract, polygonContract) => {
    console.log('HEEEEEEEEEE')
    setLoading(true);
    const etherIds = await getTokenIds(
      tokenContract,
      usedZoomContract,
      address
    );
    const polygonIds = await getTokenIds(
      polygonContract,
      polygonZoomcontract,
      address
    );
    if (
      etherIds.length > 0 ||
      polygonIds.length > 0
      //  metas2.length > 0
    ) {
      setTokenIds([...etherIds, ...polygonIds]);
      var etherwalletAmount = 0;
      var polygonWalletAmount = 0;
      etherwalletAmount = await getAllDustAvailable(etherIds);
      polygonWalletAmount = await getAllDustAvailable(polygonIds);
      const total = await getAllDustAvailable([...etherIds, ...polygonIds]);
      // console.log(polygonWalletAmount);
      setEthereumDustAmount(etherwalletAmount.replace(/,/g, ''));
      setPolygonDustAmount(polygonWalletAmount.replace(/,/g, ''));
      setTotalDustAmount(total.replace(/,/g, ''));
      if( parseFloat(polygonWalletAmount) <= 0 ){
        setdisableButton(true)
      }
    }
    //  else {
    //   setwalletDustAmount(0)
    // }
    setLoading(false);
  };

  const checkChainId = () => {
    if (chainId !== 137 || chainId !== 80001) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: '0x89',
          },
        ],
      });
    } else return;
  };
  const withdrawDust = async () => {
    // console.log("HEELLLOOOOOOO")
    setShowLoader(true);

    const contract = dustContract();

    let signer = ethersProvider.getSigner(address);

    const claimContract = contract.connect(signer);
    // console.log(signer);
    // console.log(claimContract);
    // console.log('claiming dust');

    // if (showLoader || txInProgress || !claimContract) return;

    await withdrawNow(claimContract, tokenIds);

    
  };

  const withdrawNow = async (claimContract, tokenIds) => {
    return claimContract
      .redeem(tokenIds)
      .then((tx) => {
        console.log(tx);
        setTxEtherScan(`${config.ETHERSCAN_URL}tx/` + tx.hash);
        setTxInProgress(true);

        return tx.wait().then(async (receipt) => {
          console.log('txReceipt: ', receipt);
          if (receipt && receipt.status === 1) {
            toast.success('Successfully claimed Dust');
            setTxEtherScan(null);
            setTxCompleted(true);
            setShowLoader(false);
          } else {
            toast.error('Transaction Failed');
            setTxEtherScan(null);
            setTxInProgress(false);
            setShowLoader(false);
          }
        });
      })
      .catch(handleError);
  };

  const handleError = (e) => {
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };
  return (
    <Box sx={sx.root}>
      {loading ? (
        <Spinner
          color='#FF692B'
          size={64}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        />
      ) : (
        <>
          <Box sx={sx.body}>
            <DustOnEther
              amount={ethereumDustAmount}
              text={' TOTAL DUST on  Ethereum chain'}
            />
            <DustOnEther
              amount={polygonDustAmount}
              text={' TOTAL DUST on Polygon chain'}
            />
            <DustOnEther
              amount={totalDustAmount}
              text={' TOTAL DUST in your wallet'}
            />
          </Box>
          {(chainId === 137 || chainId === 80001) ? (
            <Button
              variant='contained'
              onClick={withdrawDust}
              sx={sx.button}
              disabled={showLoader || disableButton}
            >
              {showLoader ? <SpinnerRound color='#FFF' /> : 'Claim Your Dust'}{' '}
            </Button>
          ) : (
            <Button
              variant='contained'
              onClick={checkChainId}
              sx={sx.button}
              disabled={disableButton}
            >
              Switch chain{' '}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};
export default DustSection;
