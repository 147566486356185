import { Box } from '@mui/system';
import React, { Suspense, useEffect, useState } from 'react';
import trait_card from '../../assets/images/trait_card.png';
import autograph from '../../assets/images/traits/autograph.svg';
import physicalRedeem from '../../assets/images/traits/physical-redeem.svg';
import onlineMeeting from '../../assets/images/traits/online-meeting.svg';
import './Card.css';
import perfectBadge from '../../assets/images/perfect-badge.png';
import dragonMedal from '../../assets/images/dragon-medal.png';
import girlMedal from '../../assets/images/girl-medal.png';
import robotMedal from '../../assets/images/robot-medal.png';
import { Typography } from '@mui/material';
import badgeCard from '../../assets/images/badge-background.jpg';
import flip_icon from '../../assets/images/flip-icon.svg';
import CardBack from './CardBack';
import ARTARRAY from '../../assets/images/arts/art.js';
import fullScreen from '../../assets/images/fullscreen-icon.svg';
import FullscreenModal from '../../Components/FullscreenModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TraitCard from './TraitCard.jsx'

const BP1 = '@media (max-width: 450px)';

const sx = {
  root: {
    width: '100%',
    margin: 'auto',
  },
  imageContainer: {
    position: 'relative',
    maxWidth: '300px',
    minHeight: '500px',
    margin: 'auto',
    [BP1]: {
      minHeight: '300px',
      mb: '50px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    border: '5px solid #FC6405',
    borderRadius: '28px',
  },
  traitContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    top: '10px',
    right: '10px',
  },
  traitHolder: {
    width: '35px',
    height: '35px',
    borderRadius: '8px',
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FC6405',
    },
    '& img': {
      width: '25px ',
      height: '15px ',
      transition: '0',
      objectFit: 'contain !important',
      borderRadius: '0px !important',
      position: 'inherit !important',
    },
    [BP1]: {
      width: '35px',
      height: '35px',
      '& img': {
        width: '25px !important',
        height: '20px !important',
        transition: '0',
        objectFit: 'contain !important',
        borderRadius: '0px !important',
        position: 'inherit !important',
      },
    },
  },
  medalText: {
    color: '#FFF',
    fontFamily: 'boucherie-block',
    fontSize: '20px',
  },
  iconsHolder: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: '100%',
    maxHeight: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  centerIconHolder: {
    width: 'auto',
    height: 'auto',
    backgroundColor: 'black',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#FC6405',
    },
  },
};
const TRAITS = [
  {
    icon: physicalRedeem,
    title: 'PHYSICAL REDEEM',
    description:
      'Redeem an exclusive, oil-on-canvas painting created by the artists.',
    type: 'physical',
  },
  {
    icon: onlineMeeting,
    title: 'ONLINE MEETING',
    description:
      'Book a 1 hour online meeting with the artists, Zoltan Boros and Gabor Szikszai!',
    type: 'meeting',
  },
  {
    icon: autograph,
    title: 'AUTOGRAPH',
    description:
      'Receive a customized, personalized autograph that will appear on your card.',
    type: 'autograph',
  },
  {
    icon: girlMedal,
    type: 'medal',
  },
  {
    icon: robotMedal,
    type: 'medal',
  },
  {
    icon: dragonMedal,
    type: 'medal',
  },
  {
    icon: badgeCard,
    title: 'Perfect',
    description:
      'This badge appears on artistically ‘perfect’ cards. The badge is always listed among the NFT’s characteristics, and it enables data filtering on marketplaces.',
    type: 'badge',
  },
];

const Card = () => {
  const [traitsVisible, setTraitsVisible] = useState(false);
  const [selectedTrait, setSelectedTrait] = useState(TRAITS[0]);
  const [showBackCard, setshowBackCard] = useState(false);
  const [showFlipIcon, setshowFlipIcon] = useState(false);
  const [randomImages, setrandomImages] = useState([ARTARRAY[0]]);
  const [open, setOpen] = useState(false);
  const [fullscreenSrc, setFullscreenSrc] = useState(null);
  const [mobileView, setmobileView] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);

  const showTraits = () => {
    setTraitsVisible(true);
    document.getElementById('scope').style.transform =
      'perspective(1000px) rotateY(180deg)';
    // document.getElementById("scope").style.transition = "all .35s ease-in-out";
  };
  const hideTraits = () => {
    setTraitsVisible(false);
    setshowBackCard(false);
    document.getElementById('scope').style.transform =
      'perspective(1000px) rotateY(0deg)';
  };

  const randomImage = () => {
    let randomNumber = Math.floor(Math.random() * 10);
    setrandomImages(ARTARRAY[randomNumber]);
  };
  const setFullscreenSource = (src) => {
    setFullscreenSrc(src);
    setOpen(true);
  };
  useEffect(() => {
    if (window.innerWidth < 900) {
      setmobileView(true);
    }
  }, []);
  return (
    <Box>
      <Box sx={sx.imageContainer}>
        <div
          className="pyramid_anim_container"
          onMouseOver={() => {
            setMouseEnter(true);
          }}
          onMouseLeave={() => {
            setMouseEnter(false);
          }}
        >
          <div
            className={`scope ${traitsVisible ? 'active' : ''}  `}
            id="scope"
            onMouseOver={() => {
              setshowFlipIcon(true);
            }}
            onMouseLeave={() => {
              setshowFlipIcon(false);
            }}
          >
            <span className={`front ${!traitsVisible ? 'active' : ''} `}>
              <LazyLoadImage
                className="flipped-img"
                src={trait_card}
                alt="not found"
              />
              <Box sx={sx.iconsHolder}>
                <Box
                  sx={sx.centerIconHolder}
                  style={{ marginRight: '10px' }}
                  className={!mouseEnter ? 'trait5' : ''}
                >
                  <img
                    src={fullScreen}
                    alt=""
                    className={
                      !mouseEnter
                        ? 'fullscreen-icon mid-icon1'
                        : 'fullscreen-icon'
                    }
                    onClick={() => {
                      setFullscreenSource(randomImage);
                    }}
                  />
                </Box>
                <Box
                  sx={sx.centerIconHolder}
                  className={!mouseEnter ? 'trait6' : ''}
                >
                  <img
                    src={flip_icon}
                    className={
                      !mouseEnter ? 'flip-icon mid-icon2' : 'flip-icon'
                    }
                    alt="not found"
                    onClick={() => {
                      randomImage();
                      showTraits();
                      setshowBackCard(true);
                    }}
                  />
                </Box>
              </Box>

              <img
                className="badge-img"
                src={perfectBadge}
                alt="not found"
                onClick={() => {
                  showTraits();
                  setSelectedTrait(TRAITS[6]);
                }}
              />
              {
                <Box
                  sx={sx.traitContainer}
                  className={traitsVisible ? 'hide' : ''}
                >
                  <Box
                    sx={sx.traitHolder}
                    className={!mouseEnter ? 'trait1' : ''}
                    onClick={() => {
                      showTraits();
                      setSelectedTrait(TRAITS[0]);
                    }}
                  >
                    {' '}
                    <img
                      src={physicalRedeem}
                      alt=""
                      style={{ height: '20px' }}
                    />{' '}
                  </Box>
                  <Box
                    sx={sx.traitHolder}
                    className={!mouseEnter ? 'trait2' : ''}
                    onClick={() => {
                      showTraits();
                      setSelectedTrait(TRAITS[1]);
                    }}
                  >
                    {' '}
                    <img src={onlineMeeting} alt="" />
                  </Box>
                  <Box
                    sx={sx.traitHolder}
                    className={!mouseEnter ? 'trait3' : ''}
                    onClick={() => {
                      showTraits();
                      setSelectedTrait(TRAITS[2]);
                    }}
                  >
                    {' '}
                    <img src={autograph} alt="" style={{ height: '25px' }} />
                  </Box>
                  {/* <Box sx={sx.traitHolder}
                                    onClick={() => {
                                        showTraits()
                                        setSelectedTrait(TRAITS[3])
                                    }}>
                                    {' '}
                                   <Typography sx={sx.medalText}>G</Typography>
                                </Box> */}
                  {/* <Box sx={sx.traitHolder}
                                    onClick={() => {
                                        showTraits()
                                        setSelectedTrait(TRAITS[4])
                                    }}>
                                    {' '}
                                    <Typography sx={sx.medalText}>R</Typography>
                                </Box> */}
                  <Box
                    sx={sx.traitHolder}
                    className={!mouseEnter ? 'trait4' : ''}
                    onClick={() => {
                      showTraits();
                      setSelectedTrait(TRAITS[5]);
                    }}
                  >
                    {' '}
                    <Typography sx={sx.medalText}>D</Typography>
                  </Box>
                </Box>
              }
            </span>
            <span className={`back ${traitsVisible ? 'active' : ''} `}>
              <Suspense fallback={<div>Loading...</div>}>
                <TraitCard trait={selectedTrait} onClick={hideTraits} />
              </Suspense>
            </span>
            <span className={`back_card ${showBackCard ? 'active' : ''} `}>
              <CardBack onClick={hideTraits} imageArray={randomImages} />
            </span>
          </div>
        </div>
      </Box>
      <FullscreenModal setOpen={setOpen} isOpen={open} imgSrc={trait_card} />
    </Box>
  );
};

export default Card;
