import { Contract, ethers, providers } from 'ethers';
import config from '../../../../config/config';
import dustAbi from './dust_abi.json'

const contractAddress = config.POLYGON_ID==='137'?"0x43ABfA71027E78d7af08E8483c0A9c4EE6b5b273":"0x26AF8a1438308c52eC02b52feeFfF9236248913a"



export const getDust = async (tokenId) => {
  let dustAmount = ethers.utils.commify(0);
  const contract = dustContract();
  // console.log(contract)
  const res = await contract.functions["getAvailableBalance(uint16[])"]([tokenId]).catch(e=>{console.log('error',e)})

   console.log('BALANCE RES',res);

  if(res){
    dustAmount = (ethers.utils.commify(Number(ethers.utils.formatEther(res[0]._hex, 18))));
  }


  return dustAmount;

}


export const getAllDustAvailable = async (arrayOfTokenIds) => {
  let dustAmount = ethers.utils.commify(0);
  const contract = dustContract();
  // console.log(contract)
  const res = await contract.functions["getAvailableBalance(uint16[])"](arrayOfTokenIds).catch(e=>console.log('getAllDustAvailable error',e))

  // console.log(res)

  if(res){
    dustAmount = (ethers.utils.commify(Number(ethers.utils.formatEther(res[0]._hex, 18))));
  }

  return dustAmount;

}

export const dustContract = () => {
  const provider = new providers.JsonRpcProvider(config.POLYGON_RPC);

  // console.log(provider)
  const contract = new Contract(contractAddress, dustAbi.abi, provider)

  return contract
}