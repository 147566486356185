import { Box, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ExplorerCard from '../../../View/ExplorerCard/Components/ExplorerCard';

const sx = {
  nftGallery: {
    maxWidth: '1140px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '15px',
    flexWrap: 'wrap',
    mx: 'auto',
  },
  title: {
    fontFamily: 'boucherie-block',
    fontSize: '16px',
    color: '#D2D4C2',
    marginTop: '6px',
  },
};

const NftGallery = ({
  tokenMetas,
  traitTypes,
  etherscanLink,
  scrollTo,
  detailsDisabled,
  style,
  handleClick,
}) => {
  const [mdGrid, setMdGrid] = useState(4);
  const [xsGrid, setXsGrid] = useState(12);
  const [smGrid, setSmGrid] = useState(6);
  useEffect(() => {
    if (window.innerWidth < 1120) {
      setMdGrid(4);
    }
    if (window.innerWidth < 890) {
      setSmGrid(6);
    }
    if (window.innerWidth < 760) {
      setSmGrid(6);
    }
    if (window.innerWidth < 600) {
      setXsGrid(6);
    }
    if (window.innerWidth < 450) {
      setXsGrid(12);
    }
  }, []);

  return (
    <>
      {tokenMetas.length > 0 ? (
        <Box sx={sx.nftGallery} style={style}>
          <Grid container spacing={1.5}>
            {tokenMetas.map((meta) => (
              <Grid
                key={meta.id}
                item
                xs={xsGrid}
                sm={smGrid}
                md={mdGrid}
                style={{ minHeight: '600px', marginBottom: '2.5rem' }}
              >
                <ExplorerCard
                  metadata={meta}
                  traitTypes={traitTypes}
                  imageContainerWidth={120}
                  fsHandler={handleClick}
                  dlEnabled={true}
                />
                <Typography sx={sx.title}>
                  {meta.collection_name
                    ? meta.collection_name
                    : 'Girls Robots Dragons'}{' '}
                  #{meta.id}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        ''
      )}
    </>
  );
};
export default NftGallery;
