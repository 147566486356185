import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DustTnc from '../Modals/DustTnc';
import WalletDustAmountBox from '../WalletDustAmountBox';
import DustContainerFooter from './components/DustContainerFooter';
import DustItem from './components/DustItem';
import './DustSection.css';

const BP1 = '@media (max-width: 700px)';

const sx = {
  root: {
    width: 'min(100% - 20px, 680px)',
    margin: 'auto',
    mb: '40px',
  },
  dustContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    p: '12px',
    gap: '16px',
    borderRadius: '8px',
    backgroundColor: '#1C3B3C',
  },
  dustItemHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [BP1]:{
      gap: '10px',
      justifyContent: 'center'
    }
  },
};

const DustContainer = ({
  totalDustAmount,
  totalCardIds,
  ethereumDustAmount,
  ethereumCardIds,
  polygonDustAmount,
  polygonCardIds,
  handleClaim,
  chainId,
  loading
}) => {
  const [showTnc, setShowTnc] = useState(false);
  const [claimData, setClaimData] = useState(null)
  const [termsReaded, setTermsReaded] = useState(false);
  const [isPolygonChain, setIsPolygonChain] = useState(false);
  const handleClaimButton = () => {
    setClaimData({claimData: polygonCardIds})
    setShowTnc(true)
  };
  const handleBridgeNav = () => {
    window.open('https://nftbridge.galaxis.xyz/', '_blank');
  };
  const checkChainId = async ()  => {
    if (chainId !== 137) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: '0x89',
          },
        ],
      });
    } 
  };

  useEffect(() => {
    if(chainId == 137){
      setIsPolygonChain(true);
    }else{
      setIsPolygonChain(false);
    }
  }, [chainId])
  

  useEffect(() => {
    if(chainId == 137 && termsReaded){
      setIsPolygonChain(true);
      handleClaim();
      setTermsReaded(false);
    }
  }, [chainId, termsReaded])
  const startClaim = () => {
    checkChainId();
    setTermsReaded(true);
    setShowTnc(null);
  }
  return (
    <>
      <Box sx={sx.root}>
        <Box sx={sx.dustContainer}>
          <WalletDustAmountBox dustAmount={totalDustAmount} totalCardAmounts={totalCardIds.length} />
          <Box sx={sx.dustItemHolder}>
            <DustItem
              cardAmount={polygonCardIds.length}
              chainName={"POLYGON"}
              dustAmount={parseFloat(polygonDustAmount).toFixed(2)}
              buttonLabel={isPolygonChain ? 'CLAIM' : "SWITCH NETWORK"}
              disable={loading}
              onClick={isPolygonChain ? handleClaimButton : checkChainId}
            />
            <DustItem
              cardAmount={ethereumCardIds.length}
              chainName={"ETHEREUM"}
              dustAmount={parseFloat(ethereumDustAmount).toFixed(2)}
              buttonLabel={'BRIDGE IT'}
              disable={false}
              onClick={handleBridgeNav}
            />
          </Box>
        </Box>
        <DustContainerFooter />
      </Box>
      <DustTnc
      claimData={claimData}
        showTnc={showTnc}
        hideTnc={() => { setShowTnc(null) }}
        handleClaim={startClaim}
      />
    </>
  );
};

export default DustContainer;
