import React from 'react';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
const BP2 = '@media (max-width: 508px)';
const BP3 = '@media (max-width: 415px)';

const sx = {
  root: {
    width: '100%',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '1',
    margin: 'auto',
  },
  text: {
    position: 'absolute',
    fontSize: '36px',
    mt: '2px',
    fontFamily: 'boucherie-block',
    lineHeight: '0',
    [BP2]: {
      fontSize: '30px',
    },
    [BP3]: {
      fontSize: '25px',
    },
  },
};

const Decoration = forwardRef(
  ({ text, img, style, onClick, decoration }, ref) => {
    return (
      <Box sx={sx.root} style={{ ...style }} ref={ref} onClick={onClick}>
        {img && (
          <img
            src={img}
            style={{
              width: '100%',
              height: '85px',
              ...style,
              display: !decoration ? 'block' : 'none',
            }}
            alt="valami"
          />
        )}
        <Typography sx={sx.text} style={{ color: decoration ? '#D1D3C1' : '' }}>
          {text}
        </Typography>
      </Box>
    );
  }
);
Decoration.propTypes = {
  img: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
};
export default Decoration;
