import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { toast } from 'react-toast'
import config from '../../../../config/config'
import Web3Ctx from '../../../../context/Web3Ctx'
import { getTokenIds } from '../../../../utils/zoom2'
import { dustContract, getAllDustAvailable } from '../../../../View/ExplorerCard/Components/Dust/getDust'
import DustClaimingProcedure from './components/DustClaimingProcedure'
import OrangeSwitch from './components/OrangeSwitch'
import DustContainer from './DustContainer'


const BP1 = '@media (max-width: 600px)';

const sx = {
  root: {
    width: 'min(100% - 20px, 715px)',
    mt: '40px',
    mx: 'auto',
  },
  titleBox: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '10px',
    columnGap: '15px',
    mb: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  title: {
    color: '#FAFAF2',
    fontFamily: 'Boucherie-block',
    fontSize: '20px'
  }
}

const DustSection = ({ tokenContract, ethereumZoomContract, polygonContract, polygonZoomcontract, handleRefetchMetadatas }) => {
  const { address, ethersProvider, chainId } = useContext(Web3Ctx);
  const [totalDustAmount, setTotalDustAmount] = useState(0);
  const [ethereumDustAmount, setEthereumDustAmount] = useState(0);
  const [polygonDustAmount, setPolygonDustAmount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txCompleted, setTxCompleted] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(null);
  const [disableButton, setdisableButton] = useState(false);
  const [totalCardIds, setTotalCardIds] = useState([]);
  const [ethereumCardIds, setethereumCardIds] = useState([])
  const [polygonCardIds, setPolygonCardIds] = useState([])
  const [switchValue, setSwitchValue] = useState(true)

  useEffect(() => {
    if ((address) || txCompleted) {
      getTokens(tokenContract, polygonContract);
      if (txCompleted) {
        setTxCompleted(false);
        handleRefetchMetadatas();
      }
    }
  }, [address, txCompleted]);


  // console.log(onboard);
  const getTokens = async (tokenContract, polygonContract) => {
    const etherIds = await getTokenIds(
      tokenContract,
      ethereumZoomContract,
      address
    ).catch(e=>console.log('ether IDs error',e));
    const polygonIds = await getTokenIds(
      polygonContract,
      polygonZoomcontract,
      address
    ).catch(e=>console.log('polygon IDs error',e));

    if (
      etherIds.length > 0 ||
      polygonIds.length > 0
      //  metas2.length > 0
    ) {
      setPolygonCardIds(polygonIds);
      setethereumCardIds(etherIds);
     
      setTotalCardIds([...etherIds, ...polygonIds])
      var etherwalletAmount = 0;
      var polygonWalletAmount = 0;
      etherwalletAmount = await getAllDustAvailable(etherIds);
      polygonWalletAmount = await getAllDustAvailable(polygonIds);
      const total = await getAllDustAvailable([...etherIds, ...polygonIds]);
      // console.log(polygonWalletAmount);
      setEthereumDustAmount(etherwalletAmount.replace(/,/g, ''));
      setPolygonDustAmount(polygonWalletAmount.replace(/,/g, ''));
      setTotalDustAmount(total.replace(/,/g, ''));
      if (parseFloat(polygonWalletAmount) <= 0) {
        setdisableButton(true)
      }
    }
  };


  const withdrawDust = async () => {
    // console.log("HEELLLOOOOOOO")
    setShowLoader(true);

    const contract = dustContract();

    let signer = ethersProvider.getSigner(address);

    const claimContract = contract.connect(signer);
    // console.log(signer);
    // console.log(claimContract);
    // console.log('claiming dust');

    // if (showLoader || txInProgress || !claimContract) return;

    await withdrawNow(claimContract, polygonCardIds);
  };

  const withdrawNow = async (claimContract, tokenIds) => {
    return claimContract
      .redeem(tokenIds)
      .then((tx) => {
        console.log(tx);
        setTxEtherScan(`${config.ETHERSCAN_URL}tx/` + tx.hash);
        setTxInProgress(true);

        return tx.wait().then(async (receipt) => {
          console.log('txReceipt: ', receipt);
          if (receipt && receipt.status === 1) {
            toast.success('Successfully claimed Dust');
            setTxEtherScan(null);
            setTxCompleted(true);
            setShowLoader(false);
          } else {
            toast.error('Transaction Failed');
            setTxEtherScan(null);
            setTxInProgress(false);
            setShowLoader(false);
          }
        });
      })
      .catch(handleError);
  };

  const handleError = (e) => {
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
    setShowLoader(false)
  };

  return (
    <Box sx={sx.root}>
      <Box sx={sx.titleBox} style= {{marginBottom: !switchValue && '0px'}}>
        <Typography sx={sx.title}>DUST INFO</Typography>
        <OrangeSwitch
          handleToggle={() => setSwitchValue(!switchValue)}
          isOn={switchValue}
          onColor={"#FC6405"}
        />
      </Box>
      {switchValue && <Box>
        <DustContainer
          totalCardIds={totalCardIds}
          ethereumCardIds={ethereumCardIds}
          polygonCardIds={polygonCardIds}
          ethereumDustAmount={ethereumDustAmount}
          totalDustAmount={totalDustAmount}
          polygonDustAmount={polygonDustAmount}
          handleClaim={withdrawDust}
          chainId={chainId}
          loading={showLoader}
        />
        <DustClaimingProcedure />
      </Box>}
    </Box>
  )
}

export default DustSection