import React, { useState, useEffect } from 'react';
import { Box, Modal, Button } from '@mui/material';
import ItemsCarousel from 'react-items-carousel';
import close from '../assets/images/white_close.svg';
import { Suspense } from 'react';

const BP1 = '@media (max-width: 899px)';
const BP2 = '@media (max-width: 719px)';
const BP3 = '@media (max-width: 430px)';
const BP4 = '@media (max-width: 400px)';
const BP5 = '@media (max-width: 1080px)';

const sx = {
  root: {
    height: '100%',
    backgroundColor: '#152d2ebf',
    px: '100px',
    position: 'relative',
    pointerEvents: 'none',
    transition: 'all .3s',
    zIndex: '1400',
    [BP1]: {
      px: '85px',
    },
    [BP2]: {
      px: '25px',
    },
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  closeBtn: {
    position: 'absolute',
    maxWidth: '160vh',
    pr: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    top: '15px',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  buttonContainer: {
    width: '190px',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    top: '20px',
    pointerEvents: 'auto',
  },
};
const Gallery = ({ images, isOpen, setOpen, style, id }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [noOfCards, setNoOfCards] = useState(1);
  useEffect(() => {
    if (id) {
      setActiveItemIndex(id);
    }
  }, [id]);
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setOpen(false);
        setActiveItemIndex(0);
      }}
    >
      <Suspense fallback={<div>Loading carousel...</div>}>
        <Box
          sx={sx.root}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={sx.carousel}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                width: '100%',
                maxHeight: '90vh',
                zIndex: '1400',
              }}
            >
              <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={noOfCards}
                gutter={2}
                infiniteLoop
                swipeable={true}
              >
                {images &&
                  images.map((img, id) => (
                    <Box
                      key={id}
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '0 auto',
                        height: '80vh',
                      }}
                    >
                      <Box
                        style={{
                          height: '100%',
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '0 auto',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={img}
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            width: 'auto',
                            height: 'auto',
                            zIndex: '1400',
                            pointerEvents: 'auto',
                          }}
                          alt="img"
                        />
                      </Box>
                    </Box>
                  ))}
              </ItemsCarousel>
            </Box>
            {images.length > 1 && (
              <Box sx={sx.buttonContainer}>
                <Button
                  sx={sx.button}
                  onClick={() => setActiveItemIndex(activeItemIndex - 1)}
                  variant="contained"
                >
                  Prev
                </Button>
                <Button
                  sx={sx.button}
                  variant="contained"
                  onClick={() => setActiveItemIndex(activeItemIndex + 1)}
                >
                  Next
                </Button>
              </Box>
            )}
            <Box
              sx={sx.closeBtn}
              style={style}
              onClick={() => {
                setOpen(false);
                setActiveItemIndex(0);
              }}
            >
              <img src={close} style={{ width: '23px' }} alt="Close" />
            </Box>
          </Box>
        </Box>
      </Suspense>
    </Modal>
  );
};

export default Gallery;
