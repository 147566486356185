import React from 'react';

import { Box, Modal, Typography, Button } from '@mui/material';

import close from '../../../../assets/images/orange_x.svg';

const BP1 = '@media (max-width: 899px)';
const BP2 = '@media (max-width: 719px)';
const BP3 = '@media (max-width: 600px)';
const BP4 = '@media (max-width: 430px)';
const BP5 = '@media (max-width: 408px)';

const sx = {
  root: {
    height: '100%',
    backgroundColor: '#152d2ebf',
    px: '125px',
    position: 'relative',
    pointerEvents: 'none',
    transition: 'all .3s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '30px',
    [BP1]: {
      px: '85px',
    },
    [BP2]: {
      px: '25px',
    },
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  closeBtn: {
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    pointerEvents: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    pr: '70px',
    [BP3]: {
      pr: '20px',
    },
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    maxWidth: '200px',
  },
  title: {
    fontFamily: 'boucherie-block',
    fontSize: '46px',
    fontWeight: '700',
    textAlign: 'center',
    color: '#FF692B',
    lineHeight: '50px',
    mt: '15px',
    mb: '30px',
    [BP3]: {
      fontSize: '39px',
      mb: '20px',
    },
    [BP4]: {
      fontSize: '35px',
    },
  },
  description: {
    fontFamily: 'bau',
    maxWidth: '387px',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '24px',
    mb: '40px',
    [BP3]: {
      maxWidth: '350px',
    },
    [BP4]: {
      maxWidth: '300px',
    },
    [BP5]: {
      px: '20px',
    },
  },
  content: {
    maxWidth: '508px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#CACCB5',
    padding: '30px 10px 40px 10px',
    borderRadius: '15px',
    outline: 'solid 2px #D2D4C2',
    outlineOffset: '12px',
    pointerEvents: 'auto',
    [BP3]: {
      maxWidth: '450px',
    },
  },
  closeBox: {
    display: 'flex',
  },
  button: {
    backgroundColor: '#CACCB5',
    color: '#FF692B',
    border: 'solid 3px #FF692B',
    '&:hover': {
      backgroundColor: '#FF692B',
      border: 'solid 3px #FF692B',
      color: '#FFF',
    },
  },
};

const SubmitModal = ({ isOpen, setOpen }) => {
  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        setOpen(false);
      }}
    >
      <Box
        sx={sx.root}
        style={{
          display: 'flex',
          jusifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={sx.content}>
          <Box
            sx={sx.closeBtn}
            onClick={() => {
              setOpen(false);
            }}
          >
            <img
              src={close}
              style={{ width: '16px', cursor: 'pointer' }}
              alt="Close"
            />
          </Box>
          <Typography sx={sx.title}>Collection Submission Update</Typography>

          <Typography sx={sx.description}>
            The collection submission will be enabled at a later date to be
            announced as soon as possible.
          </Typography>
          <Box sx={sx.buttonBox}>
            {/* <Button
              variant="contained"
              style={{ pointerEvents: 'auto', color: '#152D2E' }}
              onClick={() => setOpen(false)}
            >
              Decline
            </Button> */}
            <Button
              variant="contained"
              sx={sx.button}
              onClick={() => {
                setOpen(false);
              }}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubmitModal;
