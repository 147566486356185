import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import PopupModal from './SaleComponent/components/PopupModal';
import config from '../config/config';

const BP3 = '@media (max-width: 384px)';

const sx = {
    buyButton: {
        height: 'auto',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: 'inherit',
        textTransform: 'uppercase',
        padding: '6px 16px',
        borderRadius: '5px',
        whiteSpace: 'nowrap',
        border: '4px solid #FF692B',
        fontFamily: 'boucherie-block',
        color: '#FF692B',
        '&:hover': {
            border: '4px solid #fff',
            backgroundColor: '#fff',
            color: '#FF692B',
        },
        '&:disabled': {
            opacity: '0.7',
        },
        [BP3]: {
            width: '100%',
            px: '14px',
        },
    },
    title: {
        fontSize: '46px',
        color: '#FF692B',
        fontWeight: '700',
        fontFamily: 'boucherie-block',
    },
    description: {
        fontSize: '16px',
        fontFamily: 'bau',
        mt: '15px',
        mb: '20px',
    },
};
const ThankYouModal = ({ type, isOpen, setOpen, price, selectedAmount }) => {
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (selectedAmount > 0 && isOpen
            && config.DEPLOYED_CHAIN_ID === 4
        ) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'NFT-purchase',
                'pieces': selectedAmount, // how many NFT was purchased
                'value': price, // total value of the purchase
                'currency': 'ETH' // if it was purchased by CC it might be USD, and the value might be different as well
            }); 
            console.log(price, selectedAmount, 'transaction is ended')
        }
    }, [selectedAmount, isOpen])
    return (
        <PopupModal isOpen={isOpen} isTxPopup={false} style={{ padding: '0px' }}>
            <Box>
                <Typography sx={sx.title}>THANK YOU!</Typography>
                <Typography sx={sx.description}>
                    Your card were sent to your wallet, transaction confirmed. Thank you for your purchase!
                </Typography>
                <Button
                    sx={sx.buyButton}
                    style={{ margin: '10px' }}
                    variant="banner"
                    onClick={handleClose}
                // disabled={!address || isLoading}
                >
                    Okay
                </Button>
            </Box>
        </PopupModal>
    );
};

export default ThankYouModal;
