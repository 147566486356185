import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography, Grid, Modal } from '@mui/material';
import grd from './assets/images/grd_404.png';

const sx = {
  body: {
    position: 'relative',
    margin: 'auto',
    backgroundColor: '#152D2E',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const NotFound = () => (
  <Box sx={sx.body}>
    <Box sx={sx.imageContainer}>
      <img src={grd} style={{ width: '100%' }} alt="image" />
    </Box>
    <Button
      sx={sx.button}
      variant="contained"
      style={{ marginTop: '40px' }}
      component={Link}
      to="/"
    >
      back to home
    </Button>
  </Box>
);

export default NotFound;
