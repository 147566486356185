import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CardsSwitch from './CardsSwitch';
import perfectBadge from '../../../assets/images/perfect-badge.png';
import LevelsModal from './Modals/LevelsModal';
import './CollectionWallet.css';
import orangeBadge from '../../../assets/images/orange_badge.svg';

const sx = {
  title: {
    fontFamily: 'boucherie-block',
    fontSize: '16px',
    color: '#D2D4C2',
    marginTop: '6px',
  },
  imgHolder: {
    position: 'relative',
  },
};
const WalletCards = ({ cardArray, tab,  nftsPaginationTab}) => {
  const [openLevels, setOpenLevels] = useState(false);

  const levelClick = () => {
    setOpenLevels(true);
  };
  return (
    <>
      {cardArray.length === 1 ? (
        <Box>
          <Box sx={sx.imgHolder}>
            <img
              src={cardArray[0].image}
              style={{ maxWidth: '100%', borderRadius: '10px' }}
              alt="Card"
            />
            {cardArray[0].perfect && (
              <img src={perfectBadge} alt="" className="wallet-badge-image" />
            )}
            <Box className="orange-badge-image">
              {tab !== 3 && cardArray[0].perfect && !cardArray[0].rare && (
                <Box
                  onClick={levelClick}
                  style={{ cursor: 'pointer', position: 'relative' }}
                >
                  <img src={orangeBadge} alt="" />
                  <Typography
                    className="orange-badge-number"
                    style={{ fontFamily: 'boucherie-block', fontSize: '22px' }}
                  >
                    E
                  </Typography>
                </Box>
              )}
              {tab !== 2 && cardArray[0].rare && !cardArray[0].perfect && (
                <Box
                  onClick={levelClick}
                  style={{ cursor: 'pointer', position: 'relative' }}
                >
                  <img src={orangeBadge} alt="" />
                  <Typography
                    className="orange-badge-number"
                    style={{ fontFamily: 'boucherie-block', fontSize: '22px' }}
                  >
                    R
                  </Typography>
                </Box>
              )}
              {tab !== 4 && cardArray[0].rare && cardArray[0].perfect && (
                <Box
                  onClick={levelClick}
                  style={{ cursor: 'pointer', position: 'relative' }}
                >
                  <img src={orangeBadge} alt="" />
                  <Typography
                    className="orange-badge-number"
                    style={{ fontFamily: 'boucherie-block', fontSize: '22px' }}
                  >
                    L
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Typography sx={sx.title}>
            {cardArray[0].collection_name
              ? cardArray[0].collection_name
              : 'Girls Robots Dragons'}{' '}
            #{cardArray[0].id}
          </Typography>{' '}
        </Box>
      ) : (
        <CardsSwitch dataArray={cardArray} tab={tab} nftsPaginationTab={nftsPaginationTab}/>
      )}
      <LevelsModal setOpen={setOpenLevels} isOpen={openLevels} />
    </>
  );
};
export default WalletCards;
