import { Explorer } from '../View/Explorer/Explorer';
import CardRender from '../View/ExplorerCard/CardRender';
import Home from '../View/Home/Home';
import PrivacyPolicy from '../View/PrivacyPolicy/PrivacyPolicy';
import config from '../config/config'
import DustPage from '../View/DustCalim/DustPage';

const routes = config.DEPLOYED_CHAIN_ID===1?
[
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: '/render/',
    component: CardRender,
    exact: true,
  },
  {
    path: '/dust-claim',
    component: DustPage,
    exact: true,
  },
]
:
[
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: '/render/',
    component: CardRender,
    exact: true,
  },
  {
    path: '/explorer',
    component: Explorer,
    exact: true,
  },{
    path: '/dust-claim',
    component: DustPage,
    exact: true,
  },
];

export default routes;
