import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Contract } from '@ethersproject/contracts';
import { DateTime } from "luxon";
import config from "../../config/config";
import { ethers, providers } from "ethers";
import { toast } from "react-toast";


import useInterval from "../../hooks/useInterval";

import galaxis from "../../assets/images/galaxis.svg";
import InfoBox from "./components/InfoBox";
import Counter from "../../Components/Counter/Counter";
import Timer from "./components/Timer";
import useWeb3Ctx from '../../hooks/useWeb3Ctx';


import saleABI from './abi/Sale.json';
import saleStagingABI from './abi/Sale_staging.json';
import tokenABI from './abi/Token.json';
import CheckoutModal from "./components/CheckoutModal";
import BackdropModal from "./components/BackdropModal";
import TxProgressModal from "./components/TxProgressModal";
import PermissionErrorModal from "./components/PermissionErrorModal";
import './Sales.css';
//import sigs from "./signatures/sigs"; // will be downloaded in the future
import { width } from "@mui/system";
import Wallet from "./components/Wallet";
import axios from "axios";
import Decoration from "../Decoration";

import decoration_v1 from '../../assets/images/decoration_v1.svg';
import decEnd from '../../assets/images/decor-end.png';
import decMid from '../../assets/images/decor-mid.png';
import DecoratedTitle from "../DecoratedTitle";

import whitelistTest from "./whitleist-test";
import whitelist from "./whitleist";
import GA4React from "ga-4-react";
import ThankYouModal from "../ThankYouModal";
import ProgressBar from "./components/ProgressBar";
import WhatsNext from "../../View/Home/components/WhatsNext";

const BP2 = "@media (max-width: 1345px)";
const BP4 = "@media (max-width: 600px)";
const BP5 = "@media (max-width: 899px)";
const BP6 = "@media (max-width: 700px)";
const BP3 = "@media (max-width: 384px)";
const BP7 = "@media (max-width: 820px)";
const BP1 = "@media (max-width: 570px)";

const sx = {
	saleRoot: {
		textAlign: 'center'
	},
	container: {
		margin: "auto",
		mt: "-5px",
		position: "relative",
		pt: "50px",
		pb: "100px",
		maxWidth: "1233px",
		overflow: "hidden",
		transition: "all .3s",
		backgroundColor: "#FFF",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		px: "20px",
		color: "#D2D4C2",
		fontFamily: "boucherie-block",
		[BP4]: {
			pt: "39px",
		},
	},
	nameContainer: {
		marginTop: "50px",
		position: "absolute",
		mx: "auto",
		display: "flex",
		maxWidth: "1140px",
		width: "100%",
		zIndex: "1",
		backgroundColor: "#511BA3",
		[BP4]: {
			marginTop: "35px",
		},
	},
	name: {
		position: "absolute",
		height: "135px",
		width: "135px",
		backgroundColor: "#511BA3",
		borderRadius: "15px",
		bottom: "4px",
		left: "0",
		right: "0",
		marginRight: "auto",
		marginLeft: "auto",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	infoContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		textAlign: "center",
		paddingTop: "0px"
	},
	infoContainerPool: {
		display: "inline-flex",
		flexDirection: "row",
		width: "auto",
		textAlign: "center",
		paddingTop: "0px"
	},
	poolCounter: {
		marginLeft: "12px"
	},
	poolCounterTitle: {
		color: "#D2D4C2",
		fontFamily: "boucherie-block",
		textAlign: "left",
	},
	poolCounterValue: {
		color: "#D2D4C2",
		fontFamily: "boucherie-block",
		textAlign: "left",
		fontSize: "20px"
	},
	badgeContainer: {
		position: "relative",
		margin: "auto",
		// maxWidth: "300px",
		borderRadius: "12px",
		border: "solid 2px #D2D4C2",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		px: "20px",
	},
	badgeLeft: {
		borderRight: "solid 2px #D2D4C2",
		paddingRight: "20px",
		[BP3]: {
			borderRight: "unset",
			borderBottom: "solid 2px #D2D4C2",
			width: "100%",
			paddingRight: "0",
		},
	},
	badgeCenter: {
		// borderRight: "solid 2px #D2D4C2",
		paddingLeft: "20px",
		[BP3]: {
			borderRight: "unset",
			// borderBottom: "solid 2px #D2D4C2",
			width: "100%",
			paddingLeft: "0",
		},
	},
	badgeRight: {
		[BP3]: {
			width: "100%",
		},
	},
	timeContainer: {
		padding: "4px",
		margin: "auto",
		display: "inline-flex",
		maxWidth: "512px",
		borderRadius: "12px",
		border: '2px solid #D2D4C2',
		flexWrap: "wrap",
		justifyContent: "center",
		gap: "12px",
		[BP4]: {
			padding: "10px",
		},
	},
	cardContainer: {
		mx: "auto",
		mt: "50px",
		mb: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		flexWrap: "wrap",
		gap: "40px 16px",
	},
	title: {
		fontSize: "36px",
	},
	titleLive: {
		color: "#000",
	},
	socialContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		gap: "17px",
		mt: "20px",
		mb: "30px",
	},
	description: {
		fontFamily: "poppins",
		color: "#D2D4C2",
		fontSize: "16px",
		lineHeight: "24px",
		maxWidth: "606px",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		margin: "auto",
		mt: "25px",
	},
	nftContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	largeBanner: {
		display: "block",
		[BP7]: {
			display: "none",
		},
	},
	smallBanner: {
		display: "none",
		[BP7]: {
			display: "block",
		},
	},
	button: {
		backgroundColor: "#FF692B",
		color: "#FAFAFA",
		fontSize: "14px",
		padding: "14px",
		borderRadius: "8px",
		"&:hover": {
			backgroundColor: "#FF692B",
		},
	},
	buttonOutlined: {
		backgroundColor: "transparent",
		color: "#FF692B",
		fontSize: "14px",
		padding: "14px",
		borderRadius: "8px",
		"&:hover": {
			backgroundColor: "#FF692B",
			color: "white"
		},
	},

	tabContainer: {
		justifyContent: "center",
		paddingTop: "40px",
		paddingBottom: "40px",
		mx: 'auto',
	},
	tabBtns: {
		color: "#D2D4C2",
		borderColor: "#FF692B",
		borderWidth: "2px",
		padding: "6px 14px",
		fontSize: '20px',
		fontFamily: "boucherie-block",
		"&:hover": {
			borderColor: "#FF692B",
			backgroundColor: "#fff",
			borderWidth: "2px",
			color: "#FF692B",
			borderRightColor: "#FF692B !important",
		},
		borderRadius: "10px"
	},
	tabSelected: {
		backgroundColor: "#FF692B",
		color: "#fff",
	},
	buyButton: {
		height: 'auto',
		fontWeight: '500',
		fontSize: '20px',
		lineHeight: 'inherit',
		textTransform: 'uppercase',
		padding: '6px 16px',
		borderRadius: '5px',
		whiteSpace: 'nowrap',
		backgroundColor: '#FF692B',
		border: '2px solid #FF692B',
		fontFamily: 'boucherie-block',
		color: '#fff',
		'&:hover': {
			border: '2px solid #fff',
			backgroundColor: '#fff',
			color: '#FF692B',
		},
		'&:disabled': {
			opacity: '0.7'

		},
		[BP3]: {
			width: '100%',
			px: '14px',
		},
	},
	url: {
		textDecoration: "none",
		color: "#FF692B",
		fontWeight: "700",
	},
	description: {
		margin: 'auto',
		maxWidth: '715px',
		fontFamily: 'poppins',
		fontSize: '14px',
		color: '#D2D4C2',
		textAlign: 'center',
		lineHeight: '1.8',

	}
};


const Sales = ({ tokenAddress, saleAddress, symbol, labels }) => {
	const { onboard, address, ethersProvider, handleConnect, defaultChainId } = useWeb3Ctx();
	// const GA4 = new GA4React('G-1QGHZHFE9B');

	const [tab, setTab] = useState(0);

	const [minted, setMinted] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [salePrice, setSalePrice] = useState(0);
	const [discountPrice, setDiscountPrice] = useState(0);

	//sale states needed a different approach, because the sales can overlap each other :/

	const [isGrdHolder, setIsGrdHolder] = useState(false);
	const [canMintPlusOne, setCanMintPlusOne] = useState(false);
	const [plusOneSaleOn, setPlusOneSaleOn] = useState(true);

	const [preSaleStarted, setPreSaleStarted] = useState(false);
	const [preSaleFinished, setPreSaleFinished] = useState(false);

	const [mainSaleStarted, setMainSaleStarted] = useState(false);
	const [mainSaleFinished, setMainSaleFinished] = useState(false);


	const [presaleStartTime, setPresaleStartTime] = useState(null);
	const [saleStartTime, setSaleStartTime] = useState(null);
	const [presaleEndTime, setPresaleEndTime] = useState(null);
	const [saleEndTime, setSaleEndTime] = useState(null);


	const [freeMintCounter, setFreeMintCounter] = useState(null);
	const [presaleTimeCounter, setPresaleTimeCounter] = useState(null);
	const [saleTimeCounter, setSaleTimeCounter] = useState(null);


	const [isLoading, setIsLoading] = useState(false);

	const [tokenContract, setTokenContract] = useState(null);
	const [saleContract, setSaleContract] = useState(null);

	const [txEtherScan, setTxEtherScan] = useState('');
	const [showCheckout, setShowCheckout] = useState(false);
	const [showErrorPopup, setShowErrorPopup] = useState(false);
	const [txInProgress, setTxInProgress] = useState(false);
	const [approveInProgress, setApproveInProgress] = useState(false);
	const [checkoutIsPresale, setCheckoutIsPresale] = useState(true);
	const [isCreditCard, setIsCreditCard] = useState(false);

	const [signature, setSignature] = useState(null);


	const [maxTokenPerAddress, setMaxTokenPerAddress] = useState(10);

	const [maxDiscountMintable, setMaxDiscountMintable] = useState(10);
	const [maxMintableDuringMainSale, setMaxMintableDuringMainSale] = useState(10);
	const [userMaxDiscountMintable, setUserMaxDiscountMintable] = useState(0);


	const [refreshInterval, setRefreshInterval] = useState(null);

	const [signatures, setSignatures] = useState(null);

	const [saleLabels, setSaleLabels] = useState({
		preSaleTitle: "Pre-Sale",
		preSaleDescription: "",
		publicSaleTitle: "Public Sale",
		publicSaleDescription: "",
	});

	const [thankyouModalIsOpen, setThankyouModalIsOpen] = useState(false)
	const [selectedAmount, setselectedAmount] = useState(0);
	const [price, setprice] = useState(0)

	const [userIsEcHolder, setUserIsEcHolder] = useState(false);

	const [maxMintPerTransaction, setMaxMintPerTransaction] = useState(10);
	const [poolValue, setPoolValue] = useState(0);

	useEffect(() => {

		if (defaultChainId === 1) {
			setSignatures(whitelist);
		} else {
			setSignatures(whitelistTest);
		}

		setSaleLabels(labels);

	}, []);

	useEffect(() => {
		(async () => {
			if (sessionStorage.getItem('selectedWallet')) {
				let t = Number(localStorage.getItem('activeTab'));
				if (t === 1) {
					setTab(1);
				} else {
					setTab(0);
				}
			}
		})();
	}, [onboard]);



	useInterval(
		() => {
			if (mainSaleFinished) {
				setRefreshInterval(null);
			} else {
				// console.log('interval get sale')
				getSaleInfo();
			}
		},
		refreshInterval,
		false
	);




	useEffect(() => {
		if(saleContract){

			getSaleInfo();
		}
		
	}, [address]);



	useEffect(() => {
		

		if (address && signatures) {
			let userData = getUserParams();
			if (userData) {
				const _discountPrice = ethers.BigNumber.from(userData.params.eth_price); //info.discountPrice;
				setDiscountPrice(ethers.utils.formatEther(_discountPrice));
				setSignature(userData.signature);
				setUserIsEcHolder(true);
			} else {
				setUserIsEcHolder(false);
			}
		} else {
			setUserIsEcHolder(false);
		}
		
	}, [address, signatures]);




	useEffect(() => {
		const initContracts = async () => {

			if (tokenContract == null || saleContract === null) {
				const provider = new providers.JsonRpcProvider(config.RPC_URL);
				//console.log('EP in contract init',ethersProvider);

				let token = new Contract(tokenAddress, tokenABI.abi, provider);
				if (!token) {
					console.error('Token contract not found on address', tokenAddress);
					return;
				}

				let sale = new Contract(saleAddress, config.DEPLOYED_CHAIN_ID === 1 ? saleABI.abi : saleStagingABI.abi, provider);
				if (!sale) {
					console.error('Sale contract not found on address', saleAddress);
				}

				// console.log('CONTRACTS INITIATED', token, sale);
				setTokenContract(token);
				setSaleContract(sale);
			}
		}

		if (saleAddress && tokenAddress) {
			//console.log('props changed',saleAddress,tokenAddress,ethersProvider)
			initContracts();
		}
	}, [saleAddress, tokenAddress, ethersProvider]);



	useEffect(() => {
		const getInfo = async () => {
			//console.log('getting sale info start');
			await getSaleInfo();
			console.log('getting sale info end');
		}

		if (tokenContract !== null && saleContract !== null) {
			//console.log('+++++++++++++++++++++get sale info in effect', tokenContract,saleContract);
			getInfo();
			setRefreshInterval(20000);
		}
	}, [tokenContract, saleContract]);

	const getUserParams = () => {
		let up = null;
		if (address) {
			const key = Object.keys(signatures).find(
				(key) => key.toLowerCase() == address.toLowerCase()
			);

			if (key) {
				const userParams = signatures[key].params;

				up = {
					params: {
						max_mint: userParams[2],
						receiver: userParams[3],
						valid_from: userParams[4],
						valid_to: userParams[5],
						eth_price: userParams[6]
					},
					raw_params: userParams,
					signature: signatures[key].signature
				}
			}
		}
		return up;
	}

	const getSaleInfo = async () => {
		//console.log('saleInfo',saleContract);
		setIsLoading(true);

		const pv = await ethersProvider.getBalance(config.POOL_ADDRESS).catch(e => console.log);

		if (pv) {
			//	console.log('POOOOL BBBBALANCE', ethers.utils.formatEther(pv));
			let p = Number(ethers.utils.formatEther(pv)).toFixed(3);
			setPoolValue(p);
		}


		const info = await saleContract.tellEverything().catch(e => console.log('err:', e));


		const totalSupply = await tokenContract.totalSupply();
		//console.log('TS',totalSupply);
		// console.log('info', info)



		const presaleStart = Number(info.config.presaleStart);
		const presaleEnd = Number(info.config.presaleEnd);
		const saleStart = Number(info.config.saleStart);
		const saleEnd = Number(info.config.saleEnd);

		const fullPrice = info.config.fullPrice;




		/* const maxTokens = Number(info.maxTokens);
		const userMinted = Number(info.userMinted);

		const presaleIsActive = info.presaleIsActive;
		const saleIsActive = info.saleIsActive; */


		setMaxDiscountMintable(Number(info.config.maxPresalePerAddress));
		setMaxTokenPerAddress(Number(info.config.maxSalePerAddress));

		setMaxMintPerTransaction(Number(info.config.maxMintPerTransaction));

		//	let reserved = info.maxTokens.sub(info.MaxUserMintable);		
		//	setMinted(Number(info.userMinted.add(reserved)));
		setMinted(Number(totalSupply));
		setTotalAmount(Number(info.config.MaxUserMintable)); //maxTokens

		let now = (Date.parse(new Date()) / 1000);
		//let now2 = Date.parse(new Date()) / 1000;
		//let now = Number(await saleContract.getBlockTimestamp());
		
				// console.log('current time', now)
				// console.log('presale start time', presaleStart)
				// console.log('presale end time', presaleEnd)
				// console.log('sale start time', saleStart)
				// console.log('sale end time', saleEnd)

				// console.log('presale start diff', now-presaleStart);
				// console.log('presale end diff', now-presaleEnd);
				// console.log('sale start diff', now-saleStart);
				// console.log('sale end diff', now-saleEnd);

		let presaleIsOver = (presaleEnd - now) <= 0;
		let saleIsOver = (saleEnd - now) <= 0;
		let saleIsOn = (now >= saleStart && !saleIsOver);
		let presaleIsOn = saleIsOn;// (now>=presaleStart && !presaleIsOver);
		let freeMintIsOn = presaleEnd > now;


		//	let _discountPrice = 0;
		let _discountPrice = ethers.BigNumber.from('60000000000000000');

		if (presaleIsOn) {
			let userParams = getUserParams();
			if (userParams) {
				_discountPrice = ethers.BigNumber.from(userParams.params.eth_price); //info.discountPrice;
			}
		}

		//ec holder's hack
		let ecHolder = getUserParams();
		//console.log('USER IS EC HOLDER', ecHolder);
		if (ecHolder) {
			_discountPrice = ethers.BigNumber.from(ecHolder.params.eth_price); //info.discountPrice;
			setUserIsEcHolder(true);
			setIsGrdHolder(true);
			//console.log('USER IS EC HOLDER2', ecHolder);
		} else {
			setUserIsEcHolder(false);
			setIsGrdHolder(false);
		}

		const alreadyMintedByWallet = await saleContract._mintedByWallet(address).catch(e => console.log);
		/* if(Number(alreadyMintedByWallet)>0){
			setIsGrdHolder(true);
		}else{
			setIsGrdHolder(false);
		} */
		
		if (alreadyMintedByWallet && ecHolder) {
			let maxMintable = ecHolder.params.max_mint - Number(alreadyMintedByWallet);
			console.log('MAXMINTABLE', maxMintable);
			if(maxMintable>0){
				setCanMintPlusOne(true);
			//	setFreeMintCounter(new Date(presaleEnd * 1000));
			}else{
				setCanMintPlusOne(false);
			}
		}

	//	console.log('PP',freeMintIsOn);

		if(freeMintIsOn){
			setFreeMintCounter(new Date(presaleEnd * 1000));
		}

		setPlusOneSaleOn(freeMintIsOn);

		if(saleIsOver){
			setDiscountPrice(0.000001);
			setSalePrice(0.00001);
		}else{
			setDiscountPrice(ethers.utils.formatEther(_discountPrice));
			setSalePrice(ethers.utils.formatEther(fullPrice));
		}


		/* setPreSaleStarted(presaleIsOn);
		setPreSaleFinished(presaleIsOver); */
		setPreSaleStarted(true);
		setPreSaleFinished(true);

		setMainSaleStarted(saleIsOn);
		setMainSaleFinished(saleIsOver);

		//console.log(presaleStart,presaleEnd,saleStart,saleEnd,maxTokens,fullPrice,discountPrice,userMinted);
		/* 
				console.log('presaleIsOver',presaleIsOver,presaleEnd-now);
				console.log('saleIsOver',saleIsOver,saleEnd-now);
				console.log('presaleIsOn',presaleIsOn,now>=presaleStart,presaleIsOver);
				console.log('saleIsOn',saleIsOn,now>=saleStart, !saleIsOver); */

		setPresaleStartTime(new Date(presaleStart * 1000));
		setPresaleEndTime(new Date(presaleEnd * 1000));
		setSaleStartTime(new Date(saleStart * 1000));
		setSaleEndTime(new Date(saleEnd * 1000));

		if (!presaleIsOn && !presaleIsOver) {
			setPresaleTimeCounter(new Date(presaleStart * 1000));
		} else {
			//console.log('presale over, or on');
			if (!presaleIsOver) {
				setPresaleTimeCounter(new Date(presaleEnd * 1000));
			}
		}

		if (!saleIsOn && !saleIsOver) {
			setSaleTimeCounter(new Date(saleStart * 1000));
		} else {
			//console.log('sale over, or on');
			if (!saleIsOver) {
				setSaleTimeCounter(new Date(saleEnd * 1000));
			}
		}
		setIsLoading(false);
	}


	const handleDiscountMint = async () => {
		// try {
		// 	GA4.event('buy_nft_with_ether', 'Mint', 'Mint Nft with discount')
		// } catch (error) {
		// 	console.log(error)
		// }
		let maxMintable = 0;

		//mintInfo =  await saleContract.checkDiscountAvailable(address);

		const userParams = getUserParams();

		if (!userParams) {
			setShowErrorPopup(true);
			return;
		}

		const alreadyMintedByWallet = await saleContract._mintedByWallet(address).catch(e => console.log);

		//console.log('minted by wallet',alreadyMintedByWallet);

		if (alreadyMintedByWallet) {
			maxMintable = userParams.params.max_mint - Number(alreadyMintedByWallet);
		}

		//console.log('maxMintable', maxMintable);

		if (maxMintable < 1) {
			toast.error('You have already used up your presale quota.');
			return;
		}
		setUserMaxDiscountMintable(maxMintable);
		setCheckoutIsPresale(true);
		setIsCreditCard(false);
		setShowCheckout(true);

	}

	const handleMint = async () => {
		// try {
		// 	GA4.event('buy_nft_with_ether', 'Mint', 'Buy nft')
		// } catch (error) {
		// 	console.log(error)
		// }
		setApproveInProgress(true);
		//console.log('!!!!!!!max tokens per adddres', maxTokenPerAddress);
		const alreadyMintedByWallet = await saleContract._mintedByWallet(address).catch(e => console.log);

		if (alreadyMintedByWallet) {
			let maxMintableMainSale = maxTokenPerAddress - Number(alreadyMintedByWallet);
			if (maxMintableMainSale > 0) {
				setMaxMintableDuringMainSale(maxMintableMainSale < maxMintPerTransaction ? maxMintableMainSale : maxMintPerTransaction);
				if (userIsEcHolder) {
					setUserMaxDiscountMintable(maxMintableMainSale < maxMintPerTransaction ? maxMintableMainSale : maxMintPerTransaction);
				}
				setCheckoutIsPresale(userIsEcHolder);//!!! ec holder hack. it should be false otherwise 
				setIsCreditCard(false);
				setApproveInProgress(false);
				setShowCheckout(true);
			} else {
				setApproveInProgress(false);
				toast.error('You have alredy used up your quota.');
			}
		} else {
			setApproveInProgress(false);
			console.log('can\'t get already minted tokens');
		}



	}

	const mintDisco = async (amount, price) => {

		let sc = saleContract.connect(ethersProvider.getSigner());

		setShowCheckout(false);
		//	console.log(amount, price , '@!#!  #!@ #!@ #!@ !@# !@ ## !@#!@ !@ ');
		//	console.log(amount * price);
		setApproveInProgress(true);
		setselectedAmount(amount);
		setprice(price)
		let userParams = getUserParams();

		console.log('UPARAMS',userParams.params.eth_price);

		if (!userParams) {
			return;
		}
		let tx = null;
		tx = await sc.mint_approved(
			[...userParams.raw_params, userParams.signature],
			amount,
			{
				value: userParams.params.eth_price, //ethers.utils.parseEther(price.toString()),
			}).catch(handleError);

		setApproveInProgress(false);

		if (tx) {
			setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
			setTxInProgress(true);
			await tx.wait().catch(e => { handleError(e); setTxInProgress(false); });
			setTxInProgress(false);
			getSaleInfo();
			setThankyouModalIsOpen(true)
			setTab(1); //-> wallet

			localStorage.setItem('activeTab', 1);
		}
		// console.log(tx);
	}

	const handleCreditCard = async () => {
		// try {
		// 	GA4.event('click', 'payWithCard', 'Pay with credit card')
		// } catch (error) {
		// 	console.log(error);
		// }

		//	console.log('CC');
		setMaxMintableDuringMainSale(5);
		setCheckoutIsPresale(false);
		setApproveInProgress(false);
		setIsCreditCard(true);
		setShowCheckout(true);
	}

	const mintRegular = async (amount, price) => {
		// try {
		// 	GA4.event('click', 'Purchase NFT', 'Purchase NFT')
		// } catch (error) {
		// 	console.log(error)
		// }
		//console.log(amount * price);
		let sc = saleContract.connect(ethersProvider.getSigner());
		setselectedAmount(amount);
		setprice(price)
		//console.log(amount, price, '!@#! @# !@ !@ #! @#!@ #!@# !@')

		setShowCheckout(false);
		setApproveInProgress(true);
		const tx = await sc.mint(amount, { value: ethers.utils.parseEther(price.toString()) }).catch(handleError);
		setApproveInProgress(false);

		if (tx) {
			setTxEtherScan(`${config.ETHERSCAN_URL}/tx/${tx.hash}`);
			setTxInProgress(true);
			await tx.wait().catch(e => { handleError(e); setTxInProgress(false); });
			setTxInProgress(false);
			getSaleInfo();
			setThankyouModalIsOpen(true)
			setTab(1); //-> wallet
			localStorage.setItem('activeTab', 1);
		}
	}

	const handleError = (e) => {
		console.error(e);
		if (e.error && e.error.message) {
			toast.error(e.error.message);
		} else if (e.message) {
			toast.error(e.message);
		} else if (e.reason) {
			toast.error(e.reason);
		}
	};

	return (
		<Box sx={sx.saleRoot}>
			{(!preSaleFinished) &&
				<>
					<DecoratedTitle>
						{saleLabels.preSaleTitle + ' ' + ((!preSaleStarted && !preSaleFinished) ? 'is Coming Soon' : preSaleStarted ? 'is Live' : preSaleFinished ? 'Ended' : '')}
					</DecoratedTitle>

					<Typography
						sx={sx.description}
						style={{ marginTop: '3rem', marginBottom: '3rem' }}
					>
						Pre-sale is for whitelisted addresses and Ether Cards holders only. Connect your wallet and mint a maximum 5 tokens per wallet for a price of .06 ETH per token.<br />Ether Cards holders get automatic discounts: 15% OG, 10 % Alpha, 5% Founder.
					</Typography>
				</>
			}

			{(mainSaleStarted) &&
				<>
					<DecoratedTitle>
						{saleLabels.publicSaleTitle + ' ' + ((!mainSaleStarted && !mainSaleFinished) ? 'is Coming Soon' : mainSaleStarted ? 'is Live' : mainSaleFinished ? 'Ended' : '')}
					</DecoratedTitle>
					<div style={{ marginTop: "60px" }} />
				</>
			}



			<Box sx={sx.infoContainer}>

				<Box sx={sx.badgeContainer}>
					<InfoBox label="ITEMS" value={totalAmount} sx={sx.badgeLeft} />
					{minted > 0 && (
						<InfoBox label="MINTED" value={minted} sx={sx.badgeCenter} />
					)}

					{/* {plusOneSaleOn? */}
					{/* <InfoBox
						label="PRICE"
						value="FREE"
						sx={sx.badgeRight}
					/> */}
					{/* :
					<InfoBox
						label="PRICE"
						value={((mainSaleStarted || mainSaleFinished) && !userIsEcHolder) ? salePrice : discountPrice}
						eth
						sx={sx.badgeRight}
					/>} */}

				</Box>
			</Box>

			<ButtonGroup
				sx={sx.tabContainer}
				variant="outlined"
				size="medium"
				aria-label="medium outlined button group"
			>
				<Button
					sx={{
						...sx.tabBtns,
						...(tab === 0 && sx.tabSelected),
					}}
					onClick={() => { setTab(0); localStorage.setItem('activeTab', 0); }}
				>
					SALE INFO
				</Button>
				<Button
					sx={{ ...sx.tabBtns, ...(tab === 1 && sx.tabSelected) }}
					onClick={() => { setTab(1); localStorage.setItem('activeTab', 1); }}
				>
					COLLECTION WALLET
				</Button>
			</ButtonGroup>





			{tab === 0 ? <>
				{/* PRESALE */}

				{(!preSaleFinished && !plusOneSaleOn) &&
					<>


						{preSaleStarted &&
							<Typography sx={sx.description} style={{ marginTop: '0', marginBottom: '1rem' }}>
								Pre-Sale ends in
							</Typography>
						}

						<Counter
							date={presaleTimeCounter}
							isActive={preSaleStarted}
							onFinish={() => { console.log('presale timer is up'); getSaleInfo(); }}
							id="presale"
						/>

						<Box sx={sx.timeContainer}>
							<Timer
								time={
									<>
										{presaleStartTime ? new DateTime.fromMillis(presaleStartTime.getTime()).setZone('America/New_York').toFormat('dd. MM. hha') + ' EDT' : ''} - {presaleEndTime ? new DateTime.fromMillis(presaleEndTime.getTime()).setZone('America/New_York').toFormat('dd. MM. hha') + ' EDT' : ''}
									</>
								}
							/>

						</Box>
						<p style={{ marginTop: "15px", color: '#D2D4C2', }}>{saleLabels.preSaleDescription}</p>

						{preSaleStarted && (
							<Box mt={2}>
								<Button
									sx={sx.buyButton}
									style={{ margin: "10px" }}
									variant="banner"
									onClick={handleDiscountMint}
									disabled={!address || isLoading}
								>
									BUY NFT
								</Button>
								{!address && (
									<Button
										variant="banner"
										sx={sx.buyButton}
										style={{ margin: "10px" }}
										onClick={handleConnect}
									>
										CONNECT WALLET
									</Button>
								)}
							</Box>
						)}
					</>
				}

				{/* SALE */}
				{(!mainSaleFinished  && !plusOneSaleOn) && <>
					{(!mainSaleStarted) &&
						<DecoratedTitle style={{ marginTop: '3rem' }}>
							{saleLabels.publicSaleTitle + ' is Coming Soon'}
						</DecoratedTitle>
					}


					{mainSaleStarted &&
						<Typography sx={sx.description} style={{ marginBottom: '1rem' }}>
							It ends in
						</Typography>
					}

					{preSaleFinished && <Counter
						date={saleTimeCounter}
						isActive={mainSaleStarted}
						onFinish={() => { console.log('sale timer is up'); getSaleInfo() }}
						id="sale"
					/>}

					{(!mainSaleStarted && !mainSaleFinished) &&
						<Typography sx={sx.description} style={{ marginTop: '15px', marginBottom: '1rem' }}>
							It starts at
						</Typography>}


					<Box sx={sx.timeContainer}>
						<Timer
							time={
								<>
									{saleStartTime ? new DateTime.fromMillis(saleStartTime.getTime()).setZone('America/New_York').toFormat('dd. MM. hha') + ' EDT' : ''} - {saleEndTime ? new DateTime.fromMillis(saleEndTime.getTime()).setZone('America/New_York').toFormat('dd. MM. hha') + ' EDT' : ''}
								</>
							}

						/>
					</Box>
					<p style={{ marginTop: "15px", color: '#D2D4C2', }}>{saleLabels.publicSaleDescription}</p>


					{mainSaleStarted && (
						<Box mt={2}>
							{address && <Button
								sx={sx.buyButton}
								style={{ margin: "10px 10px 30px 10px" }}
								variant="banner"
								onClick={handleMint}
								disabled={!address || isLoading}
							>
								BUY NFT
							</Button>}

							{!address && (

								<Button
									sx={sx.buyButton}
									variant="banner"
									style={{ margin: "10px 10px 30px 10px" }}
									onClick={handleConnect}
								>
									CONNECT WALLET
								</Button>

							)}
							<Button
								sx={sx.buyButton}
								variant="banner"
								style={{ margin: "10px 10px 30px 10px" }}
								onClick={handleCreditCard}
							>
								PAY WITH CREDIT CARD
							</Button>





						</Box>
					)}
				</>}

				{(preSaleFinished && mainSaleFinished) &&
					<>
						<DecoratedTitle style={{ marginTop: '1rem' }}>
							The sale is over
						</DecoratedTitle>
						<Typography
							sx={sx.description}
							style={{ marginTop: '3rem', marginBottom: '3rem' }}
						>
							Missed our NFT sale? Check out the Collection on{" "}

							{tokenAddress === "0xeaf10ed6e870ab0743a6bfcb9c414ceff63a3f5f" ?

								<Box
									component="a"
									href={"https://opensea.io/collection/girls-robots-dragons-official"}
									sx={sx.url}
									target="_blank"
									rel="noopener noreferrer"
								>
									OpenSea
								</Box>
								:
								<Box
									component="a"
									href={`https://opensea.io/collection/girls-robots-dragons-official`}
									sx={sx.url}
									target="_blank"
									rel="noopener noreferrer"
								>
									OpenSea
								</Box>
							}

						</Typography>


					</>
				}


				{(plusOneSaleOn) &&
					<>
						<DecoratedTitle style={{ marginTop: '1rem', marginBottom:'3rem' }}>
						 FREE Mint 
						</DecoratedTitle>

						<Counter
							date={freeMintCounter}
							isActive={plusOneSaleOn}
							onFinish={() => { console.log('free mint timer is up'); getSaleInfo(); }}
							id="presale"
						/>

						<Typography
								sx={sx.description}
								style={{ marginTop: '3rem', marginBottom: '1rem' }}
							>
							All GRD holder addresses who owned at least 1 GRD NFT when the sale ended on 18.07.2022 9AM EDT are eligible to mint 1 FREE GRD card. Connect your wallet and mint yours now before the time runs out.
						</Typography>



							{isGrdHolder?
								<Box mt={2}>

								{canMintPlusOne?
									<Button
										sx={sx.buyButton}
										style={{ margin: "10px" }}
										variant="banner"
										onClick={(e)=>mintDisco(1,1)}
										disabled={!address || isLoading}
									>
										MINT 1 GRD
									</Button>
									:
									<Typography
										sx={sx.description}
										style={{ marginTop: '1rem', marginBottom: '1rem' }}
									>
										It seems you already have claimed your free GRD NFT. DUST Minting is coming soon!
									</Typography>
								}


								{!address && (
									<Button
										variant="banner"
										sx={sx.buyButton}
										style={{ margin: "10px" }}
										onClick={handleConnect}
									>
										CONNECT WALLET
									</Button>
								)}
							</Box>

							:
							<>
							{!address? 
								<Button
									variant="banner"
									sx={sx.buyButton}
									style={{ margin: "10px" }}
									onClick={handleConnect}
								>
									CONNECT WALLET
								</Button>
								
								:
								<>
								{(address && !isLoading) &&<Typography
									sx={sx.description}
									style={{ marginTop: '1rem', marginBottom: '1rem' }}
								>
									You are not a GRD holder.
								</Typography>}
								</>
							}		
							</>
							
							}
					
					</>
				}

			</>
				:
				<>
					<Wallet tokenContract={tokenContract} />
				</>}

			<WhatsNext />
			
			<DecoratedTitle style={{ marginTop: '30px' }}>
				THE PRIZE POOL
			</DecoratedTitle>
			<Typography
				sx={sx.description}
				style={{ marginTop: '3rem', marginBottom: '3rem' }}
			>
				The GRD Collection Prize Pool contains 20% of primary sales and 25% of secondary sales. Check out the <a href="/#/#game">Game section</a> for more detailed prize information.
			</Typography>

			<Box sx={sx.infoContainerPool}>
				<Box sx={sx.badgeContainer}>
					<InfoBox
						label="POOL VALUE"
						value={poolValue}
						eth
						sx={sx.badgeRight}
					/>
				</Box>

				<Box sx={sx.poolCounter}>
					<Typography sx={sx.poolCounterTitle}>CARDS SOLD</Typography>
					<Typography sx={sx.poolCounterValue}>{minted}/{totalAmount}</Typography>
					<ProgressBar maxValue={totalAmount} value={minted} />

				</Box>
			</Box>










			<PermissionErrorModal
				isOpen={showErrorPopup}
				setOpen={setShowErrorPopup}
			/>

			<CheckoutModal
				tokenName={symbol}
				isOpen={showCheckout}
				setOpen={() => {
					if (!txInProgress && !approveInProgress) {
						setShowCheckout(false);
					}
				}}
				isPresale={checkoutIsPresale}
				withCreditCard={isCreditCard}
				whitelistLimit={checkoutIsPresale ? userMaxDiscountMintable : maxMintableDuringMainSale}
				salePrice={salePrice}
				presalePrice={discountPrice}
				mintSale={mintRegular}
				mintPresale={mintDisco}
			/>
			<BackdropModal isOpen={approveInProgress} />

			<TxProgressModal isOpen={txInProgress} txEtherScan={txEtherScan} />
			<ThankYouModal isOpen={thankyouModalIsOpen} setOpen={setThankyouModalIsOpen} price={price} selectedAmount={selectedAmount} />
		</Box>
	);
}

export default Sales;