import React, { useEffect, useState } from 'react';
import { Box, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import theme from './theme';
import routes from './navigation/routes';
import { HashRouter } from 'react-router-dom';
import NotFound from './NotFound';
import CookieModal from './Components/CookieModal';
import Web3Manager from './Components/Web3Manager/Web3Manager';
import { ToastContainer } from 'react-toast';

// import ReactGA from 'react-ga';
// import GA4React from "ga-4-react";

// const TRACKING_ID = 'G-1QGHZHFE9B';

// const ga4react = new GA4React(TRACKING_ID);


const sx = {
  root: {
    position: 'relative',
    width: '100%',
    maxWidth: '1280px',
    margin: 'auto',
    height: '100%',
    minHeight: '100vh',
    backgroundImage: "linear-gradient(#111c1dbf, #152D2E)"
  },
};

const App = () => {
  const hash = window.location.hash.substr(0, window.location.hash.indexOf('?'));
  const [open, setOpen] = useState(false);
  const storageItem = window.location.hash === '#/explorer' || hash === '#/render' ? undefined : localStorage.getItem('cookieAccepted') === 'true';

  const acceptCoockie = async () => {
    localStorage.setItem('cookieAccepted', true);
    setOpen(false);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };
  useEffect(() => {
    (async () => {
      if (storageItem === true) {
        setOpen(false);
        // ReactGA.pageview(window.location.pathname + window.location.search);
        // try {
        //   await ga4react.initialize().then().catch()
        // } catch (error) {
        //   console.log(error)
        // }
      } else {
        if (window.location.hash !== '#/privacy-policy' && (window.location.hash !== '#/explorer' || hash !== '#/render')) {
          setOpen(true);
        }
      }
    })()

  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Web3Manager>
        <Box sx={sx.root}>
          <HashRouter basename="/">
            <Switch>
              {routes.map(({ path, component, exact }) => (
                <Route
                  key={path}
                  path={path}
                  component={component}
                  exact={exact}
                />
              ))}
              <Route component={NotFound} />
            </Switch>
          </HashRouter>
          <ToastContainer delay={4000} position="bottom-right" />
        </Box>
        <CookieModal
          isOpen={open}
          setOpen={setOpen}
          acceptCoockie={acceptCoockie}
        />
      </Web3Manager>
    </ThemeProvider>
  );
};

export default App;
