import { useRef } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import terms from "../constant/DustTerms";
import closeIcon from '../../../../assets/images/white_close.svg'
import { Box, Button } from "@mui/material";
import './DustTnc.css'
import PopupModal from "../PopupModal";

const Wrapper = styled.div`
  padding: 100px 0;
  position: fixed;
  display: ${({ show }) => (show ? "visible" : "none")};
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.9);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: auto;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  color: white;
  font-weight: 400;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  font-family: 'bau'
`;

const CloseButtonWrapper = styled.div`
  position: fixed;
  cursor: pointer;
  top: 5%;
  right: 5%;
  width: 30px;
  height: 30px;

  &:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
  }
  @media (max-width: 576px):{
    width: 16px;
    height: 16px;
  }
  @media (max-width: 768px):{
    width: 24px;
    height: 24px;
  }@media (max-width: 992px):{
    width: 30px;
    height: 30px;
  }
`;

const ButtonHolder = styled.div`
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center
`;

const CloseIcon = styled.img`
  color: #fff;
  text-shadow: 0 1px 0 #333;
  opacity: 1;
`;


const BP1 = '@media (max-width: 899px)';
const BP2 = '@media (max-width: 719px)';
const BP3 = '@media (max-width: 600px)';

const sx = {
  root: {
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.3)',
    py: '44px',
    px: '125px',
    position: 'relative',
    display: 'flex',
    // pointerEvents: "none",
    transition: 'all .3s',
    [BP1]: {
      px: '85px',
    },
    [BP2]: {
      px: '25px',
    },
  },
  container: {
    maxHeight: '80vh',
    minHeight: '150px',
    position: 'relative',
    margin: 'auto',
    width: '100%',
    maxWidth: '500px',
    borderRadius: '16px',
    border: '1px solid #050000',
    overflow: 'hidden',
    transition: 'all .3s',
    backgroundColor: '#D2D4C2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    px: '30px',
    py: '20px',
    textAlign: 'center',
    outline: 'solid 2px #D2D4C2',
    outlineOffset: '12px',
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  closeBtn: {
    position: 'absolute',
    width: '14px',
    height: '14px',
    top: '38%',
    bottom: '0',
    right: '5px',
    cursor: 'pointer',
    pointerEvents: 'auto',
    transition: 'all .3s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  header: {
    fontFamily: 'boucherie-block',
    position: 'relative',
    width: '100%',
    minHeight: '50px',
    color: '#FF692B',
  },
  body: {
    width: '100%',
    minHeight: '50px',
    fontFamily: 'poppins',
    fontSize: '16px',
  },
  footer: {
    width: '100%',
    minHeight: '10px',
  },
  contentHolder: {
    margin: 'auto',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    overflowY: 'auto',
    maxHeight: '500px',
    pr: '10px'
  },
  button: {
    margin: '0'
  }
};

const DustTnc = ({ showTnc, hideTnc, handleClaim, claimData }) => {
  const ref = useRef();
  ref && ref.current && ref.current.scrollTo(0, 0);

  const checkClaimData = typeof claimData == "object" && showTnc;

  return (

    <PopupModal setOpen={hideTnc} isOpen={showTnc} header={"Terms & Conditions"}>
      <Box sx={sx.contentHolder} className='dust-terms-scoll-box'>
        <ReactMarkdown children={terms} className="markdown" />

        <ButtonHolder>
          <Button variant="contained" sx={sx.button} onClick={() => hideTnc()}>Back</Button>
          {checkClaimData && (
            <Button variant="contained" sx={sx.button} onClick={() => { handleClaim() }}>
              Accept All
            </Button>
          )}
        </ButtonHolder>
      </Box>
    </PopupModal>
  );
};

export default DustTnc;
