const dev = {
  DEPLOYED_NTW_NAME: 'goerli',
  DEPLOYED_CHAIN_ID: 5,
  POLYGON_ID: "80001",
  INFURA_ID: 'a5e79e6ee9a14236b385e47849805596',
  FORTMATIC_KEY: 'pk_test_DD2BBA8AAA1D4FED',
  RPC_URL: 'https://goerli.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc',
  POLYGON_RPC: 'https://polygon-mumbai.g.alchemy.com/v2/wq5i4ISC7SMlpOeWuaAwYijDh8m3oA7l',
  ETHERSCAN_URL: 'https://goerli.etherscan.io/',
  OPENSEA_URL: 'https://testnets.opensea.io/',
  API_URL: 'https://cms.staging.galaxis.xyz',
  PREVIEW_URL: 'https://ssp-server-v2.herokuapp.com/api/project-session',
  AWS_URL:
    'https://galaxis-backend-staging.s3.eu-central-1.amazonaws.com/media',

  AGGREGATOR_URL: 'https://nft-aggregator-rinkeby.herokuapp.com/token',

  EC_TOKEN_ADDRESS: '0xAAD4475343f5150E33d6194270f04e7e5968A2f8',
  PHOENIX_CONTRACT_ADDRESS: '0x0937aD2d26D1f37c2d8FdA1b4c071e8f3786aF5B',

  //GRD_SALE_CONTRACT: '0x59869C6233E93a6541fEF1e67bB455C888F34795', 
  //GRD_TOKEN_CONTRACT: '0x115C84C6573Ed38655CEbBE5B3FC5F0B09fA75C5',
  
  GRD_SALE_CONTRACT: '0xe1719a5Cc1598d77a6BCD20db1CA2632Bca1Dc68', 
  //GRD_TOKEN_CONTRACT: '0xF8cBa99A9b150819A782b5a9DCC63Ce1Ea1E652a',
  GRD_TOKEN_CONTRACT: '0x372ecd707e4F640773228E0B169D295E303D2377',

  POOL_ADDRESS: "0xd25f03bdc4727b43bf09ace9cf25c5dea21d1532",
  
  CROSSMINT_CLIENT_ID: '7de2bbab-cec3-456b-a2e1-96bbd99cb8ad',
  CROSSMINT_ENV: 'staging'
 
};

const prod = {
  DEPLOYED_NTW_NAME: 'mainnet',
  DEPLOYED_CHAIN_ID: 1,
  POLYGON_ID: "137",
  INFURA_ID: 'a5e79e6ee9a14236b385e47849805596',
  FORTMATIC_KEY: 'pk_live_FBFF1F05F2879F29',
  RPC_URL: 'https://mainnet.infura.io/v3/8497b3987c8146359f3b78169351070b',
  POLYGON_RPC: 'https://polygon-mainnet.infura.io/v3/8497b3987c8146359f3b78169351070b',
  ETHERSCAN_URL: 'https://etherscan.io/',
  OPENSEA_URL: 'https://opensea.io/',

  API_URL: 'https://cms.galaxis.xyz',
  PREVIEW_URL: 'https://launchpad-backend.herokuapp.com/api/project-session',
  AWS_URL: 'https://galaxis-web.s3.amazonaws.com/media',

  AGGREGATOR_URL: 'https://nft-aggregator.herokuapp.com/token',

  EC_TOKEN_ADDRESS: '0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8',
  PHOENIX_CONTRACT_ADDRESS: '0x55B3154Ad761405B0cdd27355943Eb808d40b5A1',

  GRD_SALE_CONTRACT: '0x6022976072af9FA444Be007110bcBE7D4B94Bb57',
  GRD_TOKEN_CONTRACT: '0x0847d1fB2AdC3D1C19B233AFB7d01a631728A415',
  POOL_ADDRESS: "0x96b84cD0121F875175E13cc1F2679D674f9E505B",

  CROSSMINT_CLIENT_ID: 'b3f5189b-66fe-43d7-8295-c73f9fa3e3ef',
  CROSSMINT_ENV: ''

};

const common = {
  OPENSEA_COLLECTION: 'https://api.opensea.io/api/v1/collection',
  GOOGLE_API: 'https://www.googleapis.com/youtube/v3/search',
  RECAPCHA_SITEKEY: '6LfAjcYeAAAAAJTxnTgx_JVndCSmQgU1gqzEIwoL',
  NO_PROJECT_FOUND: 'no project found',
  TWITTER_BEARER: 'AAAAAAAAAAAAAAAAAAAAALgpeAEAAAAAZrkXeVloqp9mH%2FBow0DOFVwkBb8%3DVYUqhpS9rihI14Hd10Y9lqUOZV9lF3c3r20dHQMVJmSyytt809',
  TWITTER_API: 'https://api.twitter.com/2/users/1507357310624182281',
  
};

// if use npm/yarn start,  NODE_ENV = "development"
// if use npm/yarn build,  NODE_ENV = "production"
let envConfig = prod;   // process.env.NODE_ENV === "development" ? dev : prod
let config = { ...envConfig, ...common };

export default config;
