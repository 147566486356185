import dragon1 from '../../assets/images/carousel/dragon1.jpg';
import dragon2 from '../../assets/images/carousel/dragon2.jpg';
import dragon3 from '../../assets/images/carousel/dragon3.jpg';
import dragon4 from '../../assets/images/carousel/dragon4.jpg';
import dragon5 from '../../assets/images/carousel/dragon5.jpg';

import girl1 from '../../assets/images/carousel/girl1.jpg';
import girl2 from '../../assets/images/carousel/girl2.jpg';
import girl3 from '../../assets/images/carousel/girl3.jpg';
import girl4 from '../../assets/images/carousel/girl4.jpg';
import girl5 from '../../assets/images/carousel/girl5.jpg';

import robot1 from '../../assets/images/carousel/robot1.jpg';
import robot2 from '../../assets/images/carousel/robot2.jpg';
import robot3 from '../../assets/images/carousel/robot3.jpg';
import robot4 from '../../assets/images/carousel/robot4.jpg';
import robot5 from '../../assets/images/carousel/robot5.jpg';

import image1 from '../../assets/images/redeemables/01exigo.jpg';
import image2 from '../../assets/images/redeemables/02exigo.jpg';
import image3 from '../../assets/images/redeemables/06exigo.jpg';
import image4 from '../../assets/images/redeemables/07exigo.jpg';
import image5 from '../../assets/images/redeemables/08exigo.jpg';
import image6 from '../../assets/images/redeemables/09exigo.jpg';
import image7 from '../../assets/images/redeemables/13exigo.jpg';
import image8 from '../../assets/images/redeemables/14exigo.jpg';
import image9 from '../../assets/images/redeemables/15exigo.jpg';
import image10 from '../../assets/images/redeemables/16exigo.jpg';

import side_image1 from '../../assets/images/arts/originalArts/img1.jpg';
import side_image2 from '../../assets/images/arts/originalArts/img2.jpg';
import side_image3 from '../../assets/images/arts/originalArts/img3.jpg';
import side_image4 from '../../assets/images/arts/originalArts/img4.jpg';
import side_image5 from '../../assets/images/arts/originalArts/img5.jpg';
import side_image6 from '../../assets/images/arts/originalArts/img6.jpg';
import side_image7 from '../../assets/images/arts/originalArts/img7.jpg';
import side_image8 from '../../assets/images/arts/originalArts/img8.jpg';
import side_image9 from '../../assets/images/arts/originalArts/img9.jpg';
import side_image10 from '../../assets/images/arts/originalArts/img10.jpg';

import rules_girl1 from '../../assets/images/rules/girl1.jpg';
import rules_girl2 from '../../assets/images/rules/girl2.jpg';
import rules_girl3 from '../../assets/images/rules/girl3.jpg';
import rules_girl4 from '../../assets/images/rules/girl4.jpg';
import rules_girl5 from '../../assets/images/rules/girl5.jpg';

import rules_dragon1 from '../../assets/images/rules/dragon1.jpg';
import rules_dragon2 from '../../assets/images/rules/dragon2.jpg';
import rules_dragon3 from '../../assets/images/rules/dragon3.jpg';
import rules_dragon4 from '../../assets/images/rules/dragon4.jpg';
import rules_dragon5 from '../../assets/images/rules/dragon5.jpg';

import rules_robot1 from '../../assets/images/rules/robot1.jpg';
import rules_robot2 from '../../assets/images/rules/robot2.jpg';
import rules_robot3 from '../../assets/images/rules/robot3.jpg';
import rules_robot4 from '../../assets/images/rules/robot4.jpg';
import rules_robot5 from '../../assets/images/rules/robot5.jpg';

import art1 from '../../assets/images/arts/445x600/img1.jpg';
import art2 from '../../assets/images/arts/445x600/img2.jpg';
import art3 from '../../assets/images/arts/445x600/img3.jpg';
import art4 from '../../assets/images/arts/445x600/img4.jpg';
import art5 from '../../assets/images/arts/445x600/img5.jpg';
import art6 from '../../assets/images/arts/445x600/img6.jpg';
import art7 from '../../assets/images/arts/445x600/img7.jpg';
import art8 from '../../assets/images/arts/445x600/img8.jpg';
import art9 from '../../assets/images/arts/445x600/img9.jpg';
import art10 from '../../assets/images/arts/445x600/img10.jpg';

export const collection = [
  dragon1,
  dragon2,
  dragon3,
  dragon4,
  dragon5,
  robot1,
  robot2,
  robot3,
  robot4,
  robot5,
  girl1,
  girl2,
  girl3,
  girl4,
  girl5,
];
export const collections = [
  {
    id: 0,
    img: dragon1,
  },
  {
    id: 1,
    img: dragon2,
  },
  {
    id: 2,
    img: dragon3,
  },
  {
    id: 3,
    img: dragon4,
  },
  {
    id: 4,
    img: dragon5,
  },
  {
    id: 5,
    img: robot1,
  },
  {
    id: 6,
    img: robot2,
  },
  {
    id: 7,
    img: robot3,
  },
  {
    id: 8,
    img: robot4,
  },
  {
    id: 9,
    img: robot5,
  },
  {
    id: 10,
    img: girl1,
  },
  {
    id: 11,
    img: girl2,
  },
  {
    id: 12,
    img: girl3,
  },
  {
    id: 13,
    img: girl4,
  },
  {
    id: 14,
    img: girl5,
  },
];

export const artImages = [
  {
    id: 1,
    img: art1,
  },
  {
    id: 2,
    img: art2,
  },
  {
    id: 3,
    img: art3,
  },
  {
    id: 4,
    img: art4,
  },
  {
    id: 5,
    img: art5,
  },
  {
    id: 6,
    img: art6,
  },
  {
    id: 7,
    img: art7,
  },
  {
    id: 8,
    img: art8,
  },
  {
    id: 9,
    img: art9,
  },
  {
    id: 10,
    img: art10,
  },
];

export const galleryImages = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
];

export const slideImages = [
  side_image1,
  side_image2,
  side_image3,
  side_image4,
  side_image5,
  side_image6,
  side_image7,
  side_image8,
  side_image9,
  side_image10,
];

export const girlImages = [
  rules_girl1,
  rules_girl2,
  rules_girl3,
  rules_girl4,
  rules_girl5,
];
export const dragonImages = [
  rules_dragon1,
  rules_dragon2,
  rules_dragon3,
  rules_dragon4,
  rules_dragon5,
];
export const robotImages = [
  rules_robot1,
  rules_robot2,
  rules_robot3,
  rules_robot4,
  rules_robot5,
];
