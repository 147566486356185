import React, { useState, useEffect } from 'react';
import useWeb3Ctx from '../../../hooks/useWeb3Ctx';
import { useZoom2Contract, useZoom2PolygonContract } from '../../../hooks/useContract';
import { getTokenIds, zoomFetchTokenUris } from '../../../utils/zoom2';

import { SpinnerCircularFixed as Spinner } from 'spinners-react';
import { Box, Typography, Button, Grid, ButtonGroup } from '@mui/material';

import tokenABI from '../abi/Token.json';
import { Contract, providers } from 'ethers';
import NftGallery from './NftGallery';
import RenderModal from '../../../View/Explorer/Components/RenderModal';
import { getTraitTypes } from '../../../Api';
import useSorterByCollection from '../../../hooks/useSorterByCollection';
import CollectionWallet from './CollectionWallet';
import SubmitModal from './Modals/SubmitModal';
import collectionWallet from '../../../assets/images/collection_wallet.svg';
import Decoration from '../../Decoration';
import polygonContract from '../abi/constants/PolygonContract.json'
import polygonAbi from '../abi/PolygonAbi.json'
import config from '../../../config/config';
import { getAllDustAvailable } from '../../../View/ExplorerCard/Components/Dust/getDust';
import DustSection from './Dust/DustSection';
import { ZOOM_2_ADDRESSES } from '../abi/constants/addresses';
import { abi as ZOOM2_ABI } from '../../../Components/SaleComponent/abi/Zoom2.json';
const BP3 = '@media (max-width: 384px)';
const BP4 = '@media (max-width: 480px)';

const sx = {
  root: {
    position: 'relative',
    minHeight: '30vh',
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'top',
    textAlign: 'center',
    gap: '0 25px',
    mb: 4,
  },
  title: {
    marginTop: '8px',
    textAlign: 'center',
    fontFamily: 'poppins-semibold',
    fontSize: '36px',
  },
  connectBtn: {
    height: '60px',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '1.75',
    textTransform: 'uppercase',
    padding: '6px 16px',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    backgroundColor: '#FF692B',
    border: '2px solid #FF692B',
    fontFamily: 'boucherie-block',
    color: '#fff',
    '&:hover': {
      border: '2px solid #fff',
      backgroundColor: '#fff',
      color: '#FF692B',
    },
    '&:disabled': {
      opacity: '0.7',
    },
    [BP3]: {
      width: '100%',
      px: '14px',
    },
  },
  tabContainer: {
    justifyContent: 'center',
    paddingTop: '40px',
    paddingBottom: '40px',
    maxWidth: '800px',
    mx: 'auto',
    [BP4]: {
      flexWrap: 'wrap',
      gap: '5px',
    },
  },
  tabBtns: {
    color: '#D2D4C2',
    borderColor: '#FF692B',
    borderWidth: '2px',
    padding: '6px 14px',
    fontSize: '20px',
    fontFamily: 'boucherie-block',
    '&:hover': {
      borderColor: '#FF692B',
      backgroundColor: '#fff',
      borderWidth: '2px',
      color: '#FF692B',
      borderRightColor: '#FF692B !important',
    },
    [BP4]: {
      border: '2px solid #FF692B !important',
      borderRadius: '10px !important',
    },
    borderRadius: '10px',
  },
  tabSelected: {
    backgroundColor: '#FF692B',
    color: '#fff',
  },
  walletTitle: {
    fontFamily: 'boucherie-block',
    fontSize: '40px',
    color: '#CACCB5',
    lineHeight: '45px',
    maxWidth: '590px',
    textAlign: 'center',
    margin: 'auto',
    mb: '30px',
  },
  description: {
    fontFamily: 'poppins',
    fontSize: '16px',
    lineHeight: '29px',
    color: '#D2D4C2',
    maxWidth: '750px',
    margin: 'auto',
    mb: '30px',
  },
  button: {
    mb: '30px',
    mt: '30px',
    '&:disabled': {
      backgroundColor: '#FC6405 !important',
      color: '#fff !important',
      opacity: '30%',
    },
  },
  text: {
    fontFamily: 'bau',
    color: '#D2D4C2',
    mb: '30px',
  },
};

const Wallet = ({ tokenContract, tokenAddress, openseaCollection, provider }) => {
  const { onboard, handleConnect, address, ethersProvider, chainId } = useWeb3Ctx();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const zoomContract = useZoom2Contract();
  const polygonZoomcontract = useZoom2PolygonContract();
  const [traitTypes, setTraitTypes] = useState([]);
  const [tokenMetas, setTokenMetas] = useState([]);
  const [osCollection, setOsCollection] = useState(null);
  const [walletDustAmount, setwalletDustAmount] = useState("0")
  const [usedZoomContract, setUsedZoomContract] = useState(null);
  const [polygonTokenContract, setPolygonTokenContract] = useState(undefined)

  const [open, setOpen] = useState(false);
  const [openSubmit, setOpenSubmit] = useState(false);

  const [selectedMetadata, setselectedMetadata] = useState(undefined);
  useEffect(() => {
    console.log('CHAIN ID',chainId);

    if (chainId === 137) {
      const provider = new providers.JsonRpcProvider(config.RPC_URL);
      const zoomContract = new Contract(
        ZOOM_2_ADDRESSES['1'],
        ZOOM2_ABI,
        provider
      );
      console.log(zoomContract)
      setUsedZoomContract(zoomContract);
    } else {
      setUsedZoomContract(zoomContract);
    }
  }, [chainId]);

  useEffect(() => {
    const provider = new providers.JsonRpcProvider(config.POLYGON_RPC);
    // const zoom2Contract = new Contract("0x40faA8d164973Ab39F3cBf6e3876a3130F9c6C77", polygonZoomAbi.ABI, provider)
    
   


    let polygonToken = new Contract(polygonContract[config.POLYGON_ID], polygonAbi.abi, provider);
    
    //console.log('POYGONTOKEN address',polygonToken, provider);

    setPolygonTokenContract(polygonToken);
    if (address) {
      if (tokenContract && usedZoomContract !== null) {
         console.log('WALLET CONTRACT',tokenContract, polygonToken);
        getTokens(tokenContract, polygonToken);
        setTab(0);
      } else {
        if (tokenAddress && usedZoomContract !== null) {
          //init token
          // console.log('init contract');
          const mainProvider = new providers.JsonRpcProvider(config.RPC_URL);
          let token = new Contract(tokenAddress, tokenABI.abi, mainProvider);
          // console.log('the tokeN', token);
          if (!token) {
            console.error('Token contract not found on address', tokenAddress);
            return;
          }
          tokenContract = token;
          getTokens(tokenContract, polygonToken);
          setTab(0);
        } else {
          // console.log('Wallet','no token contract or address');
        }
      }

      if (openseaCollection) {
        setOsCollection(openseaCollection);
      }
    }else{
      setSelectedTab(1);
    }
  }, [address, usedZoomContract,]);

  const getTokens = async (tokenContract, polygonContract) => {
    setLoading(true);
    const t = await getTraitTypes();
    if (t) {
      // console.log('trait types', t);
      setTraitTypes(t);
    }
    //mainnet fetch 
    const { newMetadata: metas } = await zoomFetchTokenUris(
      tokenContract,
      usedZoomContract,
      address,
      true
    );

   // console.log('000000000',polygonContract,polygonZoomcontract,address);

    //polygon fetch
    const { newMetadata: metas2, arrayOfIds } = await zoomFetchTokenUris(
      polygonContract,
      polygonZoomcontract,
      address,
      false
    );
    

    if(metas2){
      metas2.forEach(i=>i.onPolygon=true);
    }


    if (metas || metas2) {
      let sortedList = metas.concat(metas2);
      sortedList.sort((a, b) => {
        return Number(a.tokenId) - Number(b.tokenId)
      })
      setTokenMetas(sortedList);
      console.log('SORTED LIST',sortedList)
    } else {
      setTokenMetas([]);
      arrayOfIds = [];
    }
    setLoading(false);
  };

  const handleClick = (card) => {
    setselectedMetadata(card);
    setOpen(true);
  };
  const handleRefetchMetadatas = () => {
    getTokens(tokenContract, polygonTokenContract);
  }

  const {
    finalCollections: sortedMetadata,
    maxPageCounts,
    getCountPerPage,
  } = useSorterByCollection(tokenMetas);
  const [sortedMetadataArray, setsortedMetadataArray] = useState([]);
  const [selectedmaxPageCount, setSelectedmaxPageCount] = useState(null);
  const [buttonText, setbuttonText] = useState('');
  const [nftsPaginationTab, setnftsPaginationTab] = useState(1);
  const [title, setTitle] = useState('');
  useEffect(() => {
    (async () => {
      if (sessionStorage.getItem('selectedWallet')) {
        let t = Number(localStorage.getItem('activeTab'));
        if (t === 4) {
          setTab(4);
        }
        if (t === 3) {
          setTab(3);
        }
        if (t === 1) {
          setTab(1);
        }
        if (t === 2) {
          setTab(2);
        } else {
          setTab(0);
        }
      }
    })();
  }, []);
  const setSelectedTab = (index) => {
    switch (index) {
      case 0:
        setTab(0);
        localStorage.setItem('activeTab', 0);
        break;
      case 1:
        setTab(1);
        console.log('SORT',sortedMetadata);
        sortedMetadata.map((sort) => {
          if (sort.common) {
            setsortedMetadataArray(sort.common);
            setSelectedmaxPageCount(maxPageCounts[0].common);
            setbuttonText('Submit common collection now');
            setnftsPaginationTab(1);
            setTitle('common');
          }
        });
        break;
      case 2:
        setTab(2);
        localStorage.setItem('activeTab', 2);
        sortedMetadata.map((sort) => {
          if (sort.rare) {
            setsortedMetadataArray(sort.rare);
            setSelectedmaxPageCount(maxPageCounts[1].rare);
            setbuttonText('Submit rare collection now');
            setnftsPaginationTab(1);
            setTitle('rare');
          }
        });
        break;
      case 3:
        setTab(3);
        localStorage.setItem('activeTab', 3);
        sortedMetadata.map((sort) => {
          if (sort.epic) {
            setsortedMetadataArray(sort.epic);
            setSelectedmaxPageCount(maxPageCounts[2].epic);
            setbuttonText('Submit epic collection now');
            setnftsPaginationTab(1);
            setTitle('epic');
          }
        });
        break;
      case 4:
        setTab(4);
        localStorage.setItem('activeTab', 4);
        sortedMetadata.map((sort) => {
          if (sort.legendary) {
            setsortedMetadataArray(sort.legendary);
            setSelectedmaxPageCount(maxPageCounts[3].legendary);
            setbuttonText('Submit legendary collection now');
            setnftsPaginationTab(1);
            setTitle('legendary');
          }
        });
        break;
      default:
        break;
    }
  };
  const submitHandle = () => {
   // setOpenSubmit(true);
    window.open('https://app.galaxis.xyz/2/stickerbook/claim/#/1/'+tab);
  };
  // console.log(tokenMetas)
  return (
    <Box sx={sx.root}>
      <Box sx={sx.content}>
        <Decoration
          text="WALLET COLLECTION"
          img={collectionWallet}
          style={{
            maxHeight: '84px',
            marginBottom: '24px',
            marginTop: '24px',
          }}
        />
        {address && !loading && (tokenContract && usedZoomContract && polygonTokenContract && polygonZoomcontract) &&
          <DustSection
            tokenContract={tokenContract}
            ethereumZoomContract={usedZoomContract}
            polygonContract={polygonTokenContract}
            polygonZoomcontract={polygonZoomcontract}
            handleRefetchMetadatas={handleRefetchMetadatas}
          />}
        {address && !loading && (
          <ButtonGroup
            sx={sx.tabContainer}
            variant="outlined"
            size="medium"
            aria-label="medium outlined button group"
          >
            <Button
              sx={{
                ...sx.tabBtns,
                ...(tab == 0 && sx.tabSelected),
              }}
              onClick={() => {
                setSelectedTab(0);
              }}
            >
              ALL
            </Button>
            <Button
              sx={{ ...sx.tabBtns, ...(tab == 1 && sx.tabSelected) }}
              onClick={() => {
                setSelectedTab(1);
              }}
            >
              COMMON
            </Button>
            <Button
              sx={{ ...sx.tabBtns, ...(tab == 2 && sx.tabSelected) }}
              onClick={() => {
                setSelectedTab(2);
              }}
            >
              RARE
            </Button>
            <Button
              sx={{ ...sx.tabBtns, ...(tab == 3 && sx.tabSelected) }}
              onClick={() => {
                setSelectedTab(3);
              }}
            >
              EPIC
            </Button>
            <Button
              sx={{ ...sx.tabBtns, ...(tab == 4 && sx.tabSelected) }}
              onClick={() => {
                setSelectedTab(4);
              }}
            >
              LEGENDARY
            </Button>
          </ButtonGroup>
        )}

        {!loading && tokenMetas.length === 0 && address && (
          <Typography sx={sx.text}>Your wallet is empty!</Typography>
        )}{' '}
        {tab === 0 && address && !loading && (
          <>
            <Typography sx={sx.walletTitle}>
              You have{' '}
              <span style={{ color: '#FC6405' }}>{tokenMetas.length}</span>{' '}
              cards in your wallet.
            </Typography>
            <Typography sx={sx.description}>
              To submit your collection, click on a
              collection (rarity type) tab listed above.Here you will see all of your available cards. You may filter your
              collection type by using the rarity tabs above.
              <br />
              <br />
              If you do not have the cards required to submit your collection,
              the wallet will suggest eligible purchases to help complete your
              collection. To purchase the missing card, click the button below
              and you’ll be directed to the OpenSea marketplace to complete your
              collection.
            </Typography>
          </>
        )}
        {tab >= 1 && address && (
          <>
            <Typography sx={sx.walletTitle} style={{ maxWidth: '780px' }}>
              Great! You have{' '}
              <span style={{ color: '#FC6405' }}>
                {getCountPerPage(sortedMetadataArray, nftsPaginationTab)}
              </span>{' '}
              eligible card(s) out of the 15. You need{' '}
              {15 - getCountPerPage(sortedMetadataArray, nftsPaginationTab)}{' '}
              more to submit the collection.
            </Typography>

            <Typography sx={sx.description}>
              Here you will see all of your available cards. You may filter your
              collection type by using the rarity tabs above.
              <br />
              <br />
              If you do not have the cards required to submit your collection,
              the wallet will suggest eligible purchases to help complete your
              collection. To purchase the missing card, click the button below
              and you’ll be directed to the OpenSea marketplace to complete your
              collection. For more information, please watch the{' '}
              <span style={{ color: '#FC6405' }}>
                collection explainer video (coming soon).
              </span>
            </Typography>
          </>
        )}
        {!address && (
          <Box sx={{ boxSizing: 'border-box' }}>
            <Typography
              variant="text"
              component="p"
              sx={{ mb: 4, color: '#D2D4C2', marginTop: '3rem' }}
            >
              In order to see your tokens, you need to connect your wallet
            </Typography>
            <Button variant="banner" sx={sx.connectBtn} onClick={handleConnect}>
              Connect Wallet
            </Button>

            <Box sx={{ boxSizing: 'border-box' }}>
              <ButtonGroup
              sx={sx.tabContainer}
              variant="outlined"
              size="medium"
              aria-label="medium outlined button group"
            >
              <Button
                sx={{ ...sx.tabBtns, ...(tab == 1 && sx.tabSelected) }}
                onClick={() => {
                  setSelectedTab(1);
                }}
              >
                COMMON
              </Button>
              <Button
                sx={{ ...sx.tabBtns, ...(tab == 2 && sx.tabSelected) }}
                onClick={() => {
                  setSelectedTab(2);
                }}
              >
                RARE
              </Button>
              <Button
                sx={{ ...sx.tabBtns, ...(tab == 3 && sx.tabSelected) }}
                onClick={() => {
                  setSelectedTab(3);
                }}
              >
                EPIC
              </Button>
              <Button
                sx={{ ...sx.tabBtns, ...(tab == 4 && sx.tabSelected) }}
                onClick={() => {
                  setSelectedTab(4);
                }}
              >
                LEGENDARY
              </Button>
              </ButtonGroup>

              <CollectionWallet
                  style={{ width: '100%' }}
                  tokenMetas={sortedMetadataArray}
                  tab={tab}
                  traitTypes={traitTypes}
                  etherscanLink={
                    tokenContract ? tokenContract.address : tokenAddress
                  }
                  setnftsPaginationTab={setnftsPaginationTab}
                  nftsPaginationTab={nftsPaginationTab}
                  detailsDisabled={false}
                  handleClick={handleClick}
                  selectedmaxPageCount={selectedmaxPageCount}
                  title={title}
                  disableSend={true}
                />
            </Box>




          </Box>
        )}
        {loading && (
          <Box sx={{ textAlign: 'center', marginTop:'3rem' }}>
            <Spinner color="#FF692B" />
          </Box>
        )}
        {address && !loading && (
          <>
            {tokenMetas.length > 0 && tab === 0 ? (
              <NftGallery
                style={{ width: '100%' }}
                tokenMetas={tokenMetas}
                traitTypes={traitTypes}
                etherscanLink={
                  tokenContract ? tokenContract.address : tokenAddress
                }
                detailsDisabled={false}
                handleClick={handleClick}
              />
            ) : (
              <>
                {osCollection && (
                  <Box>
                    <Button
                      variant="banner"
                      sx={sx.connectBtn}
                      onClick={() => {
                        window.open(
                          `https://opensea.io/collection/${osCollection}?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW`,
                          '_blank'
                        );
                      }}
                    >
                      Buy Now
                    </Button>
                  </Box>
                )}
              </>
            )}

            {tab >= 1 && (
              <CollectionWallet
                style={{ width: '100%' }}
                tokenMetas={sortedMetadataArray}
                tab={tab}
                traitTypes={traitTypes}
                etherscanLink={
                  tokenContract ? tokenContract.address : tokenAddress
                }
                setnftsPaginationTab={setnftsPaginationTab}
                nftsPaginationTab={nftsPaginationTab}
                detailsDisabled={false}
                handleClick={handleClick}
                selectedmaxPageCount={selectedmaxPageCount}
                title={title}
              />
            )}
          </>
        )}
        {(tab >= 1 && address) && (
          <Button variant="contained" sx={sx.button} onClick={submitHandle}>
            {buttonText}
          </Button>
        )}
      </Box>

      <RenderModal
        setOpen={setOpen}
        isOpen={open}
        metadata={selectedMetadata}
        imageContainerWidth={600}
      />
      <SubmitModal setOpen={setOpenSubmit} isOpen={openSubmit} />
    </Box>
  );
};

export default Wallet;
