import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import headericon from '../../assets/images/headericon.svg';

const BP1 = '@media (max-width: 1480px) and (min-width:1199px)';
const BP2 = '@media (max-width: 899px)';
const BP3 = '@media (max-width: 1150px)';

const sx = {
  root: {
    transition: '1s ease-out',
    cursor: 'pointer',
    position: 'relative',
    pr: '20px',
    pt: '5px',
    '& hover': {},
  },
  rootHorizontal: {
    height: '54px',
    px: '24px',
    pt: '10px',
    [BP1]: {
      px: '19px',
    },
  },
  rootVertical: {
    pl: '24px',
    py: '15px',
  },
  rootDisabled: {
    // cursor: 'not-allowed',
    color: '#f56105',
  },
  text: {
    whiteSpace: 'nowrap',
    fontSize: '18px',
    textTransform: 'uppercase',
    fontFamily: 'boucherie-block',
    color: '#FCFCF9',
    [BP3]: {
      fontSize: '14px',
    }
  },
  icon: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    [BP2]: {
      display: 'none',
    },
  },
};

const HeaderButton = ({
  text,
  active,
  disabled,
  onClick,
  onMouseOver,
  onMouseLeave,
}) => {
  const handleClick = () => {
    if (onClick && !disabled) {
      onClick();
    }
  };
  const handleHover = () => {
    if (onMouseOver && !disabled) {
      onMouseOver();
    }
  };
  const handleLeave = () => {
    if (onMouseLeave) {
      onMouseLeave();
    }
  };
  return (
    <>
      <Box
        sx={{
          ...sx.root,
          ...(active ? sx.rootDisabled : {}),
        }}
        onClick={handleClick}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
      >
        <Typography
          variant="text"
          sx={{
            ...sx.text,
            ...(active ? sx.rootDisabled : {}),
          }}
        >
          {text}
        </Typography>

        {/* <Box sx={sx.icon}>
          <img
            src={headericon}
            alt="icon"
            style={{ width: '35px', display: active ? 'block' : 'none' }}
          />
        </Box> */}
      </Box>
    </>
  );
};

/* eslint-disable react/forbid-prop-types */
HeaderButton.propTypes = {
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
  onMouseOver: PropTypes.any,
  onClick: PropTypes.any,
  onMouseLeave: PropTypes.any,
};

HeaderButton.defaultProps = {
  active: false,
  disabled: false,
  vertical: false,
  onMouseOver: null,
  onClick: null,
  onMouseLeave: null,
};

export default HeaderButton;
