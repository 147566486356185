import common1 from '../../../assets/images/collection-game/common/Grethel.png';
import common2 from '../../../assets/images/collection-game/common/Yeva.png';
import common3 from '../../../assets/images/collection-game/common/RorKhan.png';
import common4 from '../../../assets/images/collection-game/common/Koun.png';
import common5 from '../../../assets/images/collection-game/common/Falco.png';
import common6 from '../../../assets/images/collection-game/common/Esh.png';
import common7 from '../../../assets/images/collection-game/common/Billi.png';
import common8 from '../../../assets/images/collection-game/common/Nyella.png';
import common9 from '../../../assets/images/collection-game/common/Zaiyah.png';
import common10 from '../../../assets/images/collection-game/common/Mel.png';
import common11 from '../../../assets/images/collection-game/common/Skytr.png';
import common12 from '../../../assets/images/collection-game/common/Bruto.png';
import common13 from '../../../assets/images/collection-game/common/Kacee.png';
import common14 from '../../../assets/images/collection-game/common/Volby.png';
import common15 from '../../../assets/images/collection-game/common/Turo.png';

import rare1 from '../../../assets/images/collection-game/rare/Grethel.png';
import rare2 from '../../../assets/images/collection-game/rare/Yeva.png';
import rare3 from '../../../assets/images/collection-game/rare/RorKhan.png';
import rare4 from '../../../assets/images/collection-game/rare/Koun.png';
import rare5 from '../../../assets/images/collection-game/rare/Falco.png';
import rare6 from '../../../assets/images/collection-game/rare/Esh.png';
import rare7 from '../../../assets/images/collection-game/rare/Billi.png';
import rare8 from '../../../assets/images/collection-game/rare/Nyella.png';
import rare9 from '../../../assets/images/collection-game/rare/Zaiyah.png';
import rare10 from '../../../assets/images/collection-game/rare/Mel.png';
import rare11 from '../../../assets/images/collection-game/rare/Skytr.png';
import rare12 from '../../../assets/images/collection-game/rare/Bruto.png';
import rare13 from '../../../assets/images/collection-game/rare/Kacee.png';
import rare14 from '../../../assets/images/collection-game/rare/Volby.png';
import rare15 from '../../../assets/images/collection-game/rare/Turo.png';

import epic1 from '../../../assets/images/collection-game/epic/Grethel.png';
import epic2 from '../../../assets/images/collection-game/epic/Yeva.png';
import epic3 from '../../../assets/images/collection-game/epic/RorKhan.png';
import epic4 from '../../../assets/images/collection-game/epic/Koun.png';
import epic5 from '../../../assets/images/collection-game/epic/Falco.png';
import epic6 from '../../../assets/images/collection-game/epic/Esh.png';
import epic7 from '../../../assets/images/collection-game/epic/Billi.png';
import epic8 from '../../../assets/images/collection-game/epic/Nyella.png';
import epic9 from '../../../assets/images/collection-game/epic/Zaiyah.png';
import epic10 from '../../../assets/images/collection-game/epic/Mel.png';
import epic11 from '../../../assets/images/collection-game/epic/Skytr.png';
import epic12 from '../../../assets/images/collection-game/epic/Bruto.png';
import epic13 from '../../../assets/images/collection-game/epic/Kacee.png';
import epic14 from '../../../assets/images/collection-game/epic/Volby.png';
import epic15 from '../../../assets/images/collection-game/epic/Turo.png';

import legendary1 from '../../../assets/images/collection-game/legendary/Grethel.png';
import legendary2 from '../../../assets/images/collection-game/legendary/Yeva.png';
import legendary3 from '../../../assets/images/collection-game/legendary/RorKhan.png';
import legendary4 from '../../../assets/images/collection-game/legendary/Koun.png';
import legendary5 from '../../../assets/images/collection-game/legendary/Falco.png';
import legendary6 from '../../../assets/images/collection-game/legendary/Esh.png';
import legendary7 from '../../../assets/images/collection-game/legendary/Billi.png';
import legendary8 from '../../../assets/images/collection-game/legendary/Nyella.png';
import legendary9 from '../../../assets/images/collection-game/legendary/Zaiyah.png';
import legendary10 from '../../../assets/images/collection-game/legendary/Mel.png';
import legendary11 from '../../../assets/images/collection-game/legendary/Skytr.png';
import legendary12 from '../../../assets/images/collection-game/legendary/Bruto.png';
import legendary13 from '../../../assets/images/collection-game/legendary/Kacee.png';
import legendary14 from '../../../assets/images/collection-game/legendary/Volby.png';
import legendary15 from '../../../assets/images/collection-game/legendary/Turo.png';

export const images = {
  common: [
    {
      image: common1,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Grethel&search[stringTraits][0][values][1]=Grethel%20-%20the%20wise',
    },
    {
      image: common2,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Yeva&search[stringTraits][0][values][1]=Yeva%20-%20the%20golden%20one',
    },
    {
      image: common3,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Ror%E2%80%99Khan&search[stringTraits][0][values][1]=Ror%E2%80%99Khan%20-%20the%20mighty',
    },
    {
      image: common4,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Koun&search[stringTraits][0][values][1]=Koun%20-%20the%20guardian',
    },
    {
      image: common5,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Falco&search[stringTraits][0][values][1]=Falco%20-%20the%20ancient',
    },
    {
      image: common6,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Esh&search[stringTraits][0][values][1]=Esh%20-%20the%20collector',
    },
    {
      image: common7,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Billi&search[stringTraits][0][values][1]=Billi%20-%20the%20mechanic',
    },
    {
      image: common8,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Nyella&search[stringTraits][0][values][1]=Nyella%20-%20the%20archivist',
    },
    {
      image: common9,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Zaiyah&search[stringTraits][0][values][1]=Zaiyah%20-%20the%20magician',
    },
    {
      image: common10,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Mel&search[stringTraits][0][values][1]=Mel%20-%20the%20pilot',
    },
    {
      image: common11,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Skytr&search[stringTraits][0][values][1]=Skytr%20-%20the%20sly',
    },
    {
      image: common12,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Bruto&search[stringTraits][0][values][1]=Bruto%20-%20the%20bold',
    },
    {
      image: common13,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Kacee&search[stringTraits][0][values][1]=Kacee%20-%20the%20commander',
    },
    {
      image: common14,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Volby&search[stringTraits][0][values][1]=Volby%20-%20the%20hewer',
    },
    {
      image: common15,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Turo&search[stringTraits][0][values][1]=Turo%20-%20the%20explorer',
    },
  ],
  rare: [
    {
      image: rare1,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Grethel%20-%20the%20wise',
    },
    {
      image: rare2,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Yeva%20-%20the%20golden%20one',
    },
    {
      image: rare3,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Ror%E2%80%99Khan%20-%20the%20mighty',
    },
    {
      image: rare4,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Koun%20-%20the%20guardian',
    },
    {
      image: rare5,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Falco%20-%20the%20ancient',
    },
    {
      image: rare6,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Esh%20-%20the%20collector',
    },
    {
      image: rare7,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Billi%20-%20the%20mechanic',
    },
    {
      image: rare8,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Nyella%20-%20the%20archivist',
    },
    {
      image: rare9,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Zaiyah%20-%20the%20magician',
    },
    {
      image: rare10,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Mel%20-%20the%20pilot',
    },
    {
      image: rare11,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Skytr%20-%20the%20sly',
    },
    {
      image: rare12,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Bruto%20-%20the%20bold',
    },
    {
      image: rare13,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Kacee%20-%20the%20commander',
    },
    {
      image: rare14,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Volby%20-%20the%20hewer',
    },
    {
      image: rare15,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Character&search[stringTraits][0][values][0]=Turo%20-%20the%20explorer',
    },
  ],
  epic: [
    {
      image: epic1,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Grethel',
    },
    {
      image: epic2,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Yeva',
    },
    {
      image: epic3,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Ror%E2%80%99Khan',
    },
    {
      image: epic4,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Koun',
    },
    {
      image: epic5,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Falco',
    },
    {
      image: epic6,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Esh',
    },
    {
      image: epic7,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Billi',
    },
    {
      image: epic8,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Nyella',
    },
    {
      image: epic9,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Zaiyah',
    },
    {
      image: epic10,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Mel',
    },
    {
      image: epic11,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Skytr',
    },
    {
      image: epic12,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Bruto',
    },
    {
      image: epic13,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Kacee',
    },
    {
      image: epic14,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Volby',
    },
    {
      image: epic15,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Turo',
    },
  ],
  legendary: [
    {
      image: legendary1,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Grethel%20-%20the%20wise',
    },
    {
      image: legendary2,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Yeva%20-%20the%20golden%20one',
    },
    {
      image: legendary3,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Ror%E2%80%99Khan%20-%20the%20mighty',
    },
    {
      image: legendary4,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Koun%20-%20the%20guardian',
    },
    {
      image: legendary5,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Falco%20-%20the%20ancient',
    },
    {
      image: legendary6,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Esh%20-%20the%20collector',
    },
    {
      image: legendary7,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Billi%20-%20the%20mechanic',
    },
    {
      image: legendary8,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Nyella%20-%20the%20archivist',
    },
    {
      image: legendary9,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Zaiyah%20-%20the%20magician',
    },
    {
      image: legendary10,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Mel%20-%20the%20pilot',
    },
    {
      image: legendary11,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Skytr%20-%20the%20sly',
    },
    {
      image: legendary12,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Bruto%20-%20the%20bold',
    },
    {
      image: legendary13,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Kacee%20-%20the%20commander',
    },
    {
      image: legendary14,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Volby%20-%20the%20hewer',
    },
    {
      image: legendary15,
      link: 'https://opensea.io/collection/girls-robots-dragons-official?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Layout&search[stringTraits][0][values][0]=Perfect&search[stringTraits][1][name]=Character&search[stringTraits][1][values][0]=Turo%20-%20the%20explorer',
    },
  ],
};
