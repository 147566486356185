import { Box, Button, Modal, Typography, Grid } from '@mui/material';
import React, { useState } from 'react';
import close from '../../../assets/images/black-close.svg';
import physical from '../../../assets/images/traits/autograph-black.svg';
import girl from '../../../assets/images/rewards/girl_modal.svg';
import robot from '../../../assets/images/rewards/robot_modal.svg';
import dragon from '../../../assets/images/rewards/dragon_modal.svg';
import rectangle from '../../../assets/images/rectangle.svg';
const BP1 = '@media (max-width: 500px)';
const BP2 = '@media (max-width: 599px)';

const sx = {
  root: {
    maxWidth: '566px',
    maxHeight: '700px',

    width: '100%',
    height: 'fit-content',
    backgroundColor: '#FFFFFF',
    border: '1px solid #707070',
    borderRadius: '27px',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    webkitBoxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
    mozBoxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
    boxShadow: '0px 33px 44px 0px rgba(0,0,0,0.16)',
  },

  closeButton: {
    width: '17.5px',
    height: '17.5px',
    position: 'absolute',
    right: '38px',
    top: '37px',
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    gap: '20px',
    px: '50px',
    pb: '10px',
    [BP1]: {
      px: '10px',
    },
  },
  imageHolder: {
    mt: '68px',
    width: '64px',
    height: '34px',
    border: '2px solid #030000',
    p: '10px',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    maxWidth: '416px',
    maxHeight: '500px',
    pr: '20px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.3em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#FF692B',
    },
  },
  description: {
    fontFamily: 'bau',
    fontSize: '16px',
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Boucherie-block',
    fontSize: '38px',
    textAlign: 'center',
    lineHeight: '40px',
    color: '#FC6405',
    mb: '20px',
  },
  imageGirl: {
    marginRight: '-50px',
    marginTop: '10px',
    [BP2]: {
      marginRight: '-20px',
    },
  },
  imageDragon: {
    marginLeft: '-50px',
    marginTop: '10px',
    [BP2]: {
      marginLeft: '-20px',
    },
  },
  imageRobot: {
    marginLeft: '-20px',
    [BP2]: {
      marginRight: '-5px',
    },
  },
};

const ModalRewards = ({ onClose }) => {
  const [openFinalModal, setopenFinalModal] = useState(false);

  return (
    <>
      <Box sx={sx.root}>
        <img
          src={close}
          style={sx.closeButton}
          alt=""
          onClick={() => {
            onClose();
            setopenFinalModal(false);
          }}
        />
        <Box sx={sx.container}>
          <Box sx={sx.header}>
            <Box sx={sx.imageHolder}>
              <Typography
                style={{ fontSize: '40px', fontFamily: 'boucherie-block' }}
              >
                GRD
              </Typography>
            </Box>
          </Box>
          <Box sx={sx.content}>
            <Typography sx={sx.title}>Hero Medals</Typography>
            <Typography sx={sx.description}>
              You’ll be awarded a Hero Medal after collecting 5 different cards
              of girls, robots and dragons. If you have the required 15 cards in
              your wallet (5 girls, 5 robots, 5 dragons with different
              backgrounds) you’ll be eligible to win the USD 10.000 cash.
            </Typography>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              style={{
                maxWidth: '280px',
                margin: 'auto',
                marginTop: '10px',
                zIndex: 1,
              }}
            >
              {' '}
              <Grid item xs={3.6} sm={4} md={4}>
                <Box sx={sx.imageGirl}>
                  <img src={girl} alt="" style={{ width: '100%' }} />
                </Box>
              </Grid>
              <Grid item xs={3.6} sm={4} md={4}>
                <Box sx={sx.imageRobot}>
                  <img src={robot} alt="" style={{ width: '120%' }} />
                </Box>
              </Grid>
              <Grid item xs={3.6} sm={4} md={4}>
                <Box sx={sx.imageDragon}>
                  <img src={dragon} alt="" style={{ width: '100%' }} />
                </Box>
              </Grid>
            </Grid>
            {/* <Typography
              sx={sx.description}
              style={{
                fontFamily: 'poppins-medium',
                color: '#FF692B',
              }}
            >
              You have 1 Hero Medal (Girls) out of the 3 available in your
              wallet. Sorry, you can’t claim your cash prize yet! The ‘Claim’
              button will be available when you have collected all the 3 Hero
              Medals.
            </Typography> */}
            {/* <Button
              variant="contained"
              sx={sx.button}
              onClick={() => {
                setopenFinalModal(true);
              }}
              style={{ marginBottom: '30px', marginTop: '40px' }}
            >
              CLAIM
            </Button> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ModalRewards;
