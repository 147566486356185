import { Box, Typography } from '@mui/material'
import React from 'react'
import galaxisLogo from '../../../../../assets/images/small-galaxis-logo.svg'
const sx = {
  root: {
    mt: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
    "& img":{
      height: '15px'
    }
  },
  footer: {
    fontSize: '8px',
    fontFamily: 'poppins',
    color: '#F7FCFB',
    maxWidth: '400px',
    opacity: '0.4',
  },
  poweredByText: {
    fontSize: '10px',
    fontFamily: 'poppins',
    color: "#CACCB5"
  }
}
const DustContainerFooter = () => {
  return (
    <Box sx={sx.root}>
      
        <Typography sx={sx.poweredByText}>
          Powered By
        </Typography>
        <img src={galaxisLogo} alt="" />
      
    </Box>
  )
}

export default DustContainerFooter