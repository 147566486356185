import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useState } from 'react';
import dustIcon from '../../../assets/images/colored-dust-icon.svg'
import DustTnc from './Modals/DustTnc';

const BP1 = '@media (max-width: 600px)';
const sx = {
  root: {
    display: 'flex',
    width: 'fit-content',
    maxWidth: '120px',
    flexDirection: 'column',
    alignItems: 'center',
    border: 'solid 2px #D2D4C2',
    borderRadius: '12px',
    p: '12px',
    gap: '5px',
    [BP1]: {
      marginBottom: '10px'
    }
  },
  dustContent: {
    display: 'flex',
    gap: '5px',
    alignItems: 'baseline',
    '& img': {
      height: '17px',
    },
    '& p ': {
      fontSize: '22px',
      textTransform: 'uppercase',
      fontFamily: 'boucherie-block',
      color: '#D2D4C2',
      m: 0,
    }
  },
  content: {
    fontSize: '16px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    fontFamily: 'boucherie-block',
    width: '80%',
    color: '#D2D4C2',
  },
   claimButton : {
    my: '30px'
   }
}

const WalletDustAmountBox = ({ dustAmount, totalCardAmounts }) => {
  const [openTerms, setopenTerms] = useState(null);




  return (
    <>
      <Box sx={sx.root}>
        <Box sx={sx.dustContent}>
          <img src={dustIcon} alt="" />
          <p>{parseFloat(dustAmount).toFixed(2)}</p>
        </Box>
        <Typography sx={sx.content}>
          TOTAL DUST on
          your {totalCardAmounts} cards
        </Typography>
      </Box>
    </>
  )

}

export default WalletDustAmountBox