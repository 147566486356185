import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import dragon from '../../assets/images/dragon_circle.png';
import moment from 'moment';
import './TwitterSection.css';
const sx = {
  root: {},
  contentHolder: {
    backgroundColor: '#CACCB5',
    borderRadius: '20px',
    maxWidth: '480px',
    height: '158px',
    padding: '20px 20px',
    cursor: 'pointer',
  },
  imageHolder: {
    width: '50px',
    height: '50px',
    borderRadius: '15px',
    backgroundColor: '#CACCB5',
  },
  header: {
    display: 'flex',
  },
  content: {},
  description: {
    fontSize: '14px',
    maxHeight: '70px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    mb: '10px',
  },
  title: {
    fontSize: '14px',
  },
  hash: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0px 5px',
    flexWrap: 'wrap',
  },
  tweetData: {
    color: '#CACCB5',
    fontSize: '13px',
    pl: '20px',
    pt: '20px',
  },
};

const TwitterCard = ({ userData, tweetData }) => {
  const [hashElements, setHashElements] = useState(undefined);
  useEffect(() => {
    tweetDataTextSplitter(tweetData);
  }, []);
  let data = tweetData.created_at;
  let hourData = moment(data).format(' h:mm A');
  let dayData = moment(data).format(' MMMM D, YYYY');
  const tweetDataTextSplitter = (tweetData) => {
    const regex = /#\w+/g;
    const found = tweetData.text.match(regex);
    setHashElements(found);
  };
  const openTweet = (id) => {
    window.open(`https://twitter.com/GRD_Fans/status/${id}`, '_blank');
  };
  const searchingOnTwitterByParam = (queryParam) => {
    let postText;
    postText = queryParam.replace(/#\b/g, '');
    window.open(
      `https://twitter.com/hashtag/${postText}?src=hashtag_click`,
      '_blank'
    );
  };
  return (
    <>
      {userData && tweetData &&
        <Box>
          <Box
            sx={sx.contentHolder}
            onClick={() => {
              openTweet(tweetData.id);
            }}
          >

            <Box sx={sx.header}>{/* <img src="" alt="undefined" /> */}</Box>
            <Box sx={sx.content} id={`item ${tweetData.id}`}>
              <Box sx={sx.contentHeader}>
                <Box sx={sx.imageHolder}>
                  <img
                    src={userData.profile_image_url}
                    alt="undefined"
                    style={{ width: '100%', height: '100%', borderRadius: '25px' }}
                  />
                </Box>
                <Box style={{ marginLeft: '10px' }}>
                  <Typography
                    sx={sx.title}
                    style={{ fontFamily: 'poppins-semibold' }}
                  >
                    {userData.name}
                  </Typography>
                  <Typography sx={sx.title}>{userData.username}</Typography>
                </Box>
              </Box>
              <Typography
                dangerouslySetInnerHTML={{ __html: tweetData.text }}
                sx={sx.description}
                className="twitter-card-description"
              />
              <Box sx={sx.hash} className="twitter-card-hash">
                {hashElements &&
                  hashElements.length > 0 &&
                  hashElements.map((item,idx) => (
                    <Typography
                      key={'twt'+idx}
                      onClick={() => {
                        searchingOnTwitterByParam(item);
                      }}
                      style={{
                        color: '#FF692B',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }}
                    >
                      {item}
                    </Typography>
                  ))}
              </Box>
            </Box>

          </Box>
          <Typography sx={sx.tweetData}>
            {hourData} · {dayData} · Twitter Web App
          </Typography>
        </Box>
      }
    </>
  );
};

export default TwitterCard;
