
import React, { useEffect, useState } from 'react'
// import FullscreenModal from '../../Components/FullscreenModal'
import flip_icon from '../../../assets/images/flip-icon.svg'
import dlIcon from '../../../assets/images/download-arrow.svg';
const CardBack = ({ backImage, onClick, dlEnabled }) => {
    const [showFlipIcon, setshowFlipIcon] = useState(false);
    const [open, setOpen] = useState(false);
    const [fullscreenSrc, setFullscreenSrc] = useState(null);
    const [mobileView, setmobileView] = useState(false)
    const setFullscreenSource = (src) => {
        setFullscreenSrc(src);
        setOpen(true);
    };
    useEffect(() => {
        if (window.innerWidth < 900) {
            setmobileView(true);
        }
    }, [])
    return (
        <>
            <div className='explorer-card-back-root'
                style={{ backgroundImage: `url(${backImage.replaceAll(' ', '%20')})` }}
                onMouseOver={() => { setshowFlipIcon(true) }}
                onMouseLeave={() => { setshowFlipIcon(false) }}
            >
                <div className="explorer-card-icons-holder">
                    <img src={flip_icon} className='explorer-flip-icon' alt="not found"
                        style={{ display: showFlipIcon || mobileView ? 'block' : 'none' }}
                        onClick={(e) => {
                            onClick(e);

                        }}
                    />

                {dlEnabled&&<img
                    className="explorer-download-icon"
                    src={dlIcon}
                    alt="download"
                    onClick={(e) => {
                    console.log('download',backImage);
                    window.open(backImage);
                    }}
                />}
                   
                </div>
                
            </div>
            {/* <FullscreenModal setOpen={setOpen} isOpen={open} imgSrc={fullscreenSrc} /> */}
        </>
    )
}

export default CardBack