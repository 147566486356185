import { Input, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import physical from '../../../assets/images/traits/autograph-black.svg'
import signo from '../../../assets/images/signo.png';
import './ModalChildren.css';
const BP1 = '@media (max-width: 500px)';
const sx = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        gap: '20px',
        px: '70px',
        mb: '30px',
        [BP1]: {
            px: '10px'
        }
    },

    header: {
        display: 'flex',
        mt: '45px'
    },
    imageHolder: {
        width: '44px',
        height: '44px',
        border: '2px solid #030000',
        p: '10px',
        borderRadius: '7px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        maxWidth: '416px'
    },
    title: {
        fontFamily: 'Boucherie-block',
        fontSize: '38px',
        textAlign: 'center',
        lineHeight: '40px',
        color: '#FC6405',
        mb: '20px'
    },
    description: {
        fontFamily: 'poppins',
        fontSize: '16px',
        textAlign: 'center',
    },
    signoHolder: {
        [BP1]: {
            height: '80px'
        }
    },
}

const AutographClaim = () => {
    return (
        <Box sx={sx.root}>
            <Box sx={sx.header}>
                <Box sx={sx.imageHolder}><img src={physical} alt="" height={'100%'} /></Box>
            </Box>
            <Box sx={sx.content}>
                <Typography sx={sx.title}>NFT Autograph</Typography>
                <Typography sx={sx.description}>To utilise the Autograph Trait, submit the appropriate information and receive a personalised autograph from the artist(s). Once complete, the autograph is added to the metadata and will appear on the NFT.</Typography>
            </Box>
            <Box sx={sx.signoHolder}>
                <img src={signo} alt="" height={'100%'}/>
            </Box>
            <Box>
                <input
                    placeholder="RECIPIENT NAME"
                    id='physical-input'
                ></input>
            </Box>
        </Box>
    )
}

export default AutographClaim