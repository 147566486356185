import { useState, useEffect } from 'react';

const useSorterByCollection = (metadataArray) => {
  let common = [];
  let rare = [];
  let epic = [];
  let legendary = [];
  let finalCommon = [];
  let finalRare = [];
  let finalEpic = [];
  let finalLegendary = [];
  let commonCount = 0;
  let rareCount = 0;
  let epicCount = 0;
  let legendaryCount = 0;

  metadataArray.map((token) => {
    if (token.sides) {
      //rare
      if (token.rare === true && !token.perfect ) {
        rare.push(token);
        common.push(token)
      }
      //epic
     else if (token.perfect === true && !token.rare) {
        epic.push(token);
        common.push(token)
      }
      //legendary
      else if (token.perfect === true && token.rare === true) {
        legendary.push(token);
        rare.push(token);
        epic.push(token);
        common.push(token)
      }
      //common
      else {
        common.push(token);
      }
    }
  });
  for (let index = 0; index < 15; index++) {
    var sortedArrayCommon = [];
    var sortedArrayRare = [];
    var sortedArrayEpic = [];
    var sortedArrayLegendary = [];
    common.map((item) => {
      if (Number(item.sides[0].dna.slice(-2)) === index || (Number(item.sides[0].dna.slice(-2)) - 15) === index) {
        sortedArrayCommon.push(item);
      }
    })
    finalCommon.push(sortedArrayCommon);

    rare.map((item) => {
      if ((Number(item.sides[0].dna.slice(-2)) - 15) === index) {
        sortedArrayRare.push(item);
      }
    })
    finalRare.push(sortedArrayRare);

    epic.map((item) => {
      if (Number(item.sides[0].dna.slice(-2)) === index || (Number(item.sides[0].dna.slice(-2)) - 15) === index) {
        sortedArrayEpic.push(item);
      }
    })
    finalEpic.push(sortedArrayEpic);

    legendary.map((item) => {
      if ((Number(item.sides[0].dna.slice(-2)) - 15) === index) {
        sortedArrayLegendary.push(item);
      }
    })
    finalLegendary.push(sortedArrayLegendary)
  };
  finalCommon.map((item) => {
    if (item.length > commonCount) {
      commonCount = item.length;
    }
  })
  finalRare.map((item) => {
    if (item.length > rareCount) {
      rareCount = item.length;
    }
  })
  finalEpic.map((item) => {
    if (item.length > epicCount) {
      epicCount = item.length;
    }
  })
  finalLegendary.map((item) => {
    if (item.length > legendaryCount) {
      legendaryCount = item.length;
    }
  })


  const maxPageCounts = [
    { common: commonCount },
    { rare: rareCount },
    { epic: epicCount },
    { legendary: legendaryCount },
  ]

  const finalCollections = [
    { common: finalCommon },
    { rare: finalRare },
    { epic: finalEpic },
    { legendary: finalLegendary },
  ];

  function getCountPerPage(collection, page) { 
    let result = 0;
    for (let c = 0; c < 15; c++) { 
      if (collection[c] && collection[c][page-1]) { 
        result++;
        //
      }
    }
    return result;
  }
 
  return { finalCollections, maxPageCounts, getCountPerPage };
};

export default useSorterByCollection;
