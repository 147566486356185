import React from 'react';
import './DecoratedTitle.css';

const DecoratedTitle = (props) => {
    return (
      <div className="decor-title" style={props.style}>
        <span className="decor st"/>
        <span className="title-text">
          <span className="content">{props.children}</span>
        </span>
        <span className="decor en"/>
      </div>
    );
}

export default DecoratedTitle;
