import './FilterHeader.css';
import CustomFilterSelect from './ExplorerComponents/CustomFilterSelect';
import FilterCheckbox from './FilterCheckBox/FilterCheckbox';
import ShowTokenCheckbox from './FilterCheckBox/ShowTokenCheckbox';
import { useState } from 'react';
import { Box } from '@mui/material';

const sx = {
  filterholder: {
    display: 'flex',
    gap: '30px',
  },
};
const FilterHeader = ({ filters, filterChanged, projectName, showToken }) => {
  const [mintedOnlyProject, setmintedOnlyProject] = useState(
    window.location.hash ===
      '#/collie-buddz-metadata-staging.herokuapp.com/api' ||
      window.location.hash ===
        '#/collie-buddz-metadata-server.herokuapp.com/api' ||
      window.location.hash === '#/collie-buddz-metadata-server.ether.cards/api'
      ? true
      : false
  );
  const GetFilters = () => {
    return filters
      ? Object.keys(filters).map((keyname) => {
          return (
            <CustomFilterSelect
              key={keyname}
              keyname={keyname}
              filters={filters}
              filterChanged={filterChanged}
            />
          );
        })
      : 'No filters loaded!';
  };

  return (
    <div className="filter-container">
      <h3
        style={{
          color: '#000',
          fontFamily: 'boucherie-block',
          fontSize: '25px',
          marginBottom: '20px',
        }}
      >
        {projectName}
      </h3>
      <div className="filter-container-inner">
        {mintedOnlyProject && (
          <div>
            <FilterCheckbox filterChanged={filterChanged} />
          </div>
        )}
        <div>
          <ShowTokenCheckbox showToken={showToken} />
        </div>
        <Box sx={sx.filterholder}>{GetFilters()}</Box>
      </div>
    </div>
  );
};
export default FilterHeader;
