import { Button, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';

const sx = {
  imageHolder: {
    backgroundColor: '#000',
  },
  button: {
    mt: '10px',
    fontSize: '16px',
    px: '12px',
    color: '#FFF',
    border: '2px solid #FF692B',
    fontFamily: 'boucherie-block',
    '&:hover': {
      backgroundColor: '#FF692B',
    },
  },
};
const PlaceholderCard = ({ card }) => {
  return (
    <>
      <Box
        style={{
          maxWidth: '100%',
          backgroundColor: '#07191A',
          borderRadius: '10px',
        }}
      >
        <img
          src={card.image}
          style={{
            maxWidth: '100%',
            borderRadius: '10px',
            backgroundColor: '#07191A',
            filter: 'grayscale(1)',
          }}
          alt="Card"
        />
      </Box>
      <Button
        onClick={() => window.open(card.link, '_blank', 'noopener,noreferrer')}
        sx={sx.button}
      >
        BUY NOW
      </Button>
    </>
  );
};
export default PlaceholderCard;
