import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { Contract, providers } from 'ethers'
import React from 'react'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header/Header'
import useWeb3Ctx from '../../hooks/useWeb3Ctx'
import tokenABI from '../../Components/SaleComponent/abi/Token.json';
import config from '../../config/config'
import DustSection from './components/DustSection'
import polygonContract from '../../Components/SaleComponent/abi/constants/PolygonContract.json'
import polygonAbi from '../../Components/SaleComponent/abi/PolygonAbi.json'

const sx = {
  root: {},
  body: {
    pt: '18px',
    pb: '18px',
    mt: '-10px',
    backgroundColor: '#152D2E',
  },
  content: {
    margin: 'auto',
    px: '20px',
    pb: '95px',
  },
  backgroundIcon: {
    position: 'absolute',
    margin: 'auto',
    width: '75%',
    marginTop: '100px',
  },
  line: {
    background: 'linear-gradient(0.25turn, #393A2A, #D1D3C1)',
    width: '100%',
  },
  bodyicon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    transform: 'rotate(180deg)',
    mb: '40px',
  },
  descriptionFirst: {
    margin: 'auto',
    maxWidth: '900px',
    fontFamily: 'poppins',
    fontSize: '14px',
    color: '#D2D4C2',
    textAlign: 'center',
    lineHeight: '1.8',
    mt: '0px',
    position: 'relative',
    zIndex: '2',
  },
  description: {
    margin: 'auto',
    maxWidth: '900px',
    fontFamily: 'poppins',
    fontSize: '16px',
    color: '#D2D4C2',
    lineHeight: '1.8',
    mb: '16px',
    // wordBreak: 'break-all',
    // overflowWrap: 'break-word',
  },
  title: {
    fontFamily: 'boucherie-block',
    fontSize: '40px',
    textAlign: 'center',
    lineHeight: '45px',
    cursor: 'pointer',
    color: '#D1D3C1',
    mb: '8px',
  },
  date: {
    color: '#FF692B',
    fontFamily: 'boucherie-block',
    fontSize: '46px',
    mt: '50px',
    mb: '50px',
  },
  buyButton: {
    height: 'auto',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: 'inherit',
    textTransform: 'uppercase',
    padding: '6px 16px',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    backgroundColor: '#FF692B',
    border: '2px solid #FF692B',
    fontFamily: 'boucherie-block',
    color: '#fff',
    '&:hover': {
      border: '2px solid #fff',
      backgroundColor: '#fff',
      color: '#FF692B',
    },
    '&:disabled': {
      opacity: '0.7'

    },
  },
};

const DustPage = () => {
  const { address, handleDisconnect, handleConnect, ethersProvider } = useWeb3Ctx();
  const mainProvider = new providers.JsonRpcProvider(config.RPC_URL);
  const token = new Contract(config.GRD_TOKEN_CONTRACT, tokenABI.abi, mainProvider);
  const provider = new providers.JsonRpcProvider(config.POLYGON_RPC);
  const polygonToken = new Contract(polygonContract[config.POLYGON_ID], polygonAbi.abi, provider);
  
  
  console.log('DUST PAGE: TOKEN', token)
  console.log('DUST PAGE: PROVIDER (polygon)', provider)
  console.log('DUST PAGE: polygonToken', polygonToken)
  
  
  return (
    <Box sx={sx.root}>
      <Header address={address} handleDisconnect={handleDisconnect} />
      <Box sx={sx.body}>
        <Box
          sx={sx.line}
          style={{
            height: '16px',
            marginTop: '85px',
          }}
        ></Box>
        <Box
          sx={sx.line}
          style={{
            marginTop: '5px',
            height: '5px',
          }}
        ></Box>
        <Box sx={sx.content}>
          <Box
            style={{
              position: 'relative',
              maxWidth: '1280px',
              margin: 'auto',
              zIndex: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {!address ? (
              <Button
                variant="banner"
                sx={sx.buyButton}
                style={{ margin: "10px" }}
                onClick={handleConnect}
              >
                CONNECT WALLET
              </Button>
            ) : (
              <DustSection
                ethTokenContract={token}
                polygonTokenContract={polygonToken} />
            )}
          </Box>
          <Footer />
        </Box>
        <Box
          sx={sx.line}
          style={{
            height: '16px',
            marginTop: '30px',
          }}
        ></Box>
        <Box
          sx={sx.line}
          style={{
            marginTop: '5px',
            height: '5px',
          }}
        ></Box>
      </Box>
    </Box>
  )
}

export default DustPage