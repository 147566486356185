import React from "react";
import { Typography, Modal, Box } from "@mui/material";
import { SpinnerCircularFixed as Spinner } from "spinners-react";
import Divider from "./Divider";
import PopupModal from "./PopupModal";

const sx = {
  root: {
    width: "100%",
    height: "100%",
    background: "none",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  title: {
    textAlign: "center",
    color: "#FF692B",
    lineHeight:"34px",
    fontSize:"30px",
    fontFamily: "boucherie-block",
  },
  url: {
    textDecoration: "none",
    color: "#FF692B",
    fontWeight: "700",
  },
};

const TxProgressModal = ({ isOpen, txEtherScan }) => {
  return (
    <PopupModal isOpen={isOpen} isTxPopup={true}>  
      <Box sx={sx.root}>
        <Typography variant="heading" sx={sx.title}>
          Transaction In Progress
        </Typography>
        <Typography variant="text" sx={{ my: 4 }}>
        Please wait while your transaction is being processed. <br />
          {txEtherScan && (
            <>
              You can check the transaction status on{" "}
              <Box
                component="a"
                href={txEtherScan}
                sx={sx.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Etherscan
              </Box>
              .
            </>
          )}
        </Typography>

        <Spinner color="#FF692B" size={64} style={{ marginLeft: "auto", marginRight: "auto" }}/> 
      </Box>
    </PopupModal>
  );
};

export default TxProgressModal;
