import smallCard1 from './300x450Arts/img1.jpg';
import smallCard2 from './300x450Arts/img2.jpg';
import smallCard3 from './300x450Arts/img3.jpg';
import smallCard4 from './300x450Arts/img4.jpg';
import smallCard5 from './300x450Arts/img5.jpg';
import smallCard6 from './300x450Arts/img6.jpg';
import smallCard7 from './300x450Arts/img7.jpg';
import smallCard8 from './300x450Arts/img8.jpg';
import smallCard9 from './300x450Arts/img9.jpg';
import smallCard10 from './300x450Arts/img10.jpg';
import orginalCard1 from "./originalArts/img1.jpg";
import orginalCard2 from "./originalArts/img2.jpg";
import orginalCard3 from "./originalArts/img3.jpg";
import orginalCard4 from "./originalArts/img4.jpg";
import orginalCard5 from "./originalArts/img5.jpg";
import orginalCard6 from "./originalArts/img6.jpg";
import orginalCard7 from "./originalArts/img7.jpg";
import orginalCard8 from "./originalArts/img8.jpg";
import orginalCard9 from "./originalArts/img9.jpg";
import orginalCard10 from "./originalArts/img10.jpg";

const SMALLCARDS = {
    smallCard1: smallCard1,
    smallCard2: smallCard2,
    smallCard3: smallCard3,
    smallCard4: smallCard4,
    smallCard5: smallCard5,
    smallCard6: smallCard6,
    smallCard7: smallCard7,
    smallCard8: smallCard8,
    smallCard9: smallCard9,
    smallCard10: smallCard10,
};
const ORIGINALART = {
    orginalCard1: orginalCard1,
    orginalCard2: orginalCard2,
    orginalCard3: orginalCard3,
    orginalCard4: orginalCard4,
    orginalCard5: orginalCard5,
    orginalCard6: orginalCard6,
    orginalCard7: orginalCard7,
    orginalCard8: orginalCard8,
    orginalCard9: orginalCard9,
    orginalCard10: orginalCard10,
}

const ARTARRAY = [
    {
        smallimg: SMALLCARDS.smallCard1,
        orginalArt: ORIGINALART.orginalCard1
    },
    {
        smallimg: SMALLCARDS.smallCard2,
        orginalArt: ORIGINALART.orginalCard2
    },
    {
        smallimg: SMALLCARDS.smallCard3,
        orginalArt: ORIGINALART.orginalCard3
    },
    {
        smallimg: SMALLCARDS.smallCard4,
        orginalArt: ORIGINALART.orginalCard4
    },
    {
        smallimg: SMALLCARDS.smallCard5,
        orginalArt: ORIGINALART.orginalCard5
    },
    {
        smallimg: SMALLCARDS.smallCard6,
        orginalArt: ORIGINALART.orginalCard6
    },
    {
        smallimg: SMALLCARDS.smallCard7,
        orginalArt: ORIGINALART.orginalCard7
    },
    {
        smallimg: SMALLCARDS.smallCard8,
        orginalArt: ORIGINALART.orginalCard8
    },
    {
        smallimg: SMALLCARDS.smallCard9,
        orginalArt: ORIGINALART.orginalCard9
    },
    {
        smallimg: SMALLCARDS.smallCard10,
        orginalArt: ORIGINALART.orginalCard10
    },

]

export default ARTARRAY;