import { Button, Typography } from "@mui/material";
import PopupModal from "./PopupModal";
const BP2 = "@media (max-width: 1345px)";
const BP4 = "@media (max-width: 600px)";
const BP5 = "@media (max-width: 899px)";
const BP6 = "@media (max-width: 700px)";
const BP3 = "@media (max-width: 384px)";
const BP7 = "@media (max-width: 820px)";
const BP1 = "@media (max-width: 570px)";
const HEADER = "We're Sorry";
const ERROR =
  "Your wallet does not have the permission to mint yet, but do not worry Public sale is coming soon!";

const PermissionErrorModal = ({ isOpen, setOpen }) => {
  const sx={
    buyButton:{
      height: '35px',
      borderRadius: '5px',
      fontWeight: '500',
      whiteSpace: 'nowrap',
      backgroundColor: 'unset',
      border: '2px solid #FF692B',
      fontFamily: 'boucherie-block',
      color: '#FF692B',
      '&:hover': {
        border: '2px solid #FF692B',
        backgroundColor: '#FF692B',
        color: '#ffffff',
      },
      '&:disabled': {
        border: '2px solid rgba(0, 0, 0, 0.26);',
        
      },
      [BP3]: {
        width: '100%',
        px: '14px',
      },
      }
  }



  return (
    <PopupModal isOpen={isOpen} setOpen={setOpen} header={HEADER}>
      <Typography style={{marginBottom:"20px"}}>{ERROR}</Typography>
      <Button
							sx={sx.buyButton}
							style={{ margin:"10px"}}
							variant="banner"
							onClick={()=>setOpen(false)}
							>
							OK
							</Button>
    </PopupModal>
  );
};

export default PermissionErrorModal;
