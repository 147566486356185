import React, { useEffect, useRef, useState } from 'react';
import flip_icon from '../../../assets/images/flip-icon.svg';
import fullScreen from '../../../assets/images/fullscreen-icon.svg';
import dlIcon from '../../../assets/images/download-arrow.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import useContainerDimensions from './useContainerDimensions';
import CardBack from './CardBack';
import './GalaxisCard.css';
import perfectBadge from '../../../assets/images/perfect-badge.png';
import badgeCard from '../../../assets/images/badge-background.jpg';
import TraitCard from './TraitCard.jsx';
import { getDust } from './Dust/getDust';
import { Typography } from '@mui/material';
import DustButton from './Dust/DustButton';
import DustLayer from './Dust/DustLayer';
import config from '../../../config/config';

const perfectBadgeVariable = {
  image: badgeCard,
  icon: perfectBadge,
  title: 'Perfect',
  description:
    'This badge appears on artistically ‘perfect’ cards. The badge is always listed among the NFT’s characteristics, and it enables data filtering on marketplaces.',
  type: 'badge',
};

const ExplorerCard = ({
  metadata,
  traitTypes,
  imageContainerWidth,
  horizontalPadding,
  fsHandler,
  dlEnabled,
  claimDisabled

}) => {
  const [loading, setLoading] = useState(true);
  const [traitsVisible, setTraitsVisible] = useState(false);
  const [selectedTrait, setSelectedTrait] = useState({});
  const [showBackCard, setshowBackCard] = useState(false);
  const [showFlipIcon, setshowFlipIcon] = useState(false);
  const [traitType, setTraitType] = useState(traitTypes ? traitTypes[0] : null); // only if we have traits
  const [mobileView, setmobileView] = useState(false);
  const imageRef = useRef();
  const [dustAmount, setDustAmount] = useState('0.00');
  const [dustVisible, setDustVisible] = useState(false);
  const [defaultScopeWidth, setDefaultScopeWidth] = useState(
    imageContainerWidth ? imageContainerWidth - horizontalPadding : 400
  );
  //const GALAXIS_BASE_URL = 'https://galaxis-web-backend-staging.herokuapp.com';
  const GALAXIS_BASE_URL = 'https://cms.galaxis.xyz';

  const containerRef = useRef();
  const { width, height } = useContainerDimensions(containerRef);
  const [imageRatio, setImageRatio] = useState(0);
  const [resizerComponentSize, setResizerComponentSize] = useState({
    width: 0,
    height: 0,
  });
  const [containerSize, setContainerSize] = useState('medium');

  const cardRef = useRef(null);

  useEffect(() => {
   // console.log('EXPLORER METADATA',metadata,traitTypes);
    if (window.innerWidth < 900) {
      setmobileView(true);
    }
    if (window.innerWidth < 410) {
      setDefaultScopeWidth(window.innerWidth - horizontalPadding);
    }
    // if (metadata.traits) {
    //   setSelectedTrait(metadata.traits[0]);
    // }
  }, []);
  const showTraits = (e) => {
    e.stopPropagation();
    setTraitsVisible(true);
    /* document.getElementById('explorer-scope').style.transform =
      'perspective(1000px) rotateY(180deg)'; */
    cardRef.current.style.transform = 'perspective(1000px) rotateY(180deg)';
  };
  const hideTraits = (e) => {
    e.stopPropagation();
    setTraitsVisible(false);
    setshowBackCard(false);
    /*  document.getElementById('explorer-scope').style.transform =
       'perspective(1000px) rotateY(0deg)'; */
    cardRef.current.style.transform = 'perspective(1000px) rotateY(0deg)';
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const setTheImageRatio = (width, height) => {
    setImageRatio(height / width);
  };
  useEffect(() => {
    if (imageRatio != 0) {
      setResizerComponentSize({
        width: width < height / imageRatio ? 100 + '%' : height / imageRatio,
        height: width < height / imageRatio ? width * imageRatio : '100%',
      });
      // console.log(width, 'pyramidContainer width');
    }
  }, [imageRatio, width, height]);

  useEffect(() => {
    if (width) {
      let cSize = '';
      if (width > 500) {
        cSize = 'c-xlarge';
      } else if (width > 400) {
        cSize = 'c-large';
      } else if (width > 300) {
        cSize = 'c-medium';
      } else if (width > 240) {
        cSize = 'c-small';
      } else {
        cSize = 'c-xsmall';
      }
      setContainerSize(cSize);
      // console.log(containerSize, 'containerSize');
    }
  }, [width]);
  // console.log(width)
  useEffect(async () => {
    if (!loading) {
      await getDust(metadata.tokenId).then((response) => {
         console.log('metaresponse',response);
        setDustAmount(parseFloat(response.replace(/,/g, '')));
      });
    }
  }, [loading]);
  // console.log(typeof dustAmount);
  return (
    <>
      <ClipLoader
        size={150}
        color='rgb(252, 108, 3)'
        loading={loading}
        css={{
          position: 'absolute',
          border: '5px solid rgb(252, 108, 3)',
          borderBottomColor: 'transparent',
          margin: 'auto',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        speedMultiplier={0.5}
      />
      {metadata && (
        <div
          className={`explorer-card-image-container ${containerSize}`}
          ref={containerRef}
          style={{ opacity: loading ? '0' : '1' }}
          onClick={(e) => stopPropagation(e)}
        >
          {/* <img src={trait_card} alt="" style={sx.image} /> */}
          <div className='explorer-pyramid_anim_container'>
            <div
              className='resizer-container'
              style={{
                opacity: resizerComponentSize.width === 0 ? 0 : 1,
                width: resizerComponentSize.width,
                height: resizerComponentSize.height,
              }}
            >
              <div
                ref={cardRef}
                className={`explorer-scope ${traitsVisible ? 'active' : ''}  `}
                style={{ width: '100%', height: '100%' }}
                // onTransitionEnd={() => console.log('hello')}
                onMouseOver={() => {
                  setshowFlipIcon(true);
                }}
                onMouseLeave={() => {
                  setshowFlipIcon(false);
                }}
              >
                <span
                  className={`explorer-front ${!traitsVisible ? 'active' : ''}`}
                  //  ${metadata.rare ? 'rare-element' : ''}`}
                  id='explorer-front-span'
                >
                  <img
                    className={`explorer-flipped-img `}
                    src={
                      metadata.sides && metadata.sides.length > 1
                        ? metadata.sides[0].image
                        : metadata.image
                    }
                    alt='not found'
                    ref={imageRef}
                    onLoad={() => {
                      setTheImageRatio(
                        imageRef.current.naturalWidth,
                        imageRef.current.naturalHeight
                      );
                      setLoading(false);
                    }}
                  />

                  {(metadata.overlays && metadata.overlays.length>0 && metadata.overlays[0].layers && metadata.overlays[0].layers[0].length>0)&&
                    <img src={metadata.overlays[0].layers[0]} className='flipped-img' alt='overlay'/>
                  }     




                  <div className='explorer-card-icons-holder'>
                    {fsHandler && (
                      <img
                        src={fullScreen}
                        alt=''
                        className='explorer-fullscreen-icon'
                        style={{
                          display:
                            showFlipIcon || mobileView ? 'block' : 'none',
                        }}
                        onClick={() => {
                          fsHandler(metadata);
                        }}
                      />
                    )}
                    {metadata.sides && metadata.sides.length > 1 && (
                      <img
                        src={flip_icon}
                        className='explorer-flip-icon'
                        alt='not found'
                        style={{
                          display:
                            showFlipIcon || mobileView ? 'block' : 'none',
                        }}
                        onClick={(e) => {
                          showTraits(e);
                          setshowBackCard(true);
                        }}
                      />
                    )}

                    {dlEnabled && metadata.sides && (
                      <img
                        className='explorer-download-icon'
                        src={dlIcon}
                        alt='download'
                        onClick={(e) => {
                          console.log('download', metadata.sides[0].image);
                          window.open(metadata.sides[0].image);
                        }}
                      />
                    )}
                  </div>
                  {metadata.perfect && (
                    <img
                      className='explorer-badge-img'
                      src={perfectBadge}
                      alt='not found'
                      onClick={(e) => {
                        showTraits(e);
                        setSelectedTrait(perfectBadgeVariable);
                      }}
                    />
                  )}

                  <DustButton
                    dustAmount={dustAmount}
                    traitsVisible={traitsVisible}
                    handleDustClick={() => setDustVisible(true)}
                  />
                  <DustLayer
                    dustVisible={dustVisible}
                    dustAmount={dustAmount}
                    handleClose={() => {
                      setDustVisible(false);
                    }}
                  />
                  {metadata.traits && traitTypes && (
                        <div
                            className={`explorer-trait-container ${traitsVisible ? 'hide' : ''}`}
                        >
                            {metadata.traits.map((elem, metadataIndex) => {

                              return (
                                <React.Fragment key={metadataIndex}>
                                  {parseInt(elem.status)===1&&<div
                                      className="explorer-trait-holder"
                                      
                                      onClick={(e) => {
                                          setSelectedTrait(
                                              metadata.traits[metadataIndex]
                                          );
                                          setTraitType(elem.type);
                                          showTraits(e);
                                      }}
                                  >
                                      {' '}
                                      <img

                                          src={elem.icon_url}
                                          alt="undefined"
                                      />
                                      {/* <img

                                          src={GALAXIS_BASE_URL + traitElem.icon_white}
                                          alt="undefined"
                                      /> */}
                                      
                                      {' '}
                                  </div>}
                                </React.Fragment>

                              );




                              
                                
                            })}
                        </div>
                    )}
                </span>
                {/* {metadata.traits && traitTypes && ( */}
                <span
                  className={`explorer-back ${traitsVisible ? 'active' : ''} `}
                  id='trait-span'
                >
                  <TraitCard
                    onPolygon={metadata.onPolygon}
                    trait={selectedTrait}
                    onClick={hideTraits}
                    image={
                      metadata.sides && metadata.sides.length > 1
                        ? metadata.sides[0].image
                        : metadata.image
                    }
                    traitImg={
                      traitType ? GALAXIS_BASE_URL + traitType.icon_white : ''
                    }
                    claimDisabled={claimDisabled}
                  />
                </span>
                {/* )} */}
                {metadata.sides && metadata.sides.length > 1 && (
                  <span
                    className={`explorer-back_card ${showBackCard ? 'active' : ''
                      } `}
                    id='back-span'
                  >
                    <CardBack
                      onClick={hideTraits}
                      backImage={metadata.sides[1].image}
                      dlEnabled={dlEnabled}
                    />
                  </span>
                )}

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExplorerCard;
